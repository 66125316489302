import { ApolloLink, split } from '@apollo/client';

import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { WebSocketLink } from '@apollo/client/link/ws';

import { getMainDefinition } from '@apollo/client/utilities';

import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';

// import { HttpLink } from '@apollo/client';
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
// import { sha256 } from 'crypto-hash';

const httpUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_HTTP_URL_DEVELOPMENT
    : process.env.REACT_APP_HTTP_URL_PRODUCTION;

const uploadAndBatchHTTPLink = options =>
  ApolloLink.split(
    operation => {
      return Boolean(
        operation.query.definitions.find(d =>
          d.variableDefinitions.find(
            vd => vd.type.type?.name?.value === 'Upload'
          )
        )
      );
    },
    // createPersistedQueryLink({ sha256 }),
    createUploadLink(options),
    new BatchHttpLink(options)
  );

// const uploadAndHttpLink = options =>
//   ApolloLink.split(
//     operation => {
//       return Boolean(
//         operation.query.definitions.find(d =>
//           d.variableDefinitions.find(
//             vd => vd.type.type?.name?.value === 'Upload'
//           )
//         )
//       );
//     },

//     createUploadLink(options),
//     new HttpLink(options)
// );

const wsUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_WS_URL_DEVELOPMENT
    : process.env.REACT_APP_WS_URL_PRODUCTION;

const wsLink = new WebSocketLink({
  uri: wsUrl,
  credentials: 'include',
  options: {
    credentials: 'include',
    reconnect: true,
    timeout: 1000 * 60 * 7,
    lazy: true
  }
});

wsLink.subscriptionClient.backoff.max = 3333;

let link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return !(
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },

  // uploadAndHttpLink({ uri: httpUrl, credentials: 'include' }),

  uploadAndBatchHTTPLink({
    uri: httpUrl,
    batchInterval: 25,
    credentials: 'include'
    // batchKey: ({ operationName }) =>
    //   operationName === 'currentUser' ? 'currentUser' : ''
  }),

  wsLink
);

// createPersistedQueryLink({ sha256 }).concat(link);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: link,
  // link: createPersistedQueryLink({
  //   sha256,
  //   useGETForHashedQueries: true
  // }).concat(link),
  cache: cache,
  credentials: 'include',

  defaultOptions: {
    query: {
      // fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  }
});

// client._wsLink = wsLink;

export default client;
