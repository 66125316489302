const bottomNavExcludes = [
  '/',
  '/logout',
  '/user-profile',
  '/login',
  '/register',
  '/products',
  '/terms',
  '/about',
  '/contact',
  '/user-profile/edit-account-details',
  '/user-profile/change-password',
  '/user-profile/edit-avatar',
  '/lost-password',
  '/app',
  '/group/:groupId'
];

export default bottomNavExcludes;
