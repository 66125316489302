import { createMuiTheme } from '@material-ui/core';
// import { blue } from '@material-ui/core/colors';

const themes = {
  light: createMuiTheme({
    props: {
      MuiUseMediaQuery: {
        noSsr: true
      }
    },
    shape: {
      borderRadiusMassive: 40,
      borderRadius: 2
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,

        lgHome: 1440
      }
    },
    body: {
      background: '#f2f2f2'
    },
    app: {
      background: '#111111'
    },
    typography: {
      // fontFamily: "'Roboto', sans-serif"
      fontFamily: "'Inter', sans-serif"
      // fontSize: 16
    },
    palette: {
      type: 'light',
      primary: {
        main: '#17FF8A',
        dark: '#19cf7e'
      },
      secondary: {
        // main: blue[600]
        main: '#8a3ae5'
      },
      error: {
        main: '#E30E5B',
        light: '#fde7ef'
      },
      dividerBright: '#616c76',

      background: {
        gradient1:
          'linear-gradient(94.22deg, rgba(126, 39, 225, 0.25) 0%, rgba(227, 14, 91, 0.25) 100%)',
        gradient2:
          'linear-gradient(180deg, rgba(126, 39, 225, 0.15) 0%, rgba(126, 39, 225, 0.3) 100%)',
        gradient2Invert:
          'linear-gradient(180deg, rgba(126, 39, 225, 0.3) 0%, rgba(126, 39, 225, 0.15) 100%)',

        gradient3: 'linear-gradient(180deg, #130e41 0%, #25135c 100%)',

        gradientNav: 'linear-gradient(180deg, #130e41 0%, #24125b 100%)',

        gradientGreen:
          'linear-gradient(94.22deg, rgba(1, 219, 254, 0.5) 0%, rgba(23, 255, 138, 0.5) 100%)',

        gradientOrange: 'linear-gradient(135deg, #EBF91A 0%, #E30E5B 100%)',

        main: '#f5f5f5',
        medium: '#181818',
        paper: '#f2f2f2',
        paperDark: '#dddddd',
        paperLight: '#bfc7d1',

        tab: '#001E39',
        body: '#ffffff',
        game: 'rgb(217, 217, 217)',

        green: '#d1ffe8',

        purpleLight: '#fde7ef',
        purpleDark: '#d9bff7',

        scoreNeutral: '#ffffff',
        scoreNegative: '#ffffff',
        scorePositive: '#EBF91A' // bright yellow
      },
      text: {
        scoreNeutral: '#000000',
        scoreNegative: '#ff0000',
        scorePositive: '#000000',

        coin: '#EBF91A' // bright yellow
      },

      success: {
        main: '#55B058'
      }
    }
  }),

  // dark: createMuiTheme({
  //   body: {
  //     background: '#181818'
  //   },
  //   app: {
  //     background: 'black'
  //   },
  //   typography: {
  //     fontFamily: "'Roboto', sans-serif"
  //   },
  //   palette: {
  //     type: 'dark',
  //     primary: {
  //       main: '#FE6666'
  //     },
  //     secondary: {
  //       // main: blue[600]
  //       main: '#ffffff'
  //     },
  //     background: {
  //       main: '#121212',
  //       paper: '#363535',
  //       paperDark: '#252525'
  //     },
  //     success: {
  //       main: '#55B058'
  //     }
  //   }
  // })

  dark: createMuiTheme({
    props: {
      MuiUseMediaQuery: {
        noSsr: true
      }
    },
    shape: {
      borderRadiusMassive: 40,
      borderRadius: 2
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,

        lgHome: 1440
      }
    },
    body: {
      background: '#000A25'
    },
    app: {
      background: '#000A25'
    },
    typography: {
      // fontFamily: "'Roboto', sans-serif"
      fontFamily: "'Inter', sans-serif"
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#17FF8A'
        // main: '#556ea9'
      },
      secondary: {
        // main: blue[600]
        main: '#7E27E1'
      },
      error: {
        main: '#E30E5B',
        light: '#2f1b40'
      },
      dividerBright: '#717c4877',
      background: {
        gradient1:
          'linear-gradient(94.22deg, rgba(126, 39, 225, 0.25) 0%, rgba(227, 14, 91, 0.25) 100%)',
        gradient2:
          'linear-gradient(180deg, rgba(126, 39, 225, 0.15) 0%, rgba(126, 39, 225, 0.3) 100%)',
        gradient2Invert:
          'linear-gradient(180deg, rgba(126, 39, 225, 0.3) 0%, rgba(126, 39, 225, 0.15) 100%)',

        gradient3: 'linear-gradient(180deg, #130e41 0%, #25135c 100%)',

        gradientNav:
          'linear-gradient(180deg, rgba(23, 255, 138, 0.1) 0%, rgba(1, 133, 254, 0.2) 100%)',

        gradientGreen:
          'linear-gradient(94.22deg, rgba(1, 219, 254, 0.5) 0%, rgba(23, 255, 138, 0.5) 100%)',

        gradientOrange: 'linear-gradient(135deg, #EBF91A 0%, #E30E5B 100%)',

        // main: '#ff0000',
        paper: '#001E39',
        paperDark: '#000A25',
        paperLight: '#1b2b44',

        tab: '#350035',
        bodyMobile: '#000A25',
        body: '#001E39',

        game: '#000A25',

        purpleLight: '#350035',
        purpleDark: '#26216c',

        green: '#054b4a',

        scoreNeutral: '#ffffff',
        scoreNegative: '#ffffff',
        scorePositive: '#EBF91A'
      },
      text: {
        scoreNeutral: '#000000',
        scoreNegative: '#ff0000',
        scorePositive: '#000000',

        coin: '#EBF91A' // bright yellow
      },

      success: {
        main: '#55B058'
      }
    },

    overrides: {
      MuiAppBar: {
        colorDefault: {
          backgroundColor: '#ffffff'
        }
      },
      AppBar: {
        colorDefault: {
          backgroundColor: '#7E27E1'
        }
      },
      MuiDialog: {
        root: {
          borderWidth: 0,
          borderRadius: 2,
          borderColor: '#fff',
          borderStyle: 'solid'
        },
        paper: {
          borderWidth: 0,
          borderRadius: 2,
          borderColor: '#fff',
          borderStyle: 'solid'
        }
      }
    }
  })

  // overrides: {}
};

export default themes;
