import React from 'react';
import ReactDOM from 'react-dom';

// import './index.css';
import App from './Components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import IndexWrapper from 'Components/IndexWrapper.js';

// const hash = window.location.hash.toString();

// setTimeout(() => {
//   window.location.hash = '#123';

//   setTimeout(() => {
//     window.location.hash = hash;
//   }, 1000);
// }, 500);
// window.onbeforeunload = function () {
//   console.log('unload?');
//   return '';
// };

ReactDOM.render(
  <IndexWrapper>
    <App />
  </IndexWrapper>,
  document.getElementById('root')
);

serviceWorkerRegistration.init();

const setAppHeight = () => {
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`
  );
};
window.addEventListener('resize', setAppHeight);
window.addEventListener('orientationchange', () => {
  // const interval = setInterval(setAppHeight, 10);
  // setTimeout(() => clearInterval(interval), 2500);
  setAppHeight();
});
setAppHeight();

if (window.location.hostname !== 'localhost') {
  document.body.addEventListener('contextmenu', evt => {
    if (evt.target.nodeName !== 'INPUT' && evt.target.nodeName !== 'TEXTAREA') {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }
  });
}

// window.addEventListener('orientationchange', function () {
//   // document.body.style.width = window.innerWidth;

//   setTimeout(() => {
//     alert(window.innerHeight);
//   }, 1000);
// });

// setTimeout(() => {
//   alert(window.innerHeight);
// }, 1000);

// window.history.pushState({ page: 1 }, '', '');

// window.addEventListener('load', resize);
// window.addEventListener('resize', resize);

// function resize() {
//   console.log('resizee');

//   const w = window.innerWidth;
//   const h = window.innerHeight;

//   document.body.style.width = w + 'px';
//   document.body.style.height = h + 'px';
// }

// (function (w) {
//   // This fix addresses an iOS bug, so return early if the UA claims it's something else.
//   var ua = navigator.userAgent;
//   if (
//     !(
//       /iPhone|iPad|iPod/.test(navigator.platform) &&
//       /OS [1-5]_[0-9_]* like Mac OS X/i.test(ua) &&
//       ua.indexOf('AppleWebKit') > -1
//     )
//   ) {
//     return;
//   }

//   var doc = w.document;

//   if (!doc.querySelector) {
//     return;
//   }

//   var meta = doc.querySelector('meta[name=viewport]'),
//     initialContent = meta && meta.getAttribute('content'),
//     disabledZoom = initialContent + ',maximum-scale=1',
//     enabledZoom = initialContent + ',maximum-scale=10',
//     enabled = true,
//     x,
//     y,
//     z,
//     aig;

//   if (!meta) {
//     return;
//   }

//   function restoreZoom() {
//     meta.setAttribute('content', enabledZoom);
//     enabled = true;
//   }

//   function disableZoom() {
//     meta.setAttribute('content', disabledZoom);
//     enabled = false;
//   }

//   function checkTilt(e) {
//     aig = e.accelerationIncludingGravity;
//     x = Math.abs(aig.x);
//     y = Math.abs(aig.y);
//     z = Math.abs(aig.z);

//     // If portrait orientation and in one of the danger zones
//     if (
//       (!w.orientation || w.orientation === 180) &&
//       (x > 7 || (((z > 6 && y < 8) || (z < 8 && y > 6)) && x > 5))
//     ) {
//       if (enabled) {
//         disableZoom();
//       }
//     } else if (!enabled) {
//       restoreZoom();
//     }
//   }

//   w.addEventListener('orientationchange', restoreZoom, false);
//   w.addEventListener('devicemotion', checkTilt, false);
// })(this);
