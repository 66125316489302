import { withStyles } from '@material-ui/core';
import PrimaryButton from './PrimaryButton';

const GreenButton = withStyles(theme => ({
  root: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.background.tab
        : theme.palette.background.paperDark,
    background: 'none',
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.short
    }),
    fontWeight: 'bold',
    boxShadow: theme.shadows[1],
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow:
        theme.palette.type === 'light'
          ? theme.shadows[5]
          : '0px 3px 5px -1px rgb(51 33 73 / 38%), 0px 5px 8px 0px rgb(30 36 81), 0px 1px 14px 0px rgb(0 0 0)'
    }
  }
}))(PrimaryButton);

export default GreenButton;
