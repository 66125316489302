import { gql } from '@apollo/client';

const Mutation = {
  login: gql`
    mutation login($email: String!, $password: String!) {
      login(record: { email: $email, password: $password }) {
        record {
          _id
        }
      }
    }
  `,
  startRegister: gql`
    mutation startRegister($email: String!) {
      startRegister(record: { email: $email })
    }
  `,

  register: gql`
    mutation register(
      $email: String!
      $password: String!
      $passwordRepeat: String!
      $registrationCode: String!
      $name: String!
    ) {
      register(
        record: {
          email: $email
          password: $password
          passwordRepeat: $passwordRepeat
          registrationCode: $registrationCode
          name: $name
        }
      )
    }
  `,

  logout: gql`
    mutation logout($from: String) {
      logout(from: $from)
    }
  `,

  userUpdateSelfAccountDetails: gql`
    mutation userUpdateSelfAccountDetails($name: String!, $email: String!) {
      userUpdateSelfAccountDetails(record: { name: $name, email: $email }) {
        record {
          _id
        }
      }
    }
  `,

  startPasswordReset: gql`
    mutation startPasswordReset($email: String!) {
      startPasswordReset(email: $email)
    }
  `,

  resetPassword: gql`
    mutation resetPassword($email: String!, $code: String!) {
      resetPassword(email: $email, code: $code)
    }
  `,

  changePassword: gql`
    mutation changePassword($password1: String!, $password2: String!) {
      changePassword(password1: $password1, password2: $password2)
    }
  `,

  joinGame: gql`
    mutation joinGame($gameId: MongoID!) {
      joinGame(gameId: $gameId)
    }
  `,

  updateMyTeam: gql`
    mutation updateMyTeam($_id: MongoID!, $record: UpdateByIdUserTeamInput!) {
      updateMyTeam(_id: $_id, record: $record) {
        record {
          _id
          playerIds
          gameId
          setup
          captainId
          subIds
          subCosts
          freeSubs
        }
      }
    }
  `,
  resetMyTeam: gql`
    mutation resetMyTeam($_id: MongoID!) {
      resetMyTeam(_id: $_id) {
        record {
          _id
          playerIds
          gameId
          setup
          captainId
          subIds
          subCosts
          freeSubs
        }
      }
    }
  `,
  resetMyTeamOneSub: gql`
    mutation resetMyTeamOneSub($_id: MongoID!, $i: Int!, $isFree: Boolean) {
      resetMyTeamOneSub(_id: $_id, i: $i, isFree: $isFree) {
        record {
          _id
          playerIds
          gameId
          setup
          captainId
          subIds
          subCosts
          freeSubs
        }
      }
    }
  `,

  gameCreateOne: gql`
    mutation gameCreateOne($record: CreateOneGameInput!) {
      gameCreateOne(record: $record) {
        record {
          _id
        }
      }
    }
  `,

  updateGameMessage: gql`
    mutation updateGameMessage($gameId: MongoID!, $msg: String!) {
      updateGameMessage(gameId: $gameId, msg: $msg) {
        record {
          _id
          msg
        }
      }
    }
  `,
  updateGameById: gql`
    mutation updateGameById($_id: MongoID!, $record: UpdateByIdGameInput!) {
      updateGameById(_id: $_id, record: $record) {
        record {
          _id
        }
      }
    }
  `,

  updatePrediction: gql`
    mutation updatePrediction(
      $roundId: MongoID!
      $fixtureId: MongoID!
      $local: Int
      $visitor: Int
      $lId: MongoID
      $vId: MongoID
      $spot: Int
    ) {
      updatePrediction(
        roundId: $roundId
        fixtureId: $fixtureId

        local: $local
        visitor: $visitor

        lId: $lId
        vId: $vId

        spot: $spot
      )
    }
  `,

  updatePredictionQuestions: gql`
    mutation updatePrediction($roundId: MongoID!, $questions: JSON!) {
      updatePrediction(roundId: $roundId, questions: $questions)
    }
  `,

  submitReport: gql`
    mutation submitReport(
      $message: String!
      $selectValue: String
      $reportData: String
      $img: String
    ) {
      submitReport(
        message: $message
        selectValue: $selectValue
        reportData: $reportData
        img: $img
      )
    }
  `,

  submitContact: gql`
    mutation submitContact($message: String!, $email: String, $img: String) {
      submitContact(message: $message, email: $email, img: $img)
    }
  `,

  submitGroupContact: gql`
    mutation submitGroupContact($message: String!, $groupId: MongoID!) {
      submitGroupContact(message: $message, groupId: $groupId)
    }
  `,

  updateFavorite: gql`
    mutation updateFavorite(
      $fixtureId: MongoID
      $playerId: MongoID
      $teamId: MongoID
      $push: Boolean
    ) {
      updateFavorite(
        fixtureId: $fixtureId
        playerId: $playerId
        teamId: $teamId
        push: $push
      ) {
        record {
          _id
          favoriteFixtureIds
          favoritePlayerIds
          favoriteTeamIds
        }
      }
    }
  `,

  groupUpdateById: gql`
    mutation groupUpdateById($_id: MongoID!, $record: UpdateByIdGroupInput!) {
      groupUpdateById(_id: $_id, record: $record) {
        record {
          _id
          private
          name
          avatar
          email
          hasChat
        }
      }
    }
  `,
  groupUpdateByIdAvatar: gql`
    mutation groupUpdateById(
      $_id: MongoID!
      $record: UpdateByIdGroupInput!
      $file: Upload!
    ) {
      groupUpdateById(_id: $_id, record: $record, file: $file) {
        record {
          _id
          private
          name
          avatar
          email
          hasChat
        }
      }
    }
  `,

  gameConfirmUser: gql`
    mutation gameConfirmUser($userId: MongoID!, $gameId: MongoID!) {
      gameConfirmUser(userId: $userId, gameId: $gameId)
    }
  `,
  gameRemoveById: gql`
    mutation gameRemoveById($_id: MongoID!) {
      gameRemoveById(_id: $_id) {
        recordId
      }
    }
  `,
  groupRemoveById: gql`
    mutation groupRemoveById($_id: MongoID!) {
      groupRemoveById(_id: $_id) {
        recordId
      }
    }
  `,
  groupLeave: gql`
    mutation groupLeave($_id: MongoID!) {
      groupLeave(_id: $_id)
    }
  `,
  gameLeave: gql`
    mutation gameLeave($_id: MongoID!) {
      gameLeave(_id: $_id)
    }
  `,

  gameRemoveUserById: gql`
    mutation gameRemoveUserById($userId: MongoID!, $gameId: MongoID!) {
      gameRemoveUserById(userId: $userId, gameId: $gameId)
    }
  `,
  groupRemoveUserById: gql`
    mutation groupRemoveUserById($userId: MongoID!, $groupId: MongoID!) {
      groupRemoveUserById(userId: $userId, groupId: $groupId)
    }
  `,

  userUpdatePredictSpots: gql`
    mutation userUpdatePredictSpots($gameId: MongoID!, $spot: Int!) {
      userUpdatePredictSpots(gameId: $gameId, spot: $spot) {
        record {
          _id
          predictSpots
        }
      }
    }
  `,
  userUnlockPredictSpot: gql`
    mutation userUnlockPredictSpot($leagueId: MongoID!) {
      userUnlockPredictSpot(leagueId: $leagueId) {
        record {
          _id
          leaguePredictSpots
          credits
        }
      }
    }
  `
};

export default Mutation;
