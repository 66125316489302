import React from 'react';
import { useTheme, Paper, Typography } from '@material-ui/core';
import ContactBanner from './ContactBanner';
import Footer from './Footer';
import { ASSET_URL } from 'env';
import useIsTablet from 'hooks/useIsTablet';

export default function ContentPage({
  children,
  bannerImage,
  BannerContent,
  hasContactBanner,
  hasFooter,
  bannerHeight = 300,

  bannerText,

  childrenPaperStyle = {}
}) {
  const isTablet = useIsTablet();

  const theme = useTheme();
  return (
    <div
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // overflowY: 'auto',
        flexGrow: 1
      }}
    >
      <div
        style={{
          width: '100%',
          background: theme.palette.background.paperDark,
          display: 'flex',
          justifyContent: 'center',
          height: bannerHeight,
          minHeight: bannerHeight,
          overflow: 'hidden',
          position: 'relative',
          backgroundImage: bannerHeight
            ? `url(${ASSET_URL + '/hero/' + bannerImage + '.png'})`
            : '',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {/* <div
          style={{
            width: '100%',
            height: '100%',
            background: theme.palette.background.paper,
            opacity: theme.palette.type === 'dark' ? 0.1 : 0.5,
            position: 'absolute',
            zIndex: 1
          }}
        ></div> */}
        {/* <picture>
          <source
            srcSet={ASSET_URL + '/hero/' + bannerImage + '.webp'}
            type="image/webp"
          />
          <img
            // loading="lazy"
            alt=""
            src={ASSET_URL + '/hero/' + bannerImage + '.png'}
            width="250"
            height="525"
            style={{
              maxWidth: '100%',
              height: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 0
            }}
          />
        </picture> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 2
          }}
        >
          {BannerContent ? (
            <BannerContent />
          ) : bannerText ? (
            <Typography
              variant="h3"
              color="textPrimary"
              style={{
                letterSpacing: 8,
                textAlign: 'center',
                padding: isTablet ? 0 : 16
              }}
            >
              {bannerText}
            </Typography>
          ) : null}
        </div>
      </div>

      <Paper
        elevation={0}
        square
        style={{
          maxWidth: theme.breakpoints.values.lg,
          margin: '0 auto',
          flexGrow: 1,
          width: '100%',
          background: 'none',
          ...childrenPaperStyle,
          minHeight: 360
        }}
      >
        {children}
      </Paper>

      {hasContactBanner ? <ContactBanner /> : null}

      {hasFooter ? <Footer /> : null}
    </div>
  );
}
