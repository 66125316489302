import React from 'react';
import { Paper, useTheme, Fade } from '@material-ui/core';

export default function PaperBoxed(props) {
  const theme = useTheme();
  const spacing = theme.spacing(1);
  return (
    <Fade in>
      <Paper
        elevation={props.noBg ? 0 : 3}
        square
        // {...props}
        style={{
          margin: spacing,
          padding: spacing,
          ...props.style,
          background: theme.palette.background.paperDark,
          ...(props.noBg ? { background: 'none' } : {})
        }}
      >
        {props.children}
      </Paper>
    </Fade>
  );
}
