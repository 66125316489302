import { Button, withStyles } from '@material-ui/core';

const PrimaryButton = withStyles(theme => ({
  root: {
    color:
      theme.palette.type === 'light' ? theme.palette.background.tab : 'white',
    background: theme.palette.background.gradient1,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.short
    }),
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    '&:hover': {
      backgroundColor: theme.palette.background.purpleDark,
      boxShadow:
        theme.palette.type === 'light'
          ? theme.shadows[5]
          : '0px 3px 5px -1px rgb(51 33 73 / 38%), 0px 5px 8px 0px rgb(30 36 81), 0px 1px 14px 0px rgb(0 0 0)'
    },
    fontWeight: 'bold',

    minWidth: 10,
    minHeight: 10,
    boxShadow: theme.shadows[1]
  }
}))(Button);

export default PrimaryButton;
