import React from 'react';
import PaperBoxed from './Elements/PaperBoxed';
import PrimaryButton from './Elements/PrimaryButton';

/** @TODO all error feedback in ErrorOverlay, send email? */
function ErrorOverlay(err) {
  console.log('ErrorOverlay', err);
  return <div>Error</div>;
}

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ error: error, info: info });
  }

  render() {
    if (!this.state.error) return this.props.children;

    return (
      <PaperBoxed>
        <h1>Something went wrong :(</h1>
        <PrimaryButton>
          <a style={{ color: 'white', textDecoration: 'none' }} href="/">
            Reload App
          </a>
        </PrimaryButton>
        <pre style={{ maxWidth: '100%', overflowX: 'auto' }}>
          {this.state.error.message}
        </pre>
        <pre style={{ maxWidth: '100%', overflowX: 'auto' }}>
          {this.state.error.stack}
        </pre>
        {this.state.info ? <pre>{this.state.info.componentStack}</pre> : null}

        <ErrorOverlay />
      </PaperBoxed>
    );
  }
}
