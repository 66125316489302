import { gql } from '@apollo/client';

const Query = {
  usersRegistered: gql`
    query usersRegistered {
      usersRegistered
    }
  `,

  userMany: gql`
    query userMany {
      userMany {
        _id
        name
        avatar
      }
    }
  `,
  currentUser: gql`
    query currentUser {
      currentUser {
        _id
        name
        email
        admin
        avatar
        favoriteFixtureIds
        favoritePlayerIds
        favoriteTeamIds

        credits

        predictSpots
        leaguePredictSpots
      }
    }
  `,

  currentUserWithProducts: gql`
    query currentUser {
      currentUser {
        _id
        name
        email
        admin
        avatar
        favoriteFixtureIds
        favoritePlayerIds
        favoriteTeamIds
        productsPurchased {
          _id
          name
          quantity
        }
        credits
      }
    }
  `,

  currentUserWithCreditPurchases: gql`
    query currentUser {
      currentUser {
        _id
        name
        email
        admin
        avatar
        favoriteFixtureIds
        favoritePlayerIds
        favoriteTeamIds
        creditPurchases {
          _id
          credits
          createdAt
          description
        }
        credits
      }
    }
  `,

  myGroups: gql`
    query myGroups {
      myGroups {
        _id
        name
        memberCount
        memberIds
        gameCount
        avatar
        usersOnline
        private
        email
        hasChat
        link
        hideOwner
        hidden
        terms
        owner {
          _id
          name
        }
        adminIds
        s11
        games {
          _id
          mode
          memberIds
          confirmedUserIds
          msg
          s11
          elements
          link
          freeSpots
          lateJoiners
          league {
            _id
            name
            countryFlagUrl
            start
            nextRoundStart
            status
            isCup
            complete
            currentRound {
              _id
              name
              i
            }
          }
        }
      }
    }
  `,

  playersByLeagueId: gql`
    query playerMany($leagueId: MongoID!) {
      playerMany(
        filter: {
          OR: [
            { position: 1 }
            { position: 2 }
            { position: 3 }
            { position: 4 }
          ]
          leagueId: $leagueId
        }
      ) {
        _id
        name
        position
        teamId
        active
        stats
        leagueId
        number
        avatarUrl
      }
    }
  `,

  playersWithStatsPerRoundByLeagueId: gql`
    query playerMany($leagueId: MongoID!) {
      playerMany(
        filter: {
          OR: [
            { position: 1 }
            { position: 2 }
            { position: 3 }
            { position: 4 }
          ]
          leagueId: $leagueId
        }
      ) {
        _id
        name
        position
        teamId
        active
        stats
        statsPerRoundId
        leagueId
        number
        avatarUrl
      }
    }
  `,

  playerByIds: gql`
    query playerByIds($_ids: [MongoID!]!) {
      playerByIds(_ids: $_ids) {
        _id
        name
        statsPerRoundId
        position
      }
    }
  `,

  playersFiltered: gql`
    query playerMany($teamId: MongoID!) {
      playerMany(
        filter: {
          teamId: $teamId
          OR: [
            { position: 1 }
            { position: 2 }
            { position: 3 }
            { position: 4 }
          ]
        }
      ) {
        _id
        name
        position
        active
        stats
        leagueId
      }
    }
  `,

  myTeam: gql`
    query myTeam($gameId: MongoID!, $roundId: MongoID) {
      myTeam(filter: { gameId: $gameId, roundId: $roundId }) {
        _id
        playerIds
        gameId
        setup
        captainId
        subIds
        subCosts
        freeSubIds
        freeSubs
        scores
        score
        captainScore
        complete
        isNewTeam
      }
    }
  `,

  // userManyFiltered: gql`
  //   query userMany($filter: FilterFindManyUserInput!) {
  //     userMany(filter: $filter) {
  //       _id
  //       name
  //       avatar
  //     }
  //   }
  // `,

  userManyByIds: gql`
    query userManyByIds($_ids: [MongoID]) {
      userManyByIds(_ids: $_ids) {
        _id
        name
        avatar
      }
    }
  `,

  userPredictionOne: gql`
    query userPredictionOne($roundId: MongoID!, $spot: Float) {
      userPredictionOne(filter: { roundId: $roundId, spot: $spot }) {
        _id
        roundId
        predictions
        results
      }
    }
  `,

  userPredictionOneWithQuestions: gql`
    query userPredictionOne($roundId: MongoID!, $spot: Float) {
      userPredictionOne(filter: { roundId: $roundId, spot: $spot }) {
        _id
        roundId
        predictions
        results
        questions
      }
    }
  `,

  userPredictionManyByLeagueId: gql`
    query userPredictionManyByLeagueId($leagueId: MongoID!, $spot: Float) {
      userPredictionManyByLeagueId(
        filter: { leagueId: $leagueId, spot: $spot }
      ) {
        _id
        roundId
        predictions
        results
      }
    }
  `,

  userPredictionManyByLeagueIdWithQuestions: gql`
    query userPredictionManyByLeagueId($leagueId: MongoID!, $spot: Float) {
      userPredictionManyByLeagueId(
        filter: { leagueId: $leagueId, spot: $spot }
      ) {
        _id
        roundId
        predictions
        results
        questions
      }
    }
  `,

  userTeam: gql`
    query userTeamOne(
      $userId: MongoID!
      $roundId: MongoID!
      $gameId: MongoID!
    ) {
      userTeamOne(
        filter: { ownerId: $userId, roundId: $roundId, gameId: $gameId }
      ) {
        _id
        playerIds
        players {
          _id
          name
          position
          teamId
          leagueId
        }
        captainId
        setup
        scores
      }
    }
  `,

  flashOne: gql`
    query flashOne($leagueId: MongoID) {
      flashOne(filter: { leagueId: $leagueId }) {
        _id
        leagueId
        nl
        en
        date
      }
    }
  `,

  groupAndGameById: gql`
    query groupById($_id: MongoID!, $gameId: MongoID!) {
      groupById(_id: $_id) {
        _id
        name
        usersOnline
        owner {
          _id
          name
        }
        adminIds
        memberIds
        private
        email
        hasChat
        link
        s11
        gameById(_id: $gameId) {
          _id
          confirmedUserIds
          memberIds
          msg
          link
          playersChosenCounts
          playersChosenAsCaptainCounts
          s11
          allowLateJoin
          allowNewTeams
          freeSpots
          startingRoundId
          elements
          totalGame
          rules
          league {
            _id
            name
            isCup
            complete
            currentRoundId
            playerMessages
            s11Ready
            readyAt
            currentRound {
              _id
              name
              i
              isKo
            }
            start
            status
            nextRoundStart
            teams(sort: POSITION_ASC) {
              _id
              name
              position
              points
              shortCode
              played
              goals
              goalsAgainst
              logoUrl
              leagueId
              venueName
              venueCity
              venueCapacity
              recentForm
              positionHistory
              active
              won
              draw
              lost
            }
            placeholderTeams {
              _id
              name
              logoUrl
              isPlaceholder
            }
            rounds(sort: I_ASC) {
              _id
              i
              name
              incomplete
              isKo
              start
              fixtures {
                _id
                status
                start
                lId
                vId
                lScore
                vScore
                lPenScore
                vPenScore
                ftScore
                etScore
                roundId
                minute
                details
              }
            }
            periods
            oneVsOneCups
          }
        }
      }
    }
  `,
  confirmedUsersByGameId: gql`
    query gameById($gameId: MongoID!) {
      gameById(_id: $gameId) {
        _id
        confirmedUserIds
      }
    }
  `,
  standingGroupsByLeagueId: gql`
    query standingGroupMany($leagueId: MongoID!) {
      standingGroupMany(filter: { leagueId: $leagueId }) {
        _id
        name
        leagueId
        teamIds
      }
    }
  `,

  gameByIdWithPartners: gql`
    query gameById($_id: MongoID!) {
      gameById(_id: $_id) {
        _id
        partners {
          _id
          img
          link
          gameId
          status
          hide
        }
      }
    }
  `

  // gameByIdWithPartnersWithPublicFilters: gql`
  //   query gameById($_id: MongoID!) {
  //     gameById(_id: $_id) {
  //       _id
  //       partners(filter: { record: { status: "ok" } }) {
  //         _id
  //         img
  //         link
  //         status
  //         gameId
  //         hide
  //       }
  //     }
  //   }
  // `

  // groupPartners: gql`
  //   query groupById($_id: MongoID!) {
  //     groupById(_id: $_id) {
  //       _id
  //       partners
  //     }
  //   }
  // `
};

export default Query;
