import { useStoreState } from 'easy-peasy';
import translations from 'translations.js';

export default function useTranslations(customTranslations) {
  const l = useStoreState(s => s.l);

  if (customTranslations) {
    return str => {
      if (!customTranslations[str]) {
        console.log(
          'NO CUSTOM TRANSLATION',
          str,
          new Error('NO CUSTOM TRANSLATION')
        );
        return str;
      } else if (!customTranslations[str][l]) {
        console.log(
          `NO CUSTOM ${l} TRANSLATION`,
          str,
          new Error(`NO CUSTOM ${l} TRANSLATION`)
        );
        return str;
      }
      return customTranslations[str][l];
    };
  } else {
    return str => {
      if (!translations[str]) {
        console.log('NO TRANSLATION', str, new Error('NO TRANSLATION'));
        return str;
      } else if (!translations[str][l]) {
        return str;
      }
      return translations[str][l];
    };
  }
}
