export default function getLoginUrl() {
  const url = new URL(window.location.href);

  if (window.location.href.includes('?redirect')) {
    const redirect = url.searchParams.get('redirect');
    return '/login?redirect=' + redirect + url.hash;
  }

  return (
    '/login?redirect=' +
    window.location.href.replace(window.location.origin, '')
  );
}
