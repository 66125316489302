import { createStore, action } from 'easy-peasy';

const storeModel = {};

// const userPrefersDark =
//   window.matchMedia &&
//   window.matchMedia('(prefers-color-scheme: dark)').matches;

[
  {
    name: 'theme',
    useLocalStorage: true,
    def: 'dark'
  },
  { name: 'l', useLocalStorage: true, def: 'nl' },
  { name: 'drawerOpen', useLocalStorage: false, def: false },
  { name: 'route', useLocalStorage: false, def: null },
  { name: 'flashesByLeagueId', useLocalStorage: false, def: {} },
  { name: 'commentary', useLocalStorage: false, def: [] },
  { name: 'showPlayerStats', useLocalStorage: false, def: false },
  { name: 'hideInstallPrompt', useLocalStorage: true, def: 0 },
  { name: 'reportData', useLocalStorage: true, def: null, type: 'JSON' },
  { name: 'notJoinedGameIds', useLocalStorage: true, def: [], type: 'JSON' },
  {
    name: 'dismissedInviteGameIds',
    useLocalStorage: true,
    def: [],
    type: 'JSON'
  },
  {
    name: 'dismissedFlashes',
    useLocalStorage: true,
    def: [],
    type: 'JSON'
  },
  {
    name: 'homeTabValue',
    useLocalStorage: false,
    def: null
  },

  {
    name: 'fakeLogin',
    useLocalStorage: false,
    def: false
  },

  {
    name: 'scrollY',
    useLocalStorage: false,
    def: 0
  },

  {
    name: 's11GroupsOrder',
    useLocalStorage: true,
    def: null,
    type: 'JSON'
  }

  // {
  //   name: 'firstLoadedRoute',
  //   useLocalStorage: false,
  //   default: null
  // }
].forEach(({ name, def, useLocalStorage, type }) => {
  if (!useLocalStorage) {
    storeModel[name] = def;
    storeModel['set' + name.charAt(0).toUpperCase() + name.slice(1)] = action(
      (state, x) => {
        state[name] = x;
      }
    );
  } else {
    storeModel[name] =
      type === 'JSON'
        ? JSON.parse(localStorage.getItem(name))
        : localStorage.getItem(name) || def;
    storeModel['set' + name.charAt(0).toUpperCase() + name.slice(1)] = action(
      (state, x) => {
        const _x = type === 'JSON' ? JSON.stringify(x) : x;

        localStorage.setItem(name, _x);
        state[name] = x;
      }
    );
  }
});

export default createStore(storeModel);
