import React from 'react';
import ContentPage from 'Components/ContentPage';
import useTranslations from 'hooks/useTranslations';
import { Button } from '@material-ui/core';

export default function RouteNotFound() {
  const T = useTranslations();
  return (
    <ContentPage
      bannerText='Not found'
      hasFooter={true}
      hasContactBanner={true}
    >
      <div
        style={{
          marginTop: 40,
          maxWidth: 600,
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Button
          variant='contained'
          style={{ marginTop: 8 }}
          onClick={() => {
            window.location = '/';
          }}
        >
          {T('Reload app')}
        </Button>
      </div>
    </ContentPage>
  );
}
