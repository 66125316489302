import React, { lazy, Suspense, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { useStoreActions, useStoreState } from 'easy-peasy';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import useCurrentUser from 'hooks/useCurrentUser';
import useTranslations from 'hooks/useTranslations';
import MenuTopLeftNav from 'Components/MainNav/MenuTopLeftNav.js';
import getLoginUrl from 'getLoginUrl';
import getRegisterUrl from 'getRegisterUrl';
import useIsTablet from 'hooks/useIsTablet';
import PrimaryButton from 'Components/Elements/PrimaryButton';
import useIsDesktop from 'hooks/useIsDesktop';
import GreenButton from 'Components/Elements/GreenButton';

const TemporaryDrawerNotLoggedIn = lazy(() =>
  import('Components/TemporaryDrawerNotLoggedIn.js')
);

// import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
// import imgUrl from 'imgUrl';
// import useIsDesktop from 'hooks/useIsDesktop.js';
// import TopNav from 'Components/TopNav.js';
// import logo from 'branding/menu_logo_small_squared.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    zIndex: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
    // background: theme.palette.background.paperDark + 'aa',
    // backdropFilter: 'blur(10px)'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  gameSelect: {
    padding: '2px 4px',
    display: 'flex'
  }
}));

// const useMobileCenterIconStyles = makeStyles(theme => ({
//   root: {
//     // position: 'absolute',
//     // left: '50%',
//     // transform: 'translateX(-50%)',
//     // height: '100%',
//     // lineHeight: '64px'
//   },
//   IconButton: {
//     // width: 50,
//     // height: 50,
//     // padding: 0
//   },
//   img: {
//     height: 40,
//     width: 40
//   }
// }));

// function MobileBrandIcon() {
//   const classes = useMobileCenterIconStyles();
//   return (
//     <IconButton component={Link} to="/">
//       <img alt="logo" className={classes.img} src={logo} />
//     </IconButton>
//   );
// }

// function HideOnScroll(props) {
//   const { children, history } = props;
//   // Note that you normally won't need to set the history ref as useScrollTrigger
//   // will default to history.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({ target: history ? history() : undefined });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

export default function MainNavNotLoggedIn() {
  const classes = useStyles();
  const T = useTranslations();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  // const isTablet = useIsDesktop();
  // const themeType = useStoreState(s => s.theme);
  const theme = useTheme();
  // const client = useApolloClient();

  const drawerOpen = useStoreState((s) => s.drawerOpen);
  const setDrawerOpen = useStoreActions((a) => a.setDrawerOpen);

  const { currentUser } = useCurrentUser();

  const toggleDrawerOpen = () => setDrawerOpen(true);

  const history = useHistory();
  const route = useStoreState((s) => s.route); // just here to rerender appbar for colors..

  // const themeName = useStoreState(s => s.theme);
  // const setThemeName = useStoreActions(a => a.setTheme);

  const marginRight = isDesktop ? 16 : 4;

  const scrollY = useStoreState((s) => s.scrollY);

  const location = useLocation();

  const blurStr = 'saturate(180%) blur(20px)';
  const isScrolledDown = scrollY > 19; //location.hash ? false :

  const [transition] = useState(
    theme.transitions.create('all', {
      duration: theme.transitions.duration.short
    })
  );

  return (
    <>
      <div
        style={{
          background: `linear-gradient(rgba(1, 219, 254, 0.3) 0%, ${theme.palette.background.paperDark} 90%`,
          backgroundColor: theme.palette.background.paperDark,
          position: 'absolute',
          width: '100%',
          height: isTablet ? 440 : 320,
          top: 0,
          zIndex: -1
        }}
      />

      <div
        className={classes.root}
        style={
          isScrolledDown
            ? {
                WebkitBackdropFilter: blurStr,
                backdropFilter: blurStr,
                background: theme.palette.background.paper + 'aa',
                transition: transition
              }
            : // :
              //  location.hash
              // ? { background: theme.palette.background.paper }
              {}
        }

        // style={{ position: isTablet ? 'relative' : 'sticky' }}
      >
        {/* <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(1, 219, 254, 0.3) 0%, rgba(0, 15, 53, 0) 81.59%)',
            height: 440,
            zIndex: 0
          }}
        /> */}
        <AppBar
          position='sticky'
          style={{
            // ...(currentUser
            //   ? {
            //       background: theme.palette.background.paperDark,
            //       color: theme.palette.type === 'dark' ? 'white' : 'black'
            //     }
            //   : history.location.pathname === '/' ||
            //     history.location.pathname === '/login' ||
            //     history.location.pathname === '/register'
            //   ? {
            //       backgroundColor: isTablet
            //         ? theme.palette.background.paper
            //         : theme.palette.background.paperDark,
            //       color: 'white'
            //       // boxShadow: 'none'
            //     }
            //   : themeType === 'dark'
            //   ? {
            //       backgroundColor: theme.palette.background.paperDark,
            //       color: 'white'
            //     }
            //   : {
            //       background: theme.palette.background.paperDark,
            //       color: 'black'
            //     }),
            minHeight: 64,
            // height: 64,
            color: theme.palette.type === 'dark' ? 'white' : 'black',
            // transition: theme.transitions.create('background'),
            background: 'none',
            backgroundColor: 'none',
            border: 'none',

            padding: isTablet ? (isScrolledDown ? '8px 0' : '32px 0') : '',
            maxWidth: theme.breakpoints.values.lg,
            transition: transition,
            top: 0
          }}
          elevation={0}
        >
          <Toolbar
            style={{
              padding: isTablet ? '0 20px' : 0,
              minHeight: 56
              // margin: '0 auto'
            }}
          >
            {isTablet || history.location.pathname === '/' ? (
              <Link
                to='/'
                replace={history.location.pathname === '/'}
                style={{ textDecoration: 'none' }}
              >
                <div style={{ display: 'flex' }}>
                  <img
                    width={isTablet ? 245 : 163}
                    height={isTablet ? 48 : 32}
                    style={{
                      width: isTablet ? 245 : 163,
                      height: isTablet ? 48 : 32,
                      position: 'relative',
                      marginLeft: isTablet ? 0 : 16
                    }}
                    src={'/super11.svg'}
                    alt='logo'
                  />
                </div>
              </Link>
            ) : (
              <MenuTopLeftNav toggleMenu={toggleDrawerOpen} />
            )}

            {isTablet ? (
              <>
                <div style={{ flexGrow: 1 }} />

                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  {[
                    {
                      text: T(currentUser?.FAKE_LOGIN ? 'Lobby' : 'Home'),
                      active: route.path === '/',
                      to: '/'
                    },
                    {
                      text: T('What is Super11'),
                      active: route.path === '/about',
                      to: '/about'
                    },
                    {
                      text: T('Help desk'),
                      active: route.path === '/helpcenter',
                      to: '/helpcenter'
                    },
                    {
                      text: T('Contact'),
                      active: route.path === '/contact',
                      to: '/contact'
                    }
                  ].map((button) => (
                    <Button
                      key={button.to}
                      component={Link}
                      to={button.to}
                      style={{
                        textTransform: 'unset',
                        fontSize: 18,
                        // marginRight: marginRight,
                        position: 'relative',
                        marginRight: 24
                      }}
                    >
                      {button.text}
                      {button.active && (
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: 3,
                            background: theme.palette.primary.main,
                            bottom: 1,
                            borderRadius: `0 0 2px 2px`
                          }}
                        />
                      )}
                    </Button>
                  ))}

                  {/* <IconButton
                  style={{ marginRight: marginRight }}
                  onClick={() =>
                    setThemeName(themeName === 'dark' ? 'light' : 'dark')
                  }
                >
                  <BrightnessMediumRoundedIcon
                    style={{ color: themeName === 'dark' ? 'white' : 'black' }}
                  />
                </IconButton> */}

                  <div style={{ flexGrow: 1 }} />

                  <div style={{ marginTop: !currentUser ? 4 : 16 }}>
                    {!currentUser ? (
                      <>
                        <GreenButton
                          style={{
                            marginRight: marginRight,
                            fontSize: 18,
                            padding: '8px 18px'
                            // border: `2px solid ${theme.palette.primary.main}`
                          }}
                          component={Link}
                          to={getRegisterUrl()}
                        >
                          {T('Register')}
                        </GreenButton>
                        <PrimaryButton
                          style={{
                            border: `2px solid ${theme.palette.primary.main}`,
                            background: 'none',
                            marginRight: marginRight,
                            fontSize: 18,
                            padding: '6px 16px'
                          }}
                          component={Link}
                          to={getLoginUrl()}
                        >
                          {T('Login')}
                        </PrimaryButton>
                      </>
                    ) : (
                      <GreenButton component={Link} to='/'>
                        {T('Open app')}
                      </GreenButton>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div style={{ flexGrow: 1 }} />
            )}

            {/* <div style={{ display: 'flex' }}> */}

            {!isTablet && (
              <IconButton
                onClick={toggleDrawerOpen}
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                edge='start'
                color='inherit'
                style={{
                  borderRadius: theme.shape.borderRadius,
                  background: theme.palette.primary.main,
                  width: 48,
                  height: 48,
                  // marginTop: 8,
                  marginLeft: 8,
                  padding: 0,
                  marginRight: 8,
                  position: 'relative',
                  top: 4
                }}
              >
                <MenuRoundedIcon
                  style={{ width: 36, height: 36, color: 'black' }}
                />
              </IconButton>
            )}

            {/* {isTablet ? (
                <div
                  style={{
                    marginTop: 8,
                    position: 'absolute',
                    display: 'flex',
                    right: 0,
                    top: 0
                  }}
                >
                  <TabletRightIcon
                    text={T('Account')}
                    Icon={currentUser ? UserAvatar : AccountCircle}
                    onClick={handleMenu}
                    divStyle={{ marginRight: 4 }}
                    iconProps={
                      currentUser
                        ? {
                            size: 40,
                            src: currentUser.avatar + '_50.jpg'
                          }
                        : {
                            style: {
                              width: 33,
                              height: 33
                            }
                          }
                    }
                  />
                </div>
              ) : null}

              {!isTablet ? (
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  // component={Link}
                  // to={'/user-profile'}
                  onClick={handleMenu}
                  color="inherit"
                  style={{
                    width: 48,
                    height: 48,
                    background: currentUser
                      ? 'none'
                      : theme.palette.type === 'dark'
                      ? 'white'
                      : 'black',
                    color: theme.palette.type === 'dark' ? 'black' : 'white',
                    marginTop: 6,
                    marginRight: 6,
                    padding: 0
                  }}
                >
                  {currentUser ? (
                    <UserAvatar
                      size={48}
                      src={currentUser.avatar + '_50.jpg'}
                    />
                  ) : (
                    <AccountCircle
                      style={{
                        width: 48,
                        height: 48
                      }}
                    />
                  )}
                </IconButton>
              ) : null}

              <Menu
                id="menu-appbar"
                anchorEl={menuAnchor}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={menuOpen}
                onClose={handleDefaultOrSettingsClose}
              >
                <MenuItem
                  key="/login"
                  component={Link}
                  to={getLoginUrl()}
                  onClick={handleDefaultOrSettingsClose}
                  replace={location.pathname === '/login'}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText>{T('Login')}</ListItemText>
                </MenuItem>
                <MenuItem
                  key="/register"
                  component={Link}
                  to={getRegisterUrl()}
                  onClick={handleDefaultOrSettingsClose}
                  replace={location.pathname === '/register'}
                >
                  <ListItemIcon>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText>{T('Register')}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem button={false}>
                  <ListItemIcon>
                    <BrightnessMediumIcon />
                  </ListItemIcon>
                  <ListItemText style={{ marginRight: 28 }}>
                    Dark theme&nbsp;
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <ThemeSwitch />
                  </ListItemSecondaryAction>
                </MenuItem>
                <MenuItem button={false}>
                  <ListItemIcon>
                    <TranslateIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {T('Language')}:&nbsp;
                    <b style={{ textTransform: 'uppercase' }}>{l}</b>
                  </ListItemText>
                  <ListItemSecondaryAction style={{ cursor: 'pointer' }}>
                    <LanguageSelect />
                  </ListItemSecondaryAction>
                </MenuItem>
              </Menu> */}
            {/* </div> */}
          </Toolbar>
        </AppBar>
      </div>
      {drawerOpen ? (
        <Suspense fallback={null}>
          <TemporaryDrawerNotLoggedIn myGroups={[]} from='right' />
        </Suspense>
      ) : null}
    </>
  );
}

// function TabletRightIcon({ Icon, onClick, text, iconProps, divStyle }) {
//   const theme = useTheme();

//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         textAlign: 'center',
//         width: 50,
//         cursor: 'pointer',
//         marginRight: 8,
//         marginTop: -4,
//         ...divStyle
//       }}
//       onClick={onClick}
//     >
//       <IconButton
//         style={{
//           width: 40,
//           height: 40,
//           background: theme.palette.background.paper,
//           color:
//             theme.palette.type === 'dark'
//               ? theme.palette.text.primary
//               : theme.palette.text.primary,
//           margin: '0 auto'
//           // marginRight: 16
//         }}
//       >
//         <Icon {...iconProps} />
//       </IconButton>
//       <Typography
//         style={{
//           fontSize: 10,
//           position: 'relative',
//           top: 4,
//           marginTop: -2,
//           textTransform: 'uppercase',
//           color: theme.palette.text.secondary
//         }}
//       >
//         {text}
//       </Typography>
//     </div>
//   );
// }

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { makeStyles, AppBar, Toolbar, IconButton } from '@material-ui/core';

// import useCurrentUser from 'hooks/useCurrentUser';

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1
//   },
//   menuButton: {
//     marginRight: theme.spacing(2)
//   },
//   title: {
//     flexGrow: 1
//   }
// }));

// export default function MainNav() {
//   const classes = useStyles();
//   const { currentUser } = useCurrentUser();

//   return (
//     <div className={classes.root}>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             className={classes.menuButton}
//             color="inherit"
//             aria-label="menu"
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" className={classes.title}>
//             News
//           </Typography>
//           <Button color="inherit">Login</Button>
//         </Toolbar>
//       </AppBar>
//     </div>
//   );

//   // return (
//   //   <>
//   //     <Link to="/">Home</Link> <Link to="/users">Users</Link>{' '}
//   //     {!currentUser ? (
//   //       <>
//   //         <Link to="/login">Login</Link> <Link to="/register">Register</Link>{' '}
//   //       </>
//   //     ) : null}
//   //     {currentUser ? <Link to="/user-profile">Profile</Link> : null}{' '}
//   //     {currentUser ? <Link to="/logout">Logout</Link> : null}
//   //   </>
//   // );
// }
