import React, { lazy, Suspense } from 'react';
import AppWrapper from 'Components/AppWrapper';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsTablet from 'hooks/useIsTablet';
import MainNavNotLoggedIn from './MainNav/MainNavNotLoggedIn';
import { useTheme } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import { usePWAInstall } from 'react-use-pwa-install';
const BottomNav = lazy(() => import('Components/BottomNav.js'));
const MainNav = lazy(() => import('Components/MainNav'));

function Loading() {
  const theme = useTheme();
  return (
    <div
      // elevation={}
      style={{
        borderRadius: 0,
        height: '64px',
        width: '100%',
        top: 0,
        background: theme.palette.background.paperDark,
        position: 'fixed'
      }}
    ></div>
  );
}

function App() {
  // just here to make sure early trigger is ok?
  usePWAInstall();

  const { currentUser, loading } = useCurrentUser();

  const isTablet = useIsTablet();

  // const isTablet = useIsTablet();

  const route = useStoreState(s => s.route);

  if (route?.path === '/' && currentUser) {
    route.isContentPage = false;
  }

  return (
    <AppWrapper
      before={
        route?.isContentPage &&
        (!currentUser || currentUser?.FAKE_LOGIN) &&
        !loading ? (
          <Suspense fallback={<Loading />}>
            <MainNavNotLoggedIn />
          </Suspense>
        ) : currentUser?.FAKE_LOGIN ? (
          <Suspense fallback={<Loading />}>
            <MainNav notLoggedInDrawer={true} />
          </Suspense>
        ) : !loading ? (
          <Suspense fallback={<Loading />}>
            <MainNav />
          </Suspense>
        ) : (
          <Loading />
        )
      }
      after={
        <>
          {isTablet && currentUser ? (
            <Suspense fallback={null}>
              <BottomNav />
            </Suspense>
          ) : null}
        </>
      }
    />
  );
}

export default App;
