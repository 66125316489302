import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function CenteredLoader({ style = { marginTop: 16 } } = {}) {
  return (
    <center>
      <CircularProgress style={style} />
    </center>
  );
}
