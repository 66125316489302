import React from 'react';
import { useStoreState } from 'easy-peasy';
import useTranslations from 'hooks/useTranslations';
import useIsTablet from 'hooks/useIsTablet';
import { Container, Typography, Paper } from '@material-ui/core';
import GreenButton from './Elements/GreenButton';

export default function UnderConstruction({
  refetchUnderConstruction,
  underConstruction,
  loadingUnderConstruction,
  errorUnderConstruction
}) {
  const l = useStoreState(s => s.l);
  const T = useTranslations();
  const isTablet = useIsTablet();

  console.log('underConstruction', underConstruction);
  console.log('errorUnderConstruction', errorUnderConstruction);
  console.log('loadingUnderConstruction', loadingUnderConstruction);

  if (
    (underConstruction || errorUnderConstruction) &&
    !loadingUnderConstruction
  ) {
    return (
      <Container
        maxWidth="sm"
        style={{
          height: '100%',
          marginTop: isTablet ? 0 : -64,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Paper
          elevation={0}
          style={{ textAlign: 'center', background: 'none' }}
        >
          <Typography variant="h4">Super11</Typography>

          <Typography variant="h5">Under Construction</Typography>

          <Typography>
            {underConstruction
              ? underConstruction.text[l]
              : T('Server offline or you are offline')}
          </Typography>

          <GreenButton
            onClick={() => refetchUnderConstruction()}
            style={{
              zIndex: 2,
              position: 'relative',
              marginBottom: 16,
              marginTop: 16
            }}
          >
            {T('Reload')}
          </GreenButton>
        </Paper>
      </Container>
    );
  }

  return null;
}
