import React, { useState } from 'react';
import { TextField, useTheme, Typography } from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import { MAILCHIMP_ID, MAILCHIMP_U } from 'env.js';
import GreenButton from './Elements/GreenButton';
import useTranslations from 'hooks/useTranslations';

import jsonp from 'jsonp';

const url =
  `https://super11.us1.list-manage.com/subscribe/post-json` +
  `?u=${MAILCHIMP_U}` +
  `&id=${MAILCHIMP_ID}`;

export default function EmailSubscribe() {
  const theme = useTheme();
  const T = useTranslations();

  const [email, setEmail] = useState('');

  const [reqState, setReqState] = useState({});

  function onSubscribeClick() {
    setReqState({
      status: 'loading',
      message: T('Sending') + '..'
    });

    jsonp(
      `${url}&EMAIL=${email}`,
      {
        param: 'c'
      },
      (err, data) => {
        if (err) {
          setReqState({
            status: 'error',
            message: err
          });
        } else if (data.result !== 'success') {
          setReqState({
            status: 'error',
            message: data.msg
          });
        } else {
          setReqState({
            status: 'success',
            message: data.msg
          });
        }
      }
    );
  }

  const buttonSize = 40;

  return (
    <div style={{ marginBottom: 16, textAlign: 'left' }}>
      {!reqState.status ? (
        <Typography>{T('Subscribe for updates and news')}</Typography>
      ) : reqState.status === 'error' ? (
        <Typography color="error">
          {T('Fout bij aanmelden')}:{' '}
          <span dangerouslySetInnerHTML={{ __html: reqState.message }}></span>
        </Typography>
      ) : reqState.status === 'success' ? (
        <Typography style={{ color: theme.palette.success.main }}>
          {T('You are subscribed to the newsletter')}!
        </Typography>
      ) : (
        <Typography>{reqState.message}</Typography>
      )}
      <div style={{ display: 'flex', width: '100%%' }}>
        <TextField
          disabled={
            reqState.status === 'success' || reqState.status === 'loading'
          }
          size="small"
          variant="outlined"
          // label="email"
          placeholder={T('your@email.com')}
          value={email}
          onChange={evt => setEmail(evt.target.value)}
          style={{
            background: theme.palette.background.paperDark
          }}
          // inputProps={{
          //   style: {
          //     height: 30
          //   }
          // }}
        />
        <GreenButton
          onClick={onSubscribeClick}
          disabled={
            reqState.status === 'success' || reqState.status === 'loading'
          }
          style={{
            height: buttonSize,
            width: buttonSize,
            minHeight: buttonSize,
            minWidth: buttonSize,
            // color: 'white',
            marginLeft: 8
          }}
        >
          <ArrowForwardRoundedIcon />
        </GreenButton>
      </div>
    </div>
  );
}
