import React, { Suspense, lazy, useState, useRef, useEffect } from 'react';
import {
  Typography,
  useTheme,
  Paper,
  Fade,
  withStyles
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import useCurrentUser from 'hooks/useCurrentUser';
import PrimaryButton from 'Components/Elements/PrimaryButton';
import useTranslations from 'hooks/useTranslations';
import { Link, useHistory } from 'react-router-dom';
import { ASSET_URL } from 'env';
import getLoginUrl from 'getLoginUrl';
import getRegisterUrl from 'getRegisterUrl';
import useIsTablet from 'hooks/useIsTablet';
import SwipeableViews from 'react-swipeable-views';
import PersonIcon from '@material-ui/icons/Person';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Footer from 'Components/Footer';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CenteredLoader from 'Components/CenteredLoader';
import PublicRoundedIcon from '@material-ui/icons/PublicRounded';
import BallIcon from 'icons/BallIcon';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import TrophyIcon from 'icons/TrophyIcon';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import FlashOnRoundedIcon from '@material-ui/icons/FlashOnRounded';
import useWindowWidth from 'hooks/useWindowWidth';
import { useQuery, gql } from '@apollo/client';
import GreenButton from 'Components/Elements/GreenButton';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ContentPageBottomCTA from 'Components/ContentPageBottomCTA';

const InviteGroupInfo = lazy(() =>
  import('Components/Routes/Home/InviteGroupInfo.js')
);

function Ctas({ containerStyle = {} }) {
  const theme = useTheme();
  const tr = useTranslations();
  const isTablet = useIsTablet();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: isTablet ? 'row' : 'column',
        textAlign: 'center',
        ...containerStyle
      }}
    >
      <GreenButton
        component={Link}
        to={getRegisterUrl()}
        style={{
          background: theme.palette.primary.main,
          border: 'none',
          width: isTablet ? 300 : 'calc(100% - 32px)',
          fontSize: 18,
          marginRight: isTablet ? 24 : 0,
          ...(isTablet ? {} : { margin: '0 auto' })
        }}
      >
        {tr('Register now')}
      </GreenButton>

      <PrimaryButton
        component={Link}
        to={getLoginUrl()}
        style={{
          border: `2px solid ${theme.palette.primary.main}`,
          background: 'none',
          width: isTablet ? 300 : 'calc(100% - 32px)',
          fontSize: 18,
          ...(isTablet ? {} : { margin: '0 auto' }),
          marginTop: isTablet ? 0 : 12
        }}
      >
        {tr('Login')}
      </PrimaryButton>
    </div>
  );
}
const REVIEWS = [
  {
    img: <PersonIcon style={{ width: 75, height: 75 }} />,
    name: 'Richard van Gelderen',
    text: {
      en: 'Our cafe in Leeuwarden plays Super11. 124 of our guests are playing and we are playing with a fun prize pool. It is amazing that everything is live and that we see the standings update live after every goal in the Eredivisie. Keep it up.',
      nl: 'Ons café in Leeuwarden speelt mee met Super11. Er doen 124 "stamgasten" mee en we spelen met een leuke prijzenpot. Geweldig dat alles live is en dat wij direct na elk doelpunt in de Eredivisie de tussenstand zien veranderen. Ga zo door.'
    },
    rating: '5.0'
  },
  {
    img: <PersonIcon style={{ width: 75, height: 75 }} />,
    name: 'Niels Jacobs',
    text: {
      en: 'The European Championship game is amazing. I used to organize a pool using an Excell file but now I can use Super11 for this! The fact that I can configure everything is just perfect. What a great invention! :)',
      nl: 'Het EK spel is fantastisch. Ik organiseerde altijd een EK poule via een Excell bestand maar ik kan nu Super11 hiervoor gebruiken! Dat ik alles zelf kan instellen is perfect. Wat een geweldige uitvinding! :)'
    },
    rating: '5.0'
  },
  {
    img: <PersonIcon style={{ width: 75, height: 75 }} />,
    name: 'Sander Bouma',
    text: {
      en: 'With our football community we have our own group. It really feels as if we have our own football app. Besides playing the game I use the Super11 app to check all scores and stats of competitions. I receive updates about goals within seconds!',
      nl: 'Met onze voetbalvereniging hebben wij onze eigen groep. Het voelt echt alsof wij onze eigen voetbal app hebben. Naast het Super11 gedeelte gebruik ik de app ook om alle scores en statistieken van een competitie te volgen. Doelpunten krijg ik altijd binnen een seconde binnen!'
    },
    rating: '5.0'
  }
];
const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.background.scorePositive,
    fontSize: '2rem'
  }
}))(Rating);

function Reviews() {
  const theme = useTheme();
  const isTablet = useIsTablet();
  const [index, setIndex] = useState(0);
  const l = useStoreState((s) => s.l);

  function getReviewEl(review, i, style = {}) {
    return (
      <div
        key={i}
        style={{
          width: isTablet ? '30%' : 'calc(100% - 16px)',

          borderRadius: 4,
          margin: isTablet ? '' : '0 auto',
          marginBottom: 8,
          cursor: !isTablet ? 'grab' : '',
          padding: isTablet ? 32 : 16,
          position: 'relative',
          zIndex: 1,
          background:
            'linear-gradient(180deg, rgba(126, 39, 225, 0.15) 0%, rgba(0, 10, 37, 0) 60.94%)',
          ...style
        }}
      >
        <div>
          <StyledRating
            precision={0.5}
            defaultValue={2.5}
            readOnly
            value={parseFloat(review.rating)}
            icon={<StarRoundedIcon fontSize='inherit' />}
          />

          <Typography
            color={
              theme.palette.type === 'dark' ? 'textSecondary' : 'textSecondary'
            }
            style={{
              textAlign: 'left',
              fontSize: isTablet ? 16 : 14,
              lineHeight: '26px',
              marginTop: 8
            }}
          >
            {review.text[l]}
          </Typography>

          <Typography
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 16,
              letterSpacing: '5%',
              lineHeight: '24px',
              marginTop: 8
            }}
          >
            — {review.name}
          </Typography>
        </div>

        {/* <div style={{ display: 'flex' }}>
          {review.img}
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: isTablet ? 'row' : 'column',
                textAlign: isTablet ? 'center' : 'left'
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  marginRight: isTablet ? 8 : 0
                }}
              >
                {review.name}
              </Typography>{' '}
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  top: isTablet ? -2 : 0,
                  marginLeft: isTablet ? 0 : -2
                }}
              >
                <StyledRating
                  precision={0.5}
                  defaultValue={2.5}
                  readOnly
                  value={parseFloat(review.rating)}
                  icon={<StarRoundedIcon fontSize="inherit" />}
                />
                {!isTablet ? (
                  <Typography
                    color="textSecondary"
                    style={{
                      position: 'relative',
                      top: 2,
                      marginLeft: 4
                    }}
                  >
                    {review.rating}
                  </Typography>
                ) : null}
              </div>
            </div>
            {isTablet ? (
              <Typography
                color={
                  theme.palette.type === 'dark'
                    ? 'textSecondary'
                    : 'textPrimary'
                }
                style={{
                  textAlign: 'left',
                  fontSize: isTablet ? 18 : 14
                }}
              >
                {review.text[l]}
              </Typography>
            ) : null}
          </div>
        </div> */}

        {/* {!isTablet ? (
          <Typography style={{ textAlign: 'left' }}>
            {review.text[l]}
          </Typography>
        ) : null} */}
      </div>
    );
  }

  const windowWidth = useWindowWidth();

  return (
    <>
      <Paper
        elevation={0}
        style={{
          marginTop: isTablet ? 40 : 0,
          padding: '16px 0',
          marginBottom: 8,
          marginLeft: isTablet ? 0 : 16,
          marginRight: isTablet ? 0 : 16,
          background: 'none',
          borderRadius: isTablet ? 0 : 40,
          position: 'relative'
        }}
      >
        {isTablet ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {getReviewEl(REVIEWS[0], 0)}
            {getReviewEl(REVIEWS[1], 1, { marginTop: 48 })}
            {getReviewEl(REVIEWS[2], 2)}
          </div>
        ) : (
          <>
            <SwipeableViews
              index={index}
              enableMouseEvents={true}
              onChangeIndex={(newIndex) => setIndex(newIndex)}
              containerStyle={
                !isTablet
                  ? {
                      width: windowWidth - 80
                      // paddingRight: 80
                    }
                  : {}
              }
            >
              {REVIEWS.map((review, i) => getReviewEl(review, i))}
            </SwipeableViews>
            {/* <PaginationButtons
              amount={REVIEWS.length}
              index={index}
              setIndex={setIndex}
            /> */}
          </>
        )}
      </Paper>
    </>
  );
}

function NotLoggedInHome2() {
  const isTablet = useIsTablet();
  const theme = useTheme();

  const isInvited = window.location.hash.includes('groupJoin_');

  return (
    <>
      <Paper
        elevation={0}
        style={{
          maxWidth: theme.breakpoints.values.lg,
          margin: '0 auto',
          padding: isTablet ? 32 : 8,
          background: 'none',
          overflow: isTablet ? 'visible' : 'visible'
        }}
      >
        {isInvited ? (
          <div style={{ minHeight: 250, marginTop: 8, marginBottom: 40 }}>
            <Suspense fallback={<CenteredLoader />}>
              <InviteGroupInfo RenderAtBottom={() => <Ctas />} />
            </Suspense>
          </div>
        ) : null}

        <Hero />

        <Reviews />
        <InviteText />

        <Previews />

        <Leagues />

        <div style={{ textAlign: isTablet ? 'center' : 'left', marginTop: 80 }}>
          <ContentPageBottomCTA />
        </div>
      </Paper>
      <Footer />
    </>
  );
}

function Hero() {
  const history = useHistory();
  const isTablet = useIsTablet();
  const theme = useTheme();
  const tr = useTranslations();

  const setFakeLogin = useStoreActions((a) => a.setFakeLogin);

  const heroImg = `${ASSET_URL}/home2/phone_hero${
    // retStr || isTablet ? '@2x' : ''
    '@2x'
  }`;

  // ar 17∶30

  const windowWidth = useWindowWidth();

  const ar = 17 / 30;

  const [imgLoaded, setImgLoaded] = useState(false);

  let imgWidth = isTablet ? 476 : windowWidth;
  let imgHeight = isTablet ? 840 : windowWidth / ar;

  let tenPercentImgWidth;

  if (!isTablet) {
    tenPercentImgWidth = imgWidth / 10;
    imgWidth -= tenPercentImgWidth * 2;

    imgHeight -= imgHeight / 5;
  }

  const transition = theme.transitions.create();

  // const leagueImgAr = 190 / 51;
  // const leagueImgWidth = isTablet ? 160 : (windowWidth - 16) / 3;
  // const leagueImgHeight = leagueImgWidth / leagueImgAr;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isTablet ? 'row' : 'column',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ width: isTablet ? '50%' : '100%', display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              background: `linear-gradient(180deg, rgba(1, 219, 254, 0.57) 0%, #17FF8A 100%)`,
              position: 'absolute',
              width: '100%',
              height: '100%',
              transform: 'rotate(-15deg)',
              top: 0,
              zIndex: -1,
              borderRadius: 30,
              filter: 'blur(150px)',
              opacity: imgLoaded ? 1 : 0,

              transition: transition
            }}
          />

          <picture onLoad={() => setImgLoaded(true)}>
            <source srcSet={`${heroImg}.webp`} type='image/webp' />
            <img
              // loading="lazy"
              alt=''
              src={`${heroImg}.png`}
              width={imgWidth}
              height={imgHeight}
              style={{
                zIndex: 1,
                position: 'relative',
                ...(isTablet
                  ? {}
                  : {
                      width: imgWidth,
                      left: tenPercentImgWidth,
                      height: imgHeight,
                      position: 'relative'
                    }),

                opacity: imgLoaded ? 1 : 0,
                transition: transition
              }}
            />
          </picture>

          <img
            alt=''
            src={`${ASSET_URL}/home2/qr.svg`}
            style={{
              position: 'absolute',
              top: '10%',
              left: isTablet ? '60%' : '70%',
              padding: 8,
              background: theme.palette.background.gradientOrange,
              width: isTablet ? 182 : 112,
              height: isTablet ? 182 : 112,
              borderRadius: theme.shape.borderRadius,
              zIndex: 1,
              opacity: imgLoaded ? 1 : 0,
              transition: transition
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: isTablet ? '40%' : '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant='h1'
          style={{
            fontWeight: '800',
            fontSize: isTablet ? 64 : 36,
            lineHeight: isTablet ? '72px' : '42px',
            padding: isTablet ? 0 : 16,
            marginTop: isTablet ? 0 : 8
          }}
        >
          {tr('Play the most fun')}
          <br />
          <span style={{ color: theme.palette.primary.main }}>
            real-time&nbsp;
          </span>
          {tr('live football games with us')}.
        </Typography>

        <div
          style={{
            display: 'flex',
            marginTop: isTablet ? 48 : 16,
            marginBottom: isTablet ? 0 : 32
          }}
        >
          <img
            alt='League logos'
            width='570'
            height='51'
            style={{ width: '100%', height: 'auto' }}
            src={`${ASSET_URL}/leagues/leagues_white.png`}
          />

          {/* <img
            width={leagueImgWidth}
            height={leagueImgHeight}
            style={{
              // width: '33%',
              // height: 'auto',
              filter: 'brightness(100)'
            }}
            src={`${ASSET_URL}/leagues/Eredivisie_full.png`}
            alt=""
          />
          <img
            width={leagueImgWidth}
            height={leagueImgHeight}
            style={{
              // width: '33%',
              // height: 'auto',
              filter: 'brightness(100)'
            }}
            src={`${ASSET_URL}/leagues/Premier League_full.png`}
            alt=""
          />
          <img
            width={leagueImgWidth}
            height={leagueImgHeight}
            style={{
              // width: '33%',
              // height: 'auto',
              filter: 'brightness(100)'
            }}
            src={`${ASSET_URL}/leagues/Euro_full.png`}
            alt=""
          /> */}
        </div>

        {isTablet && (
          <div style={{ marginTop: 48 }}>
            <PrimaryButton
              style={{
                fontSize: 21,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: 'rgba(255, 255, 255, 0.25)'
              }}
              onClick={() => {
                setFakeLogin(true);
                history.replace('/');
              }}
            >
              {tr('Open Super11 App')}
            </PrimaryButton>

            <Typography
              style={{
                fontSize: 13,
                color: theme.palette.text.secondary,
                marginTop: 8
              }}
            >
              {tr(
                'You can use the Super11 app without an account. In order to play games you need to create an account easily.'
              )}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

function InviteText() {
  const tr = useTranslations();
  const isTablet = useIsTablet();
  const theme = useTheme();

  const containerRef = useRef();
  const windowWidth = useWindowWidth();

  const [gradientOffset, setGradientOffset] = useState(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      let _gradientOffset = -(
        (windowWidth - containerRef.current.offsetWidth) / 2 +
        300
      );

      if (_gradientOffset < -424) _gradientOffset = -424;

      setGradientOffset(_gradientOffset);
    }
  }, [containerRef, windowWidth]);

  return (
    <div
      style={{ padding: 8, marginTop: 32, position: 'relative' }}
      ref={containerRef}
    >
      {isTablet && !isNaN(gradientOffset) ? (
        <div
          style={{
            filter: 'blur(400px)',
            background: '#7E27E1',
            width: 600,
            height: 900,
            position: 'absolute',
            top: 0,
            right: gradientOffset,
            zIndex: 0
          }}
        />
      ) : null}

      <Typography
        style={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: 12,
          lineHeight: '16px',
          marginBottom: 8,
          zIndex: 1,
          position: 'relative'
        }}
      >
        {tr('Invite your friends')}!
      </Typography>

      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: isTablet ? 32 : 24,
          lineHeight: isTablet ? '36px' : '28px',
          letterSpacing: '-1%',
          width: isTablet ? '70%' : '',
          zIndex: 1,
          position: 'relative'
        }}
      >
        <span style={{ color: theme.palette.primary.main }}>
          {tr('Super11 is The football app')}&nbsp;
        </span>
        {tr(
          'which makes it possible to organize football games based on real-time football data. This way the Eredivisie becomes even more fun to follow. But of course the most fun is to play with your Super11 team during the European Championship or the World Cup!'
        )}
      </Typography>
    </div>
  );
}

function Previews() {
  const theme = useTheme();
  const isTablet = useIsTablet();
  const l = useStoreState((s) => s.l);

  const previews = [
    {
      title: {
        en: 'Live data',
        nl: 'Live data'
      },
      text: {
        en: 'Follow all matches live. All stats and goals update live and in real-time. The Super11 game standings and scores are also updated within seconds.',
        nl: 'Bekijk en volg alle wedstrijden live. Alle statistieken en doelpunten updaten direct en in real-time. Ook de tussenstanden en scores binnen de Super11 spellen zijn binnen enkele secondes bijgewerkt.'
      },
      Icon: PublicRoundedIcon
    },
    {
      title: {
        en: 'Your Super11 Team',
        nl: 'Je Super11 Team'
      },
      text: {
        en: 'Follow all matches live. All stats and goals update live and in real-time. The Super11 game standings and scores are also updated within seconds.',
        nl: 'Bekijk en volg alle wedstrijden live. Alle statistieken en doelpunten updaten direct en in real-time. Ook de tussenstanden en scores binnen de Super11 spellen zijn binnen enkele secondes bijgewerkt.'
      },
      Icon: BallIcon,
      iconStyle: { position: 'relative', top: 4, left: 4 },
      iconStyleActive: { position: 'relative', top: 6, left: 6 }
    },
    {
      title: {
        en: 'Custom prize pool',
        nl: 'Eigen prijzenpot'
      },
      text: {
        en: 'Follow all matches live. All stats and goals update live and in real-time. The Super11 game standings and scores are also updated within seconds.',
        nl: 'Bekijk en volg alle wedstrijden live. Alle statistieken en doelpunten updaten direct en in real-time. Ook de tussenstanden en scores binnen de Super11 spellen zijn binnen enkele secondes bijgewerkt.'
      },
      Icon: LocalAtmRoundedIcon
    },
    {
      title: {
        en: 'Predict matches',
        nl: 'Voorspel wedstrijden'
      },
      text: {
        en: 'Follow all matches live. All stats and goals update live and in real-time. The Super11 game standings and scores are also updated within seconds.',
        nl: 'Bekijk en volg alle wedstrijden live. Alle statistieken en doelpunten updaten direct en in real-time. Ook de tussenstanden en scores binnen de Super11 spellen zijn binnen enkele secondes bijgewerkt.'
      },
      Icon: TrophyIcon,
      iconStyle: { position: 'relative', top: 4, left: 1 },
      iconStyleActive: { position: 'relative', top: 6, left: 2 }
    },
    {
      title: {
        en: 'Join or create a group',
        nl: 'Zoek of maak een groep'
      },
      text: {
        en: 'Follow all matches live. All stats and goals update live and in real-time. The Super11 game standings and scores are also updated within seconds.',
        nl: 'Bekijk en volg alle wedstrijden live. Alle statistieken en doelpunten updaten direct en in real-time. Ook de tussenstanden en scores binnen de Super11 spellen zijn binnen enkele secondes bijgewerkt.'
      },
      Icon: GroupRoundedIcon
    },
    {
      title: {
        en: '1 vs 1 bonus game',
        nl: '1 vs 1 bonusspel'
      },
      text: {
        en: 'Follow all matches live. All stats and goals update live and in real-time. The Super11 game standings and scores are also updated within seconds.',
        nl: 'Bekijk en volg alle wedstrijden live. Alle statistieken en doelpunten updaten direct en in real-time. Ook de tussenstanden en scores binnen de Super11 spellen zijn binnen enkele secondes bijgewerkt.'
      },
      Icon: FlashOnRoundedIcon
    }
  ];

  const [active, setActive] = useState(-1);

  const heroImg = `${ASSET_URL}/home2/laptop${
    // retStr || isTablet ? '@2x' : ''
    '@2x'
  }`;

  const containerRef = useRef();
  // const windowWidth = useWindowWidth();
  // const imgOffset = -(
  //   (windowWidth - containerRef.current?.offsetWidth) / 2 +
  //   50
  // );

  const width = 740.5 * 1.25;
  const height = 464.5 * 1.25;

  return (
    <div
      style={{
        padding: 8,
        marginTop: 64,
        display: 'flex',
        flexDirection: isTablet ? 'row' : 'column',
        position: 'relative',
        zIndex: 1
      }}
      ref={containerRef}
    >
      <div style={{ width: isTablet ? '45%' : '100%' }}>
        {previews.map((preview, i) => {
          const isActive = i === active;
          const size = isActive ? 50 + 32 : 32 + 32;
          const margin = 16;
          return (
            <div
              key={i}
              style={{
                display: 'flex',
                // cursor: 'pointer',
                marginTop: margin,
                marginBottom: margin
                // alignItems: 'center'
              }}
              // onClick={() => setActive(i)}
            >
              <div
                style={{
                  color: isActive
                    ? theme.palette.background.paper
                    : theme.palette.primary.main,
                  background: isActive
                    ? 'linear-gradient(135deg, #17FF8A 0%, #01DBFE 100%)'
                    : theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  width: size,
                  height: size,
                  padding: 16,
                  marginLeft: isActive ? 0 : 16,
                  marginRight: 16

                  // transition: theme.transitions.create()
                }}
              >
                <preview.Icon
                  style={{
                    width: size - 32,
                    height: size - 32,
                    ...((isActive
                      ? preview.iconStyleActive
                      : preview.iconStyle) || {})
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  style={{
                    color: isActive
                      ? theme.palette.primary.main
                      : theme.palette.primary.dark,
                    fontWeight: 'bold',
                    fontSize: isActive ? 24 : 20,
                    lineHeight: '28px'
                  }}
                >
                  {preview.title[l]}{' '}
                  {/* {!isActive && (
                    <ExpandMoreRoundedIcon
                      style={{
                        background: theme.palette.background.paper,
                        color: theme.palette.primary.main,
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        position: 'relative',
                        top: 4
                      }}
                    />
                  )} */}
                </Typography>
                {isActive && (
                  <Typography style={{ fontSize: 16, lineHeight: '26px' }}>
                    {preview.text[l]}
                  </Typography>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: isTablet ? '55%' : 'calc(100% + 32px)',
          position: 'relative',
          left: -16,
          overflow: isTablet ? 'visible' : 'hidden'
        }}
      >
        <picture>
          <source srcSet={`${heroImg}.webp`} type='image/webp' />
          <img
            loading='lazy'
            alt=''
            src={`${heroImg}.png`}
            width={width}
            height={height}
            style={{
              zIndex: 1,
              position: 'absolute',
              right: -200,
              top: 0,

              ...(isTablet
                ? {}
                : {
                    width: 'calc(120% - 16px)',
                    height: 'auto',
                    top: 0,
                    left: '-10%',
                    position: 'relative',
                    marginTop: 16
                  })
            }}
          />
        </picture>
      </div>
    </div>
  );
}

function Leagues() {
  const theme = useTheme();
  const tr = useTranslations();
  const isTablet = useIsTablet();
  const history = useHistory();

  const { data: { s11GroupMany } = {} } = useQuery(gql`
    query s11GroupMany {
      s11GroupMany(filter: { s11: true }) {
        _id
        memberCount
        name
        games {
          _id
          league {
            _id
            name
            countryFlagUrl
            s11Ready
            start
          }
        }
      }
    }
  `);

  const setFakeLogin = useStoreActions((a) => a.setFakeLogin);

  // const [index, setIndex] = useState(0);

  function getLeague(group, i) {
    const game = group.games.reduce((a, v) => {
      if (!a || v.league.start > a.league.start) a = v;

      return a;
    }, undefined);

    if (!game) {
      console.log('No game for group', group);
      return null;
    }

    // const league = game.league;

    const leagueName = game.league.name
      .replace(/\s\d+\/\d+/, '')
      .replace(/\s\d\d\d\d/, '');

    // const isActive = i === index;
    const isActive = false;

    // const ButtonToUse = isActive ? GreenButton : PrimaryButton;

    return (
      <Paper
        key={i}
        // onClick={evt => {
        //   if (index !== i) {
        //     evt.preventDefault();
        //     evt.stopPropagation();
        //     setIndex(i);
        //   }
        // }}
        style={{
          width: isTablet ? 'calc((100% / 3) - 16px)' : '',
          minWidth: isTablet ? 'calc((100% / 3) - 16px)' : '',

          // background: isActive
          //   ? theme.palette.background.gradientGreen
          //   : theme.palette.background.gradient1,
          background: theme.palette.background.gradient1,

          transition: theme.transitions.create(),
          padding: 16,
          marginTop: isTablet ? 12 : 8,
          marginBottom: isTablet ? 12 : 8,
          marginRight: isTablet && (i + 1) % 3 !== 0 ? 24 : 0,
          position: 'relative',
          zIndex: 1
        }}
      >
        <div
          style={{
            background: 'rgba(247, 247, 247, 0.8)',
            padding: '16px 8px',
            borderRadius: theme.shape.borderRadius,
            textAlign: 'center'
          }}
        >
          <img
            width='50'
            height='50'
            alt='logo'
            src={`${ASSET_URL}/leagues/square/${leagueName}.png`}
            loading='lazy'
          />
        </div>

        <div
          style={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: 16
            }}
          >
            <span style={{ color: theme.palette.text.secondary }}>
              {tr('Members')}:
            </span>{' '}
            {group.memberCount * (i % 2 === 0 ? 8 : i % 3 === 0 ? 9 : 11)}
          </Typography>

          <div>
            {game.league.s11Ready ? (
              <Typography
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: 16,
                  color: theme.palette.text.secondary
                }}
              >
                <span style={{ marginRight: 4 }}>{tr('Game')}:</span>

                <CheckRoundedIcon
                  style={{
                    top: 4,
                    position: 'relative',
                    width: 20,
                    height: 20,
                    borderRadius: theme.shape.borderRadius,
                    background: theme.palette.success.main,
                    color: 'white'
                  }}
                />
              </Typography>
            ) : null}
          </div>
        </div>

        <div>
          <PrimaryButton
            onClick={() => {
              // if (index === i || isTablet) {
              setFakeLogin(true);
              history.replace('/');
              // }
            }}
            style={{
              background: isActive ? 'none' : '',
              backgroundColor: isActive
                ? theme.palette.success.main
                : 'rgba(255, 255, 255, 0.5)',

              width: '100%',
              marginTop: 16
            }}
          >
            Open {leagueName}
          </PrimaryButton>
        </div>
      </Paper>
    );
  }

  return (
    <div style={{ padding: isTablet ? '' : 8 }}>
      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: isTablet ? 32 : 24,
          marginTop: isTablet ? 160 : 80,
          lineHeight: isTablet ? '' : '24px'
        }}
      >
        {tr('Play Super11 with your favorite competitions')}:
      </Typography>

      {!s11GroupMany ? (
        <CenteredLoader />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: isTablet ? 'row' : 'column',
            flexWrap: isTablet ? 'wrap' : ''
          }}
        >
          {s11GroupMany.filter((group) => group.games.length).map(getLeague)}
        </div>
      )}
    </div>
  );
}

const LoggedInHome = lazy(() => import('./LoggedInHome'));
const Error = lazy(() => import('Components/Error'));

export default function Home() {
  const { currentUser, loading, error } = useCurrentUser({
    fetchPolicy: 'network-only'
  });

  const isTablet = useIsTablet();

  return (
    <>
      {!currentUser && !loading && !error ? (
        <Fade in timeout={isTablet ? 500 : 0}>
          <div>
            <NotLoggedInHome2 />
          </div>
        </Fade>
      ) : loading ? (
        <CenteredLoader />
      ) : error ? (
        <Suspense fallback={null}>
          <Error error={error} />
        </Suspense>
      ) : currentUser ? (
        <Suspense fallback={null}>
          <LoggedInHome />
        </Suspense>
      ) : null}
    </>
  );
}
