const STATIC_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STATIC_URL_DEVELOPMENT
    : process.env.REACT_APP_STATIC_URL_PRODUCTION;

const ASSET_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_ASSET_URL_DEVELOPMENT
    : process.env.REACT_APP_ASSET_URL_PRODUCTION;

const MAILCHIMP_U =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_MAILCHIMP_U_DEVELOPMENT
    : process.env.REACT_APP_MAILCHIMP_U_PRODUCTION;

const MAILCHIMP_ID =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_MAILCHIMP_ID_DEVELOPMENT
    : process.env.REACT_APP_MAILCHIMP_ID_PRODUCTION;

export { STATIC_URL, ASSET_URL, MAILCHIMP_U, MAILCHIMP_ID };
