import React from 'react';
import useTranslations from 'hooks/useTranslations';
import { useTheme, Paper, Typography } from '@material-ui/core';
import useIsTablet from 'hooks/useIsTablet';
import PrimaryButton from './Elements/PrimaryButton';
import { Link } from 'react-router-dom';

export default function ContactBanner({ marginTop = 80 } = {}) {
  const T = useTranslations();
  const theme = useTheme();
  const isTablet = useIsTablet();

  return (
    <>
      {/* <div style={{ marginTop: 40 }} /> */}
      <Paper
        square
        style={{
          width: '100%',
          background: theme.palette.background.paper,
          paddingTop: 24,
          paddingBottom: 24,
          position: 'relative',
          marginTop: marginTop
        }}
      >
        <img
          src="/android-chrome-144x144.png"
          loading="lazy"
          width="70"
          height="70"
          alt=""
          style={{ position: 'absolute', top: -35, left: 'calc(50% - 35px)' }}
        />

        <div
          style={{
            textAlign: 'center',
            maxWidth: 600,
            padding: '16px 8px',
            margin: '0 auto'
          }}
        >
          <Typography
            variant="h5"
            style={{ marginBottom: 24, paddingLeft: 16, paddingRight: 16 }}
          >
            {T('Feel free to contact us')}
          </Typography>
          <PrimaryButton
            component={Link}
            to="/contact"
            style={{
              background: theme.palette.primary.dark,
              fontSize: 18,
              width: isTablet ? 300 : 'calc(100% - 16px)'
            }}
          >
            {T('Contact us now')}
          </PrimaryButton>
        </div>
      </Paper>
    </>
  );
}
