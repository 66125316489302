import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export default function useCurrentUser() {
  const {
    loading,
    error,
    data: { underConstruction } = {},
    refetch
  } = useQuery(
    gql`
      query underConstruction {
        underConstruction
      }
    `,
    { notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' }
  );

  return { underConstruction, loading, error, refetch };
}
