import React from 'react';
import { StoreProvider } from 'easy-peasy';
import { ApolloProvider } from '@apollo/client';

import store from 'store.js';
import client from 'client.js';

/**
 * Used to wrap around the App Component. Wrap StoreProvider around App in order
 * to access i.e: theme in ErrorBoundary.
 */
export default function IndexWrapper(props) {
  return (
    <StoreProvider store={store}>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </StoreProvider>
  );
}
