import React from 'react';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { IconButton, makeStyles, useTheme } from '@material-ui/core';
import { useHistory, useLocation, Link } from 'react-router-dom';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useStoreState } from 'easy-peasy';

// import logo from 'branding/menu_logo_small_squared.png';
import useIsTablet from 'hooks/useIsTablet';

const useStyles = makeStyles(theme => ({
  IconButton: {
    padding: 0,
    margin: 0,
    position: 'relative',
    top: 4,
    marginLeft: 12,
    width: 48,
    height: 48,
    borderRadius: theme.shape.borderRadius,
    transition: 'none'
  },
  Icon: {
    width: 36,
    height: 36
  }
}));

export default function MenuTopLeftNav({ toggleMenu }) {
  const history = useHistory();
  const location = useLocation();
  const isTablet = useIsTablet();
  const classes = useStyles();
  const theme = useTheme();

  const route = useStoreState(s => s.route);

  let backNav;
  if (route && route.backNav) backNav = route.backNav;

  if (isTablet) {
    return (
      <div style={{ zIndex: 2 }}>
        <IconButton
          onClick={toggleMenu}
          edge='start'
          color='inherit'
          aria-label='menu'
          style={{
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.primary.main,
            width: 50,
            height: 50,
            position: 'relative',
            // top: 4,
            left: 8
          }}
        >
          <MenuRoundedIcon
            className={classes.Icon}
            style={{ color: 'black', width: 36, height: 36 }}
          />
        </IconButton>
        {/* <IconButton
          style={{ width: 40, height: 40, position: 'absolute', left: 66 }}
          component={Link}
          to="/"
        >
          <img src={logo} alt="logo" style={{ width: 40, height: 40 }} />
        </IconButton> */}
      </div>
    );
  }

  if (backNav) {
    if (backNav === '<') {
      return (
        <IconButton
          onClick={() =>
            history.length < 3 ? history.replace('/') : history.goBack()
          }
          edge='start'
          color='inherit'
          className={classes.IconButton}
        >
          <ArrowBackIosRoundedIcon className={classes.Icon} />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          component={Link}
          to={backNav}
          replace={true}
          edge='start'
          color='inherit'
          className={classes.IconButton}
        >
          <ArrowBackIosRoundedIcon className={classes.Icon} />
        </IconButton>
      );
    }
  } else if (location.pathname === '/') {
    return (
      <IconButton
        onClick={toggleMenu}
        edge='start'
        color='inherit'
        aria-label='menu'
        className={classes.IconButton}
        style={{
          borderRadius: theme.shape.borderRadius,
          background: theme.palette.primary.main
        }}
      >
        <MenuRoundedIcon
          style={{ color: 'black', width: 36, height: 36 }}
          className={classes.Icon}
        />
      </IconButton>
    );
  }

  return (
    <IconButton
      onClick={toggleMenu}
      edge='start'
      color='inherit'
      aria-label='menu'
      className={classes.IconButton}
      style={{
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.primary.main
      }}
    >
      <MenuRoundedIcon
        style={{ color: 'black', width: 36, height: 36 }}
        className={classes.Icon}
      />
    </IconButton>
  );
}
