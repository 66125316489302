import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from './Elements/PrimaryButton';
import useTranslations from 'hooks/useTranslations';
import { useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'absolute',
    height: '100%',
    width: '100%'
  }
}));

const LOADING_ANIMATION_DURATION = 375;

export default function UpdateDialog() {
  const [open, setOpen] = React.useState(false);
  const T = useTranslations();
  const theme = useTheme();
  const classes = useStyles();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    function onUpdate() {
      setOpen(true);
    }

    document.body.addEventListener('appUpdate', onUpdate);

    return () => {
      document.body.removeEventListener('appUpdate', onUpdate);
    };
  }, []);

  // const handleClose = e => {
  //   // setOpen(false);
  //   e.preventDefault();
  //   window.location.reload(true);
  // };

  const [loaderWidth, setLoaderWidth] = useState(0);

  function onUpdateClick() {
    setLoaderWidth(100);

    setTimeout(() => {
      window.location.reload(true);
    }, LOADING_ANIMATION_DURATION / 1.1);
  }

  return (
    <Dialog
      open={open}
      onClose={onUpdateClick}
      aria-labelledby="update-dialog-title"
      aria-describedby="update-dialog-description"
      style={{
        zIndex: 100000,
        textAlign: 'center'
      }}
      PaperProps={{
        style: {
          margin: 16
        }
      }}
    >
      <DialogTitle
        id="update-dialog-title"
        style={{ paddingLeft: 8, paddingRight: 8, minWidth: 280 }}
      >
        <span style={{ fontWeight: 'bold', fontSize: 30 }}>UPDATE</span>
        <br />

        <span style={{ fontSize: 16, position: 'relative', top: -8 }}>
          {T('We have updated the app')}
        </span>
      </DialogTitle>
      <DialogContent style={{ marginTop: -16, marginBottom: 16 }}>
        <PrimaryButton
          style={{ width: '100%', padding: 0, minHeight: 38 }}
          onClick={onUpdateClick}
          classes={{
            label: classes.label
          }}

          // autoFocus
        >
          <div
            style={{
              width: `${loaderWidth}%`,
              height: '100%',
              background: theme.palette.success.main,
              fontSize: 18,
              lineHeight: '40px',
              borderRadius: 4,
              position: 'absolute',
              transition: theme.transitions.create(['width'], {
                duration: LOADING_ANIMATION_DURATION
              }),
              left: 0
            }}
          ></div>

          <div
            style={{
              width: '100%',
              height: '100%',
              fontSize: 18,
              lineHeight: '40px',
              borderRadius: 4,
              position: 'absolute',
              pointerEvents: 'none'
            }}
          >
            Update app
          </div>
        </PrimaryButton>
      </DialogContent>
    </Dialog>
  );
}
