import React from 'react';
import { useTheme, Paper, Typography } from '@material-ui/core';
import useIsTablet from 'hooks/useIsTablet';
import EmailSubscribe from './EmailSubscribe';
import CopyrightIcon from '@material-ui/icons/Copyright';
import SOCIAL_HREFS from 'SOCIAL_URLS';
import { Link, useHistory } from 'react-router-dom';
import useTranslations from 'hooks/useTranslations';
import GreenButton from './Elements/GreenButton';
import { useStoreActions, useStoreState } from 'easy-peasy';
import LanguageSelect from './LanguageSelect';
import useCurrentUser from 'hooks/useCurrentUser';

export default function Footer() {
  const theme = useTheme();
  const isTablet = useIsTablet();
  const tr = useTranslations();
  const { currentUser } = useCurrentUser();
  const history = useHistory();

  const setFakeLogin = useStoreActions(a => a.setFakeLogin);

  const sections = [
    {
      title: () => (
        <Link to="/">
          <img
            width="122"
            height="24"
            style={{ width: 122, height: 24 }}
            src={'/super11.svg'}
            alt="logo"
          />
        </Link>
      ),
      links: [
        ['App', '/app'],
        ['Helpcenter', '/helpcenter'],
        ['Affiliate', '/affiliate'],
        ['Contact', '/contact']
      ]
    },

    {
      title: 'About',
      links: [
        ['Info', '/about'],
        ['Blog', '/blog'],
        // ['FAQ', '/faq'],
        [tr('Terms'), '/terms']
      ]
    },

    {
      title: 'Developers',
      links: [
        ['WhatsNext Digital', '/wnxt'],
        ['Jobs', '/jobs']
      ]
    },

    {
      title: 'Social',
      hrefs: [
        ['Discord', SOCIAL_HREFS.discord],
        ['Instagram', SOCIAL_HREFS.instagram],
        ['Facebook', SOCIAL_HREFS.facebook],
        ['Twitter', SOCIAL_HREFS.twitter]
      ]
    }
  ];

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setFakeLogin(true);
  //     history.replace('/');
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, []);

  return (
    <>
      {!isTablet && !currentUser ? (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            paddingBottom: 'env(safe-area-inset-bottom)',
            left: 0,
            background: theme.palette.background.gradient1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            padding: 10,
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            zIndex: 2
          }}
        >
          <GreenButton
            style={{ width: '100%' }}
            onClick={() => {
              setFakeLogin(true);
              history.replace('/');
            }}
          >
            {tr('Open Super11 App')}
          </GreenButton>
        </div>
      ) : isTablet && !currentUser ? (
        <div
          style={{
            position: 'fixed',
            bottom: 'calc(env(safe-area-inset-bottom) + 16px)',
            zIndex: 2,
            right: 16
          }}
        >
          <GreenButton
            style={{ width: '100%', fontSize: 18, padding: '8px 18px' }}
            onClick={() => {
              setFakeLogin(true);
              history.replace('/');
            }}
          >
            {tr('Open Super11 App')}
          </GreenButton>
        </div>
      ) : null}

      <Paper
        style={{
          background: 'none',
          position: 'relative',
          paddingLeft: isTablet ? 8 : 0,
          paddingRight: isTablet ? 8 : 0,
          marginTop: 40,
          borderTop: `1px solid ${theme.palette.divider}`,
          paddingTop: 16,
          paddingBottom: isTablet
            ? 80
            : 'calc(16px + (env(safe-area-inset-bottom) / 2) + 56px + env(safe-area-inset-bottom))'
        }}
        square
        elevation={0}
      >
        <div
          style={{
            background: `linear-gradient(180deg, #000A25 0%, #350035 100%)`,
            backgroundColor: theme.palette.background.paperDark,
            position: 'absolute',
            width: isTablet ? 'calc(100% + 8px)' : '100%',
            height: isTablet ? 400 : 880,
            bottom: 0,
            zIndex: -2,
            left: isTablet ? -8 : 0
          }}
        />

        <div
          style={{
            maxWidth: 1280,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: '0 auto'
          }}
        >
          {sections.map((section, i) => (
            <div
              key={i}
              style={{
                width: isTablet ? '20%' : i % 2 === 0 ? '45%' : '45%',
                padding: 0
                // paddingTop: isTablet && section.title === 'Developers' ? 60 : 0
              }}
            >
              {typeof section.title === 'function' ? (
                section.title()
              ) : (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {section.title}
                </Typography>
              )}

              {section.links
                ? section.links.map(link => (
                    <Typography
                      key={link[0]}
                      style={{ marginBottom: 8, marginTop: 8 }}
                    >
                      <Link
                        style={{
                          color: theme.palette.text.primary,
                          textDecoration: 'none'
                        }}
                        to={link[1]}
                      >
                        {link[0]}
                      </Link>
                    </Typography>
                  ))
                : section.hrefs
                ? section.hrefs.map(href => (
                    <Typography
                      key={href[0]}
                      style={{ marginBottom: 8, marginTop: 8 }}
                    >
                      <a
                        style={{
                          color: theme.palette.text.primary,
                          textDecoration: 'none'
                        }}
                        href={href[1]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {href[0]}
                      </a>
                    </Typography>
                  ))
                : null}
            </div>
          ))}

          {!isTablet ? (
            <div style={{ width: '90%', left: '5%', marginTop: 16 }}>
              <EmailSubscribe />
            </div>
          ) : null}

          {isTablet ? (
            <div style={{ width: '20%' }}>
              <EmailSubscribe />

              <Lang />

              <Copyright />
            </div>
          ) : (
            <div style={{ marginTop: 16, width: '90%' }}>
              <Lang />

              <Copyright />
            </div>
          )}
        </div>
      </Paper>
    </>
  );
}

function Lang() {
  const tr = useTranslations();
  const l = useStoreState(s => s.l);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography>
        {tr('Language')}:{' '}
        <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          ({l})
        </span>
      </Typography>
      <LanguageSelect withIcon={true} withIconButton={true} />
    </div>
  );
}

function Copyright() {
  return (
    <Typography>
      <CopyrightIcon
        style={{
          position: 'relative',
          height: 20,
          width: 20,
          top: 4,
          marginRight: 4
        }}
      />
      {new Date().getFullYear()} <b>Super11</b>
    </Typography>
  );
}
