export function displayUpdateNotification(hadServiceWorker) {
  if (!hadServiceWorker) return;

  // const div = document.createElement('div');
  // div.classList.add('updateNotification');
  // div.innerHTML =
  //   '<div>Update is available.<br>Press the screen to install.</div>';
  // div.addEventListener('click', e => {
  //   e.preventDefault();
  //   div.innerHTML = `<div class="updateLoader">
  //       Installing...
  //       <div class="updateLoaderInner"></div>
  //   </div>`;
  //   setTimeout(() => {
  //     //window.location.reload(true);
  //   }, 525);
  //   // e.preventDefault();
  //   // div.innerHTML = 'Installing update...';
  //   // setTimeout(() => {
  //   //   div.innerHTML = 'Update installed, we will restart soon!';
  //   //   setTimeout(() => {
  //   //     //window.location.reload();
  //   //     //window.open('', '_self').close();
  //   //   }, 1500);
  //   // }, 3500);
  // });

  // div.style.opacity = 0;
  // document.querySelector('body').appendChild(div);

  // setTimeout(() => {
  //   div.style.opacity = 1;
  //   const inner = document.querySelector('.updateLoaderInner');
  //   inner.style.width = '100%';
  // }, 25);

  // const updateNotification = document.getElementById('updateNotification');
  // updateNotification.style.display = 'block';

  document.body.dispatchEvent(new Event('appUpdate'));
  for (let i = 1; i < 1; i++) {
    setTimeout(() => {
      document.body.dispatchEvent(new Event('appUpdate'));
    }, i * 50);
  }
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      // setTimeout(() => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
      // }, 2500);
    });
  }
}

function registerValidSW(swUrl, config) {
  if (config.immediate) {
    navigator.serviceWorker.getRegistration(swUrl).then(registration => {
      registration && registration.update();
    });
  } else {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        // console.log('registration', registration);
        // document.getElementById('feedback').innerHTML += 'register<br>';
        if (config && config.onRegister) {
          config.onRegister();
        }
        registration.onupdatefound = () => {
          //document.getElementById('feedback').innerHTML += 'onupdatefound<br>';
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            //document.getElementById('feedback').innerHTML +=
            //'installingWorker.state: ' + installingWorker.state + '<br>';
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                console.log(
                  'New content is available and will be used when all ' +
                    'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                );

                // Execute callback
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
                console.log('New content is available; please refresh.');
                displayUpdateNotification(true);
                //displayUpdateNotification();
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('Content is cached for offline use.');

                // Execute callback
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  }
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

export function init() {
  /**
   * Service worker update logic
   */
  let hasServiceWorker = true;
  if ('serviceWorker' in navigator) {
    if (!navigator.serviceWorker.controller) hasServiceWorker = false;
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      setTimeout(() => {
        // if (document.getElementById('errorUpdateNotification'))
        //   document.getElementById('errorUpdateNotification').innerHTML =
        //     'App update!';
        displayUpdateNotification(hasServiceWorker);
      }, 1);

      //document.getElementById('feedback').innerHTML += 'controllerchange<br>';
      try {
        navigator.serviceWorker.postMessage({ type: 'SKIP_WAITING' });
      } catch (e) {}

      //document.getElementById('feedback').innerHTML +=
      //'reload after postMessage<br>';
      // setTimeout(() => {
      //   window.location.reload(true);
      // }, 2500);
    });
  }

  //alert('starting register');
  register({
    onUpdate: reg => {
      const waitingServiceWorker = reg.waiting;

      //document.getElementById('feedback').innerHTML += 'onUpdate<br>';

      // console.log('reg.waiting:', reg.waiting);

      if (waitingServiceWorker) {
        //document.getElementById('feedback').innerHTML +=
        //'waitingServiceWorker<br>';
        waitingServiceWorker.addEventListener('statechange', event => {
          //document.getElementById('feedback').innerHTML +=
          //'waitingServiceWorker statechange: ' + event.target.state + '<br>';

          if (event.target.state === 'activated') {
            setTimeout(() => {
              // if (document.getElementById('errorUpdateNotification'))
              //   document.getElementById('errorUpdateNotification').innerHTML =
              //     'App update!';
              displayUpdateNotification(hasServiceWorker);
            }, 100);
          }

          console.log('event.target.state:', event.target.state);
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
    onRegister: () => {
      navigator.serviceWorker.getRegistration().then(reg => {
        if (!reg) return null;
        //document.getElementById('feedback').innerHTML +=
        //'reg.update() (onRegister)<br>';
        reg.update();
      });
    }
    //immediate: true
  });

  function checkUpdate() {
    // console.log('shoudl update :(');

    //if (!document.hidden) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then(reg => {
        // console.log('reg', reg);

        if (!reg) return null;
        reg.update();
        //document.getElementById('feedback').innerHTML += 'reg.update()<br>';
      });
    }
    //}
  }

  setInterval(() => {
    checkUpdate();
  }, 2500);
}
