import { gql } from '@apollo/client';

const Subscription = {
  usersRegistered: gql`
    subscription usersRegistered($keepSilent: Boolean) {
      usersRegistered(keepSilent: $keepSilent)
    }
  `
};

export default Subscription;
