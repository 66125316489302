import React from 'react';
import { useStoreActions } from 'easy-peasy';

import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function LanguageSelect({ withIcon, withIconButton }) {
  const setL = useStoreActions(a => a.setL);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const theme = useTheme();

  const handleClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt, l) => {
    evt.preventDefault();
    evt.stopPropagation();
    setAnchorEl(null);
    if (l) setL(l);
  };

  return (
    <>
      {withIconButton ? (
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      ) : (
        <MoreVertIcon onClick={handleClick} />
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={evt => handleClose(evt)}
      >
        <MenuItem onClick={evt => handleClose(evt, 'nl')}>Nederlands</MenuItem>
        <MenuItem onClick={evt => handleClose(evt, 'en')}>English</MenuItem>
      </Menu>
    </>
  );
}

// @TODO make real PrimaryButton language type of switch thingy with menu like above? :)
