import React, { useEffect, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import useIsTablet from 'hooks/useIsTablet';
// import { CircularProgress, Paper } from '@material-ui/core';
import useCurrentUser from 'hooks/useCurrentUser';
import useUnderConstruction from 'hooks/useUnderConstruction';

import Home from 'Components/Routes/Home/index.js';
import UnderConstruction from 'Components/UnderConstruction';
import { useTheme } from '@material-ui/core';

const productName = process.env.REACT_APP_PRODUCT_NAME;

const containerStyles = document.getElementById('containerStyles');

const routes = [
  {
    exact: true,
    path: '/',
    component: Home,
    title: {
      en: 'Home',
      nl: 'Home'
    },
    description: {
      en: `With the Super11 App you play your own live football competition against your friends with real players from the Eredivisie, Premier League, La Liga and more!`,
      nl: `Met de Super11 App speel je met vrienden jouw eigen live voetbal competitie met echte spelers uit de Eredivisie, Premier League, La Liga en meer!`
    },
    keywords: {
      en: [
        'Home',
        'Homepage',
        'Super11',
        'Voetball',
        'App',
        'Eredivisie',
        'Live',
        'Premier League'
      ],
      nl: [
        'Home',
        'Homepage',
        'Super11',
        'Football',
        'Soccer',
        'Eredivisie',
        'Live',
        'Premier League'
      ]
    },
    menuTitle: {
      en: 'Home',
      nl: 'Home'
    },
    isContentPage: true
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('Components/Routes/Authentication/Login.js')),
    title: {
      en: 'Login',
      nl: 'Login'
    },
    description: {
      en: 'Login to the Super11 App for Live Soccer Data and Games',
      nl: 'Login op de Super11 App voor Live Voetbal Data en Games'
    },
    keywords: {
      en: ['Login', 'Super11'],
      nl: ['Login', 'Super11']
    },
    menuTitle: {
      en: 'Login',
      nl: 'Login'
    },
    redirectToWhenLoggedIn: '/',
    backNav: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() =>
      import('Components/Routes/Authentication/Register.js')
    ),
    title: {
      en: 'Register',
      nl: 'Registreren'
    },
    description: {
      en: 'Register for the Super11 App and play!',
      nl: 'Registreer je voor de Super11 App en speel mee!'
    },
    keywords: {
      en: ['Register', 'Super11'],
      nl: ['Registreren', 'Super11']
    },
    menuTitle: {
      en: 'Register',
      nl: 'Registreren'
    },
    redirectToWhenLoggedIn: '/',
    backNav: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/lost-password',
    component: lazy(() =>
      import('Components/Routes/Authentication/LostPassword.js')
    ),
    title: {
      en: 'Lost password',
      nl: 'Wachtwoord kwijt'
    },
    description: {
      en: 'Lost password',
      nl: 'Wachtwoord kwijt'
    },
    keywords: {
      en: ['Password', 'lost', 'Super11'],
      nl: ['Wachtwoord', 'kwijt', 'Super11']
    },
    menuTitle: {
      en: 'Lost password',
      nl: 'Wachtwoord kwijt'
    },
    requiresLogin: false,
    backNav: '/login',
    redirectToWhenLoggedIn: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/logout',
    component: lazy(() => import('Components/Routes/Authentication/Logout.js')),
    title: {
      en: 'Logout',
      nl: 'Logout'
    },
    description: {
      en: 'Logout',
      nl: 'Logout'
    },
    keywords: {
      en: ['Logout', 'Super11'],
      nl: ['Logout', 'Super11']
    },
    menuTitle: {
      en: 'Logout',
      nl: 'Logout'
    },
    // requiresLogin: true,
    backNav: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/user-profile',
    component: lazy(() => import('Components/Routes/UserProfile/index.js')),
    title: {
      en: 'Profile',
      nl: 'Profiel'
    },
    description: {
      en: 'Profile',
      nl: 'Profiel'
    },
    keywords: {
      en: ['User', 'profile', 'Super11'],
      nl: ['Gebruiker', 'profiel', 'Super11']
    },
    menuTitle: {
      en: 'Profile',
      nl: 'Profiel'
    },
    requiresLogin: true,
    backNav: '<',
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/user-profile/edit-account-details',
    component: lazy(() =>
      import('Components/Routes/UserProfile/AccountDetails.js')
    ),
    title: {
      en: 'Account details',
      nl: 'Account gegevens'
    },
    description: {
      en: 'Account details',
      nl: 'Account gegevens'
    },
    keywords: {
      en: ['Account', 'details', 'Super11'],
      nl: ['Account', 'gegevens', 'Super11']
    },
    menuTitle: {
      en: 'Account details',
      nl: 'Account gegevens'
    },
    requiresLogin: true,
    backNav: '<',
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/user-profile/change-password',
    component: lazy(() =>
      import('Components/Routes/UserProfile/ChangePassword.js')
    ),
    title: {
      en: 'Change password',
      nl: 'Verander wachtwoord'
    },
    description: {
      en: 'Change password',
      nl: 'Verander wachtwoord'
    },
    keywords: {
      en: ['Password', 'Super11'],
      nl: ['Wachtwoord', 'Super11']
    },
    menuTitle: {
      en: 'Change password',
      nl: 'Verander wachtwoord'
    },
    requiresLogin: true,
    backNav: '<',
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/user-profile/edit-avatar',
    component: lazy(() =>
      import('Components/Routes/UserProfile/EditAvatar.js')
    ),
    title: {
      en: 'Avatar',
      nl: 'Avatar'
    },
    description: {
      en: 'Avatar',
      nl: 'Avatar'
    },
    keywords: {
      en: ['Avatar', 'Super11'],
      nl: ['Avatar', 'Super11']
    },
    menuTitle: {
      en: 'Avatar',
      nl: 'Avatar'
    },
    requiresLogin: true,
    backNav: '<',
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/products',
    component: lazy(() => import('Components/Routes/Products.js')),
    title: {
      en: 'Credits',
      nl: 'Credits'
    },
    description: {
      en: 'Credits',
      nl: 'Credits'
    },
    keywords: {
      en: ['Credits', 'Super11'],
      nl: ['Credits', 'Super11']
    },
    menuTitle: {
      en: 'Credits',
      nl: 'Credits'
    },
    backNav: '<',
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/contact',
    component: lazy(() => import('Components/Routes/Contact')),
    title: {
      en: 'Contact',
      nl: 'Contact'
    },
    description: {
      en: 'Contact the Super11 team',
      nl: 'Neem contact op met het Super11 Team'
    },
    keywords: {
      en: ['Contact', 'Super11'],
      nl: ['Contact', 'Super11']
    },
    menuTitle: {
      en: 'Contact',
      nl: 'Contact'
    },
    backNav: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/terms',
    component: lazy(() => import('Components/Routes/Terms')),
    title: {
      en: 'Terms of service',
      nl: 'Voorwaarden'
    },
    description: {
      en: 'Terms of service of Super11',
      nl: 'Voorwaarden van Super11'
    },
    keywords: {
      en: ['Terms of service', 'Super11'],
      nl: ['Voorwaarden', 'Super11']
    },
    menuTitle: {
      en: 'Terms of service',
      nl: 'Voorwaarden'
    },
    backNav: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/about',
    component: lazy(() => import('Components/Routes/About')),
    title: {
      en: 'About us',
      nl: 'Over ons'
    },
    description: {
      en: 'About us and the Super11 App. The football app for live data, games and more.',
      nl: 'Over ons en de Super11 App. De voetbal app voor live data, games en meer.'
    },
    keywords: {
      en: ['About us', 'Super11'],
      nl: ['Over ons', 'Super11']
    },
    menuTitle: {
      en: 'About us',
      nl: 'Over ons'
    },
    backNav: '/',
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/group/:groupId/game/:gameId',
    component: lazy(() => import('Components/Routes/GroupGame/GroupGame.js')),
    title: {
      en: 'Game',
      nl: 'Game'
    },
    description: {
      en: 'Game',
      nl: 'Game'
    },
    keywords: {
      en: ['Game', 'Super11'],
      nl: ['Game', 'Super11']
    },
    menuTitle: {
      en: '',
      nl: ''
    },
    backNav: '<',
    requiresLogin: true,
    lazy: true
  },
  {
    exact: true,
    path: '/i/:groupLink',
    component: lazy(() => import('Components/Routes/GroupLink')),
    title: {
      en: 'Group link',
      nl: 'Group link'
    },
    description: {
      en: 'Group link',
      nl: 'Group link'
    },
    keywords: {
      en: ['Group link', 'Super11'],
      nl: ['Group link', 'Super11']
    },
    menuTitle: {
      en: '',
      nl: ''
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/group/:groupId',
    component: lazy(() => import('Components/Routes/Group')),
    title: {
      en: 'Group',
      nl: 'Groep'
    },
    description: {
      en: 'Group',
      nl: 'Groep'
    },
    keywords: {
      en: ['Group', 'Super11'],
      nl: ['Groep', 'Super11']
    },
    menuTitle: {
      en: 'Group',
      nl: 'Groep'
    },
    backNav: '<',
    requiresLogin: true,
    noBottomNav: true,
    lazy: true
  },
  {
    exact: true,
    path: '/helpcenter',
    component: lazy(() => import('Components/Routes/Helpcenter')),
    title: {
      en: 'Helpcenter',
      nl: 'Helpcenter'
    },
    description: {
      en: 'Helpcenter. Here you can find everything about Super11!',
      nl: 'Helpcenter. Hier kun je alles over Super11 vinden!'
    },
    keywords: {
      en: ['Helpcenter', 'Super11'],
      nl: ['Helpcenter', 'Super11']
    },
    menuTitle: {
      en: 'Helpcenter',
      nl: 'Helpcenter'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/blog',
    component: lazy(() => import('Components/Routes/Blog')),
    title: {
      en: 'Blog',
      nl: 'Blog'
    },
    description: {
      en: 'Blog',
      nl: 'Blog'
    },
    keywords: {
      en: ['Blog', 'Super11'],
      nl: ['Blog', 'Super11']
    },
    menuTitle: {
      en: 'Blog',
      nl: 'Blog'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  // {
  //   exact: true,
  //   path: '/faq',
  //   component: lazy(() => import('Components/Routes/Faq')),
  //   title: {
  //     en: 'FAQ',
  //     nl: 'FAQ'
  //   },
  //   description: {
  //     en: 'FAQ',
  //     nl: 'FAQ'
  //   },
  //   keywords: {
  //     en: ['FAQ', 'Super11'],
  //     nl: ['FAQ', 'Super11']
  //   },
  //   menuTitle: {
  //     en: 'FAQ',
  //     nl: 'FAQ'
  //   },
  //   backNav: '<',
  //   requiresLogin: false,
  //   noBottomNav: true,
  //   lazy: true,
  //   isContentPage: true
  // },
  {
    exact: true,
    path: '/affiliate',
    component: lazy(() => import('Components/Routes/Affiliate')),
    title: {
      en: 'Affiliate',
      nl: 'Affiliate'
    },
    description: {
      en: 'Affiliate',
      nl: 'Affiliate'
    },
    keywords: {
      en: ['Affiliate', 'Super11'],
      nl: ['Affiliate', 'Super11']
    },
    menuTitle: {
      en: 'Affiliate',
      nl: 'Affiliate'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/app',
    component: lazy(() => import('Components/Routes/AppPage')),
    title: {
      en: 'App',
      nl: 'App'
    },
    description: {
      en: 'The Super11 App',
      nl: 'De Super11 App'
    },
    keywords: {
      en: ['App', 'Super11'],
      nl: ['App', 'Super11']
    },
    menuTitle: {
      en: 'App',
      nl: 'App'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/wnxt',
    component: lazy(() => import('Components/Routes/Wnxt')),
    title: {
      en: 'WhatsNext Digital',
      nl: 'WhatsNext Digital'
    },
    description: {
      en: 'WhatsNext Digital',
      nl: 'WhatsNext Digital'
    },
    keywords: {
      en: ['WhatsNext Digital', 'Super11'],
      nl: ['WhatsNext Digital', 'Super11']
    },
    menuTitle: {
      en: 'WhatsNext Digital',
      nl: 'WhatsNext Digital'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },
  {
    exact: true,
    path: '/jobs',
    component: lazy(() => import('Components/Routes/Jobs')),
    title: {
      en: 'Jobs',
      nl: 'Jobs'
    },
    description: {
      en: 'Jobs',
      nl: 'Jobs'
    },
    keywords: {
      en: ['Jobs', 'Super11'],
      nl: ['Jobs', 'Super11']
    },
    menuTitle: {
      en: 'Jobs',
      nl: 'Jobs'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },

  {
    exact: true,
    path: '/purchase-completed',
    component: lazy(() => import('Components/Routes/PurchaseCompleted')),
    title: {
      en: 'Purchase completed',
      nl: 'Aankoop geslaagd'
    },
    description: {
      en: 'Purchase completed',
      nl: 'Aankoop geslaagd'
    },
    keywords: {
      en: ['Purchase completed'],
      nl: ['Aankoop geslaagd']
    },
    menuTitle: {
      en: 'Purchase completed',
      nl: 'Aankoop geslaagd'
    },
    backNav: '/',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  },

  {
    exact: true,
    path: '/social',
    component: lazy(() => import('Components/Routes/Social')),
    title: {
      en: 'Social',
      nl: 'Social'
    },
    description: {
      en: 'Social',
      nl: 'Social'
    },
    keywords: {
      en: ['Social', 'Super11'],
      nl: ['Social', 'Super11']
    },
    menuTitle: {
      en: 'Social',
      nl: 'Social'
    },
    backNav: '<',
    requiresLogin: false,
    noBottomNav: true,
    lazy: true,
    isContentPage: true
  }
].map(route => {
  /**
   * This code below can be used to "hook" into each of the components which we
   * route to
   */
  const ComponentToRender = route.component;

  function Comp(...xs) {
    const {
      currentUser,
      loading: currentUserLoading,
      refetch
    } = useCurrentUser();
    const history = useHistory();
    const isTablet = useIsTablet();

    const theme = useTheme();

    const l = useStoreState(s => s.l);

    useEffect(() => {
      document.querySelector('.App').scrollTop = 0;
    }, []);

    const setRoute = useStoreActions(actions => actions.setRoute);
    useEffect(() => {
      setRoute(route);

      const interval = setInterval(refetch, 30000);

      return () => clearInterval(interval);
    }, []); // eslint-disable-line

    useEffect(() => {
      const navHeight = route.isContentPage ? 0 : 64;
      const paddingTop = route.isContentPage ? (isTablet ? 128 : 64) : 0;

      if (route.path === '/' && currentUser) {
        route.isContentPage = false;
      }

      if (route.isContentPage) {
        containerStyles.innerHTML = `div.App {
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          padding-top: ${paddingTop}px !important;
          margin-top: ${navHeight}px !important;
          padding-bottom: ${navHeight === 128 ? 64 : 0}px !important;
          height: calc(100% - ${navHeight}px - calc(env(safe-area-inset-bottom) / 2)) !important;
          background: ${theme.palette.background.paperDark};
          /* from routes.js */
        }
        
        body {
          background: ${theme.palette.background.paperDark} !important;
        }
        `;
      } else if (route.noBottomNav) {
        containerStyles.innerHTML = `
          .MuiContainer-root {
            // position: fixed !important;
            top: ${navHeight}px !important;
            // overflow-y: auto ome!important;
            height: calc(100% - ${navHeight}px - calc(env(safe-area-inset-bottom) / 2)) !important;
            // width: 100% !important;
            // max-width: none !important;
            // overflow-x: hidden;
            /* from routes.js */
          }
          `;
      } else {
        if (isTablet && route.path !== '/') {
          containerStyles.innerHTML = `.App {
            padding-bottom: 16px !important;
            /* from routes.js */
          }`;
        } else {
          containerStyles.innerHTML = `.App {
            padding-bottom: 0px !important;
            /* from routes.js */
          }`;
        }
      }
    }, [isTablet, currentUser, theme]);

    const {
      refetch: refetchUnderConstruction,
      underConstruction,
      loading: loadingUnderConstruction,
      error: errorUnderConstruction
    } = useUnderConstruction();

    if (route.requiresLogin && currentUser === null && !currentUserLoading) {
      setTimeout(() => history.replace('/'), 0);
      return null;
    }
    if (
      route.redirectToWhenLoggedIn &&
      currentUser &&
      !currentUser.FAKE_LOGIN
    ) {
      setTimeout(() => history.replace(route.redirectToWhenLoggedIn), 0);
      return null;
    }

    if (
      (underConstruction || errorUnderConstruction) &&
      (route.requiresLogin || (route.path === '/' && currentUser)) &&
      !currentUser?.admin
    ) {
      return (
        <Suspense fallback={null}>
          <Helmet>
            <title>Super11</title>
            <meta name='keywords' content={routes[0].description[l]} />
            <meta
              data-react-helmet='true'
              name='description'
              content={routes[0].description[l]}
            />
          </Helmet>
          <UnderConstruction
            refetchUnderConstruction={refetchUnderConstruction}
            underConstruction={underConstruction}
            loadingUnderConstruction={loadingUnderConstruction}
            errorUnderConstruction={errorUnderConstruction}
          />
        </Suspense>
      );
    }

    return (
      <>
        <Helmet>
          {route.title && route.title[l] && (
            <title>
              {route.title[l] === 'Home'
                ? productName
                : (productName ? productName + ' | ' : '') + route.title[l]}
              {}
            </title>
          )}
          {route.description && route.description[l] && (
            <meta
              name='description'
              // data-react-helmet="true"
              content={
                (productName ? productName + ' | ' : '') + route.description[l]
              }
            ></meta>
          )}
          {route.keywords && route.keywords[l] && (
            <meta
              // data-react-helmet="true"
              name='keywords'
              content={route.keywords[l].join(', ')}
            />
          )}
        </Helmet>
        {/* eslint-disable-next-line */}
        {route.lazy ? (
          <Suspense fallback={null}>
            <ComponentToRender />
          </Suspense>
        ) : (
          <ComponentToRender />
        )}
      </>
    );
  }
  route.component = Comp;
  return route;
});

// const renderLoader = () => (
//   <div>
//     <Paper style={{ background: 'none' }}>
//       <CircularProgress />
//     </Paper>
//   </div>
// );

export default routes;
