import { SvgIcon } from '@material-ui/core';

import React from 'react';

export default function BallIcon(props) {
  return (
    <SvgIcon style={{ position: 'relative', top: 4, left: 4 }} {...props}>
      <path d="M6.24179 8.03672L7.32461 11.25H10.7172L11.7585 8.03742L9.01562 6.05039L6.24179 8.03672ZM8.98398 0C4.04453 0 0.015625 4.02891 0.015625 9C0.015625 13.9711 4.04453 18 9.01562 18C13.9867 18 17.984 13.9711 17.984 9.03164C17.984 4.09219 13.9867 0 8.98398 0ZM15.3156 12.6949L11.6629 12.6394L10.5846 16.1382C10.0773 16.2492 9.55351 16.3125 9.01562 16.3125C8.47773 16.3125 7.95425 16.2502 7.44906 16.1392L6.37187 12.6422L2.71668 12.6949C2.19179 11.8037 1.84515 10.7961 1.73933 9.71648L4.73711 7.60781L3.55586 4.15195C4.25371 3.36656 5.11398 2.72988 6.08851 2.30203L9.01562 4.5L11.9438 2.30379C12.9183 2.73164 13.7786 3.36797 14.4764 4.15371L13.2941 7.60781L16.2905 9.71613C16.1559 10.7965 15.8394 11.8055 15.3156 12.6949Z" />
    </SvgIcon>
  );
}
