const translations = {
  'GraphQL error: Name too short': {
    en: 'Name too short',
    nl: 'Naam te kort'
  },
  'GraphQL error: Name too long': {
    en: 'Name too long',
    nl: 'Naam te lang'
  },
  'Something went wrong': {
    en: 'Something went wrong',
    nl: 'Er ging iets fout'
  },
  'GraphQL error: incorrect login details': {
    en: 'Login failed. Is your e-mail and/or password correctly written?',
    nl: 'Inloggen mislukt. Is jouw e-mail en/of wachtwoord goed getypt?'
  },
  'GraphQL error: passwords do not match': {
    en: 'Passwords do not match',
    nl: 'Wachtwoorden zijn niet gelijk'
  },
  'GraphQL error: incorrect code or email': {
    en: 'Incorrect code',
    nl: 'Ongeldige code'
  },
  'GraphQL error: cannot sign up with facebook account': {
    en: 'Cannot sign up with this Facebook account (Facebook account without email?)',
    nl: 'Aanmelden niet mogelijk met dit Facebook account (Facebook account zonder email?)'
  },
  'GraphQL error: cannot sign up with google account': {
    en: 'Cannot sign up with this Google account (Google account without email?)',
    nl: 'Aanmelden niet mogelijk met dit Google account (Google account zonder email?)'
  },
  'GraphQL error: unauthorized': {
    en: 'Unauthorized',
    nl: 'Geen toestemming'
  },
  'GraphQL error: no password': {
    en: 'No password',
    nl: 'Geen wachtwoord'
  },
  'GraphQL error: invalid code': {
    en: 'Invalid code',
    nl: 'Ongeldige code'
  },
  'GraphQL error: already validated': {
    en: 'Account already validated',
    nl: 'Account bestaat al'
  },
  'Network error: Failed to fetch': {
    en: 'Cannot connect to server',
    nl: 'Kan geen verbinding met de server maken'
  },
  'invalid email': {
    nl: 'Ongeldige e-email',
    en: 'Invalid email'
  },
  'invalid code': {
    en: 'Invalid code',
    nl: 'Ongeldige code'
  },
  'incorrect code or email': {
    nl: 'Ongeldige code',
    en: 'Invalid code'
  },
  'incorrect login details': {
    en: 'Login failed. Is your e-mail and/or password correctly written?',
    nl: 'Inloggen mislukt. Is jouw e-mail en/of wachtwoord goed getypt?'
  },
  'passwords do not match': {
    en: 'Passwords do not match',
    nl: 'Wachtwoorden zijn niet gelijk'
  },
  'Email sent': {
    en: 'Email sent',
    nl: 'Email verstuurd'
  },
  'Code sent': {
    en: 'Code sent',
    nl: 'Code verstuurd'
  },
  'Reset password with code': {
    en: 'Reset password with code',
    nl: 'Herstel wachtwoord met code'
  },
  'Send reset code': {
    nl: 'Verstuur herstelcode'
  },
  retry: {
    en: 'Retry',
    nl: 'Opnieuw proberen'
  },

  'Password saved': {
    en: 'Password saved',
    nl: 'Wachtwoord opgeslagen'
  },
  'new password': {
    en: 'new password',
    nl: 'nieuw wachtwoord'
  },
  'repeat new password': {
    en: 'repeat new password',
    nl: 'herhaal nieuw wachtwoord'
  },
  Name: {
    en: 'Name',
    nl: 'Naam'
  },
  'Update account details': {
    en: 'Update account details',
    nl: 'Account gegevens aanpassen'
  },
  'Account details saved': {
    en: 'Account details saved',
    nl: 'Account gegevens opgeslagen'
  },

  save: {
    en: 'save',
    nl: 'opslaan'
  },

  Reload: {
    en: 'Reload',
    nl: 'Herladen'
  },

  'Our products': {
    en: 'Our products',
    nl: 'Onze producten'
  },

  Purchase: {
    en: 'Purchase',
    nl: 'Kopen'
  },
  'Purchase now': {
    en: 'Purchase now',
    nl: 'Nu kopen'
  },
  'Login to purchase': {
    en: 'Login to purchase',
    nl: 'Login om te kopen'
  },
  'Your purchased products': {
    en: 'Your purchased products',
    nl: 'Gekochte producten'
  },
  'Product name': {
    en: 'Product name',
    nl: 'Product naam'
  },
  Amount: {
    en: 'Amount',
    nl: 'Hoeveelheid'
  },

  'Account details': {
    en: 'Account details',
    nl: 'Account gegevens'
  },
  'Edit account details': {
    en: 'Edit account details',
    nl: 'Account gegevens aanpassen'
  },
  'Change password': {
    en: 'Change password',
    nl: 'Wachtwoord veranderen'
  },
  'Change avatar': {
    en: 'Change avatar',
    nl: 'Avatar aanpassen'
  },
  'App language': {
    en: 'App language',
    nl: 'App taal'
  },
  Language: {
    nl: 'Taal'
  },

  Home: {
    nl: 'Home'
  },

  Users: {
    nl: 'Gebruikers'
  },
  Products: {
    nl: 'Producten'
  },
  'Long list': {
    nl: 'Lange lijst'
  },
  'Profile & settings': {
    nl: 'Profiel en instellingen'
  },
  Logout: {
    nl: 'Uitloggen'
  },
  Register: {
    nl: 'Registreren'
  },

  'Logged in': {
    nl: 'Ingelogd'
  },
  password: {
    nl: 'wachtwoord'
  },
  'Or login with': {
    nl: 'Of log in met'
  },
  "Don't have an account yet?": {
    nl: 'Heb je nog geen account?'
  },
  'Lost password?': {
    nl: 'Wachtwoord vergeten?'
  },
  'See you soon': {
    nl: 'Tot snel'
  },

  'code sent to email': {
    nl: 'code in je mailbox'
  },

  'Already got an account?': {
    nl: 'Heb je al een account?'
  },

  'Edit current avatar': {
    nl: 'Huidige avatar aanpassen'
  },
  'Upload new photo': {
    nl: 'Upload nieuwe foto'
  },
  'Photo saved': {
    nl: 'Foto opgeslagen'
  },

  Contact: {
    nl: 'Contact'
  },
  'Terms of service': {
    nl: 'Gebruikers voorwaarden'
  },
  Terms: {
    nl: 'Voorwaarden'
  },
  'About us': {
    nl: 'Over ons'
  },
  'Press here to try again': {
    nl: 'Druk hier om opnieuw te proberen'
  },
  'Buy credits': {
    nl: 'Koop credits'
  },
  'You seem to have not purchased credits yet!': {
    nl: 'Het lijkt er op dat je nog geen credits hebt gekocht!'
  },
  'Credits added': {
    nl: 'Credits bijgeschreven'
  },
  Welcome: {
    nl: 'Welkom'
  },
  'Next step': {
    nl: 'Volgende stap'
  },
  Back: {
    nl: 'Terug'
  },
  'Background color': {
    nl: 'Achtergrond kleur'
  },

  'Choose a name': {
    nl: 'Kies een naam'
  },
  'Choose a unique name': {
    nl: 'Kies een unieke naam'
  },
  'Choose a logo': {
    nl: 'Kies een logo'
  },
  'Upload a logo': {
    nl: 'Upload een logo'
  },

  'Create a new group': {
    nl: 'Maak een nieuwe groep'
  },

  'Create the first game': {
    nl: 'Creëer het eerste spel'
  },

  'Options and settings': {
    nl: 'Opties en instellingen'
  },

  Completion: {
    nl: 'Afronden'
  },
  'Optionally choose a logo for your group': {
    nl: 'Kies eventueel een logo voor je groep'
  },

  'Search for a group': {
    nl: 'Zoek een groep'
  },
  'Create a group': {
    nl: 'Maak een groep'
  },
  'Create group': {
    nl: 'Groep aanmaken'
  },

  Competition: {
    nl: 'Competitie'
  },
  Finished: {
    nl: 'Afgelopen'
  },
  'All leagues are either finished or live.': {
    nl: 'Alle leagues zijn of afgelopen of live.'
  },

  'Choose competition description': {
    en: `Choose a competition. Later you will be able to create a lot more games for
      your group`,
    nl: `Kies nu 1 competitie. Je kunt straks nog veel meer spellen voor jouw
    group opstarten`
  },

  'Choose competition mode description': {
    en: 'Choose the game mode (difficulty level)',
    nl: 'Kies de spel modus (moeilijkheidsgraad)'
  },

  'Do you want your group to be private?': {
    nl: 'Wil je dat je groep besloten is?'
  },
  Public: {
    nl: 'Openbaar'
  },
  Private: {
    nl: 'Besloten'
  },

  'Choose a 4 digit pin code for your group': {
    nl: 'Kies een 4-cijferige pincode voor jouw groep'
  },

  'This name is already in use': {
    nl: 'Deze naam is al in gebruik'
  },
  'This name is available': {
    nl: 'Deze naam is beschikbaar'
  },
  'Invalid name': {
    nl: 'Ongeldige naam'
  },
  'Checking name': {
    nl: 'Naam aan het checken'
  },
  'Name too short': {
    nl: 'Naam te kort'
  },
  'Name too long': {
    nl: 'Naam te lang'
  },
  'Name contains invalid characters': {
    nl: 'Naam bevat ongelidige tekens'
  },
  'Invalid pin': {
    nl: 'Ongeldige pin'
  },
  Error: {
    nl: 'Fout'
  },
  member: {
    nl: 'lid'
  },
  members: {
    nl: 'leden'
  },
  game: {
    nl: 'spel'
  },
  games: {
    nl: 'spellen'
  },
  'My groups': {
    nl: 'Mijn groepen'
  },

  "You're not yet a member of a group.": {
    nl: 'Je bent nog geen lid van een groep.'
  },
  "You're not yet a member of a Super11 league": {
    nl: 'Je bent nog geen lid van een Super11 league'
  },

  'With Super11 you can only organize football games as an owner of a group. You can easily create a group. If you do not want to play in a group you can join the big Super11 leagues.':
    {
      nl: 'Op Super11 kun je alleen voetbalspellen organiseren als je eigenaar bent van een groep. Je kunt heel gemakkelijk een groep maken. Als je niet wilt meespelen binnen een groep kun je meedoen met de grote Super11 leagues.'
    },

  Owner: {
    nl: 'Eigenaar'
  },

  Easy: {
    nl: 'Makkelijk'
  },
  Medium: {
    nl: 'Normaal'
  },
  Hard: {
    nl: 'Moeilijk'
  },

  'Enter a group name': {
    nl: 'Vul een groep naam in'
  },
  'Enter a pin code': {
    nl: 'Vul een pin code in'
  },
  'These football games are currently being played by this group': {
    nl: 'Deze voetbalspellen worden nu door deze groep gespeeld'
  },
  'This group currently plays no active football games': {
    nl: 'Deze groep speelt op dit moment geen actieve voetbalspellen'
  },
  'Enter pin code to join': {
    nl: 'Voer pin code in om lid te worden'
  },
  'GraphQL error: Incorrect pin': {
    en: 'Incorrect pin code',
    nl: 'Onjuiste pin code'
  },
  'GraphQL error: Group not found': {
    en: 'Group not found',
    nl: 'Groep niet gevonden'
  },
  'Checking pin code': {
    nl: 'Pin code aan het checken'
  },
  fullDays: {
    en: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    nl: [
      'Zondag',
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag'
    ]
  },
  fullMonths: {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    nl: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'Oktober',
      'November',
      'December'
    ]
  },

  predict: {
    nl: 'voorspel'
  },
  draw: {
    nl: 'gelijk'
  },
  You: {
    nl: 'Jij'
  },
  'Add a game': {
    nl: 'Voeg een spel toe'
  },
  'Choose a competition': {
    nl: 'Kies een competitie'
  },
  'Add game': {
    nl: 'Spel toevoegen'
  },
  'Game added': {
    nl: 'Spel toegevoegd'
  },
  positionLetters: {
    en: {
      1: 'G',
      2: 'D',
      3: 'M',
      4: 'A'
    },
    nl: {
      1: 'K',
      2: 'V',
      3: 'M',
      4: 'A'
    }
  },
  positionNames: {
    en: {
      1: 'goalkeeper',
      2: 'defender',
      3: 'midfielder',
      4: 'attacker'
    },
    nl: {
      1: 'keeper',
      2: 'verdediger',
      3: 'middenvelder',
      4: 'aanvaller'
    }
  },
  'Game message': {
    nl: 'Game bericht'
  },
  Saved: {
    nl: 'Opgeslagen'
  },
  All: {
    nl: 'Alle'
  },
  Available: {
    nl: 'Beschikbare'
  },
  'Not available': {
    nl: 'Niet beschikbaar'
  },
  'Check your lineup': {
    nl: 'Bekijk jouw opstelling'
  },
  'Hide your lineup': {
    nl: 'Sluit jouw opstelling'
  },
  Choose: {
    nl: 'Kies'
  },
  'Too many': {
    nl: 'Te veel'
  },
  'This will be shown when the first round starts': {
    nl: 'Dit wordt weergegeven wanneer de 1e speelronde is gestart'
  },
  'Starts in': {
    nl: 'Begint over'
  },
  'member online': {
    nl: 'lid online'
  },
  'members online': {
    nl: 'leden online'
  },
  'active game': {
    nl: 'actief spel'
  },
  'active games': {
    nl: 'actieve spellen'
  },
  'Players list': {
    nl: 'Spelerslijst'
  },
  'The game starts in': {
    nl: 'Het spel begint over'
  },
  'Choose a': {
    nl: 'Kies een'
  },
  'Select 11 players and a captain': {
    en: `Select 11 players and a captain`,
    nl: `Selecteer 11 spelers en een aanvoerder`
  },
  'Captain selected': {
    nl: 'Aanvoerder geselecteerd'
  },
  'You still have to select a captain': {
    nl: 'Je moet nog een aanvoerder selecteren'
  },
  'Selected 11 players': {
    nl: '11 spelers geselecteerd'
  },
  'Your team is not complete': {
    nl: 'Je team is niet compleet'
  },
  'Your team is complete': {
    nl: 'Je team is compleet'
  },
  'Check all the rules for this game here': {
    nl: 'Bekijk hier alle spelregels voor dit spel'
  },
  'Choose 11 players from 11 different clubs': {
    nl: 'Kies 11 spelers van 11 verschillende clubs'
  },
  'Select 1 captain. Your captain will score double (minus) points': {
    nl: 'Selecteer 1 aanvoerder. Deze scoort dubbele punten (of minpunten)'
  },
  'We play this game without a budget': {
    nl: 'We spelen dit spel zonder budget'
  },
  'Every round you can apply 3 substitutes. Every substitute costs 6 points. Players who are sold, rented or stopped can be substituted for "free".':
    {
      nl: 'Je kan iedere ronde 3 keer wisselen. Elke wissel kost 6 minpunten. Spelers die verkocht, verhuurd of gestopt zijn mag je "gratis" wisselen.'
    },

  'Standard rules desc': {
    en: 'Besides that all the standard Super11 rules apply. These are the rules which are the same for all Super11 games. Check these rules by selecting "Super11 rules" above',
    nl: 'Verder gelden alle standaard Super11 regels. Dit zijn de regels die voor alle Super11 spellen hetzelfde zijn. Bekijk deze spelregels door hierboven “Super11 spelregels” te selecteren.'
  },

  'Team complete desc': {
    en: 'Always make sure your team is complete. Did you forget something? Then you will not get points for this position',
    nl: 'Zorg er altijd voor dat jouw team compleet is. Ben je iets vergeten? Dan scoor je op deze positie(s) geen punten.'
  },
  'Captain desc': {
    en: 'Your captain scores double (minus) points. This score is added to the weekly scores. When you forgot to select a captain, your goalkeeper will be your captain automatically',
    nl: 'Jouw aanvoerder scoort dubbele punten of minpunten. Deze score wordt meegerekend bij de weekscore. Wanneer je vergeten bent een aanvoerder te selecteren is jouw keeper automatisch de aanvoerder.'
  },
  'Rounds desc': {
    en: 'A round can have multiple "winners". The Super11 rankings are determined by: 1. total score - 2a. budget(?) 2b. substitutes - 3. captain score - 4. lottery',
    nl: 'Een speelronde kan meerdere "winnaars" hebben. De Super11 ranglijst wordt bepaald aan de hand van 1. totaal punten - 2a. budget(?) 2b. wissels - 3. captain points - 4. loting'
  },
  'Data provider desc': {
    en: 'We are working with a data provider: SportMonks. When you see that scores are not correct, let us know via the report button. We will always correct things when required. The standings will be updated accordingly. Super11 is ultimately responsible',
    nl: 'Wij werken samen met data leverancier SportMonks. Als je scores/punten ziet die niet kloppen, laat het ons weten via de report knop. Wij zullen altijd alles aanpassen waarnodig. De tussenstand zal bij iedere wijziging aangepast worden. Super11 is eindverantwoordelijk.'
  },
  'Postponed desc': {
    en: 'When a match is canceled/postponed, it will remain in the original round. For such matches your lineup will remain the same for that round',
    nl: 'Wanneer een wedstrijd wordt afgelast/uitgesteld blijft deze in de originele speelronde staan. Voor de inhaalwedstrijd blijft je opstelling van die speelronde staan.'
  },

  'Predictions desc': {
    en: 'You can predict matches. This is a bonus feature and is not required',
    nl: 'Je kunt/mag wedstrijden voorspellen. Dit is een los spel element en is niet verplicht.'
  },
  'KO desc': {
    en: '24 hours after each played round the 1vs1 match standings will be locked(!). This means that not completed matches will not count towards the 1vs1 score. Do you notice something incorrect? Let us know as soon as possible because after the 24 hour period 1vs1 scores will not be updated. This is because of the new matches being locked in.',
    nl: '24 uur na elke gespeelde 1vs1 wedstrijd staat de eindstand van een 1vs1 match vast(!). Dit betekent dus dat niet uigespeelde wedstrijden niet meer meetellen. Zie je een fout? Meld dit dan zo snel mogelijk want voor 1vs1 wedstrijden kan dit niet meer worden hersteld. Dit is omdat er een nieuwe loting moet plaats vinden.'
  },

  'Game rules': {
    nl: 'Spelregels'
  },
  'Choose player': {
    nl: 'Kies speler'
  },
  'Make this player captain': {
    nl: 'Maak aanvoerder'
  },
  'Remove this player': {
    nl: 'Verwijder speler'
  },
  'Substitute this player': {
    nl: 'Wissel speler'
  },

  'My team': {
    nl: 'Mijn team'
  },
  // Standings: {
  //   nl: 'Tussenstand'
  // },
  More: {
    nl: 'Meer'
  },
  Predictions: {
    nl: 'Voorspellingen'
  },
  Members: {
    nl: 'Leden'
  },
  Member: {
    nl: 'Lid'
  },
  Total: {
    nl: 'Totaal'
  },
  'You already have a player of this team': {
    nl: 'Je hebt al een speler van dit team'
  },
  'You already have a player from': {
    nl: 'Je hebt al een speler van'
  },
  'Your prediction': {
    nl: 'Jouw voorspelling'
  },
  'Lineups are not know yet': {
    nl: 'Opstellingen zijn nog niet bekend'
  },
  'Do you want to join us?': {
    nl: 'Wil je met ons meespelen?'
  },
  'Every game you must first unlock with 3 credits': {
    nl: 'Ieder spel moet je eerst unlocken met 3 credits'
  },
  'Your balance': {
    nl: 'Jouw balans'
  },
  'Message from the admin': {
    nl: 'Bericht van de admin'
  },
  'admin message': {
    nl: 'admin bericht'
  },
  'The admin did not write a message': {
    nl: 'De admin heeft geen bericht achtergelaten'
  },
  'Check the rules for this game': {
    nl: 'Bekijk de spelregels voor dit spel'
  },
  Position: {
    nl: 'Positie'
  },

  'Times chosen': {
    nl: 'Keer gekozen'
  },
  'Matches played': {
    nl: 'Wedstrijden gespeeld'
  },
  Goals: {
    nl: 'Doelpunten'
  },
  'Matches won': {
    nl: 'Wedstrijden gewonnen'
  },
  'Lost matches': {
    nl: 'Verloren wedstrijden'
  },
  'Matches lost': {
    nl: 'Wedstrijden verloren'
  },
  'Clean sheets (defenders/keepers)': {
    nl: 'Clean sheets (verdidigers/keepers)'
  },
  'Yellow cards': {
    nl: 'Gele kaarten'
  },
  'Yellow card': {
    nl: 'Gele kaart'
  },
  'Red cards': {
    nl: 'Rode kaarten'
  },
  'Red card': {
    nl: 'Rode kaart'
  },
  'Yes, I want to join now': {
    nl: 'Ja, ik wil nu meespelen'
  },
  'You have enough credits to join': {
    nl: 'Je hebt genoeg credits om mee te spelen'
  },
  'Credits for 1 game': {
    nl: 'Credits voor 1 spel'
  },
  'Credits for 2 games': {
    nl: 'Credits voor 2 spellen'
  },
  'Credits for 4 games': {
    nl: 'Credits voor 4 spellen'
  },
  'You do not have enough credits to join': {
    nl: 'Je hebt niet genoeg credits om mee te spelen'
  },
  'Add credits to your account first': {
    nl: 'Voeg eerst credits toe aan jouw account'
  },
  Pay: {
    nl: 'Betaal'
  },
  'Code sent to email': {
    nl: 'Code naar email verstuurd'
  },
  Password: {
    nl: 'Wachtwoord'
  },
  'Repeat password': {
    nl: 'Herhaal wachtwoord'
  },
  'Make sure to check your spam folder': {
    nl: 'Check ook je spam map'
  },

  'Super11 rules': {
    nl: 'Super11 spelregels'
  },
  Scoring: {
    nl: 'Puntentelling'
  },
  'Admin message': {
    nl: 'Admin bericht'
  },
  Matches: {
    nl: 'Wedstrijden'
  },
  Match: {
    nl: 'Wedstrijd'
  },
  subs: {
    nl: 'wissels'
  },
  Join: {
    nl: 'Deelnemen'
  },
  'This feature will be here soon': {
    nl: 'Deze feature zal er spoedig zijn'
  },
  'Welcome to our game': {
    nl: 'Welkom bij ons spel'
  },
  'Welcome to Super11': {
    nl: 'Welkom bij Super11'
  },
  'Register a new account': {
    nl: 'Registreer een nieuw account'
  },
  'Login to an existing account': {
    nl: 'Log in op bestaand account'
  },
  'Your email address': {
    nl: 'Jouw e-mail adres'
  },
  Login: {
    nl: 'Inloggen'
  },
  Register2: {
    nl: 'Registreer',
    en: 'Register'
  },
  'Register for Super11': {
    nl: 'Registreer je voor Super11'
  },
  'You will receive a verification code on this email address': {
    nl: 'Je ontvangt zo op dit email adres een verificatie code'
  },
  or: {
    nl: 'of'
  },
  of: {
    nl: 'van'
  },
  'You received the verification code at this email address': {
    nl: 'Deze code heb je zojuist op dit email adres ontvangen'
  },
  'Full name': {
    nl: 'Volledige naam'
  },
  'Create my account': {
    nl: 'Maak mijn account'
  },
  'Email address': {
    nl: 'Email adres'
  },
  'Welcome to our': {
    nl: 'Welkom bij ons'
  },
  Win: {
    nl: 'Winst'
  },
  Draw: {
    nl: 'Gelijk'
  },
  '2nd yellow card': {
    nl: '2e gele kaart'
  },
  'If a field player takes the spot of the keeper, and this player stops a penalty, this player scores 4 points':
    {
      nl: 'Als een veldspeler de plek van de keeper moet vervangen en deze speler stopt een penalty, dan krijgt deze speler 4 punten.'
    },
  Postponed: {
    nl: 'Uitgesteld'
  },
  'Round live': {
    nl: 'Ronde live'
  },
  'Next round': {
    nl: 'Volgende ronde'
  },
  'setup changed': {
    en: 'You have change your setup, reset the team first',
    nl: 'Je hebt je setup aangepast, hestel je team eerst'
  },
  Reset: {
    nl: 'Herstel'
  },
  Round: {
    nl: 'Speelronde'
  },
  RoundShort: {
    nl: 'Ronde',
    en: 'Round'
  },
  'Please be patient for a little bit longer, we are actively working on this.':
    {
      nl: 'Nog heel eventjes geduld. We zijn druk bezig om deze functie beschikbaar te maken.'
    },
  'Our 1vs1 game starts in round 5': {
    nl: 'Ons 1vs1 spel begint in speelronde 5. Nog even geduld.'
  },
  'Check your current team': {
    nl: 'Bekijk jouw huidige team'
  },
  'Apply subs for round': {
    nl: 'Wissels toepassen voor ronde'
  },

  'Press apply subs to make changes to your team during a live round': {
    nl: 'Druk op wissels toepasses om je team aan te passen tijdens een live ronde'
  },
  'Subs left for round': {
    nl: 'Wissels over voor ronde'
  },
  'Reset team': {
    nl: 'Herstel team'
  },
  'Reset substitution': {
    nl: 'Herstel wissel'
  },
  'Are you sure you want to reset your team?': {
    en: 'Are you sure you want to reset your team? Changes for the upcoming round will be reset.',
    nl: 'Weet je zeker dat je jouw team wilt herstellen? Wijzigingen voor de aankomende speelronde zullen dan ongedaan worden gemaakt.'
  },
  'Are you sure you want to reset this substitution?': {
    nl: 'Weet je zeker dat je deze wissel wilt herstellen?'
  },
  'You can apply x more paid subs for round x': {
    en: (s, r) =>
      `You can apply ${s} more paid sub${s === 1 ? '' : 's'} for round ${r}`,
    nl: (s, r) =>
      `Je kunt nog ${s} betaalde wissel${
        s === 1 ? '' : 's'
      } toepassen voor ronde ${r}`
  },
  'We are working to fix the standings': {
    nl: 'We zijn bezig om het probleem met de stand op te lossen'
  },
  'PLEASE STAND BY. THE CORRECT STANDINGS WILL BE AVAILABLE AS SOON AS POSSIBLE.':
    {
      nl: 'EVEN GEDULD AUB. DE GOEDE STAND WORDT ZSM BIJGEWERKT.'
    },

  'Goal attempts': {
    nl: 'Doelpogingen'
  },
  Possession: {
    nl: 'Balbezit'
  },
  Corners: {
    nl: 'Corners'
  },
  Offsides: {
    nl: 'Buitenspel'
  },
  Timeline: {
    nl: 'Verloop'
  },
  Lineups: {
    nl: 'Opstelliingen'
  },
  Standings: {
    nl: 'Stand'
  },
  Favorites: {
    nl: 'Favorieten'
  },
  Rounds: {
    nl: 'Rondes'
  },
  'Base lineups': {
    nl: 'Basis opstellingen'
  },
  Substitutions: {
    nl: 'Wissels'
  },
  'Goal Disallowed': {
    nl: 'Goal afgekeurd'
  },
  Cards: {
    nl: 'Kaarten'
  },
  'No player': {
    nl: 'Geen speler'
  },
  'Your message': {
    nl: 'Je bericht'
  },

  'What do you want to report': {
    nl: 'Wat wil je aangeven'
  },
  'Missing player': {
    nl: 'Speler mist'
  },
  'Incorrect/missing match event': {
    nl: 'Er is een foute/missende match event'
  },
  'Incorrect score': {
    nl: 'Score klopt niet'
  },
  'Super11 app error/bug': {
    nl: 'Super11 app fout/bug'
  },
  'Something else': {
    nl: 'Iets anders'
  },
  Submit: {
    nl: 'Verstuur'
  },

  'Fill in the form': {
    nl: 'Vul het formulier in'
  },

  'Thanks, we will take a look!': {
    nl: 'Bedankt, we gaan er naar kijken!'
  },
  'cannot see team for this round yet': {
    en: 'You cannot see the team for this round yet',
    nl: 'Je kunt het team voor deze ronde nog niet bekijken'
  },
  'This user does not play this game': {
    nl: 'Deze gebruiker speelt dit spel niet'
  },
  'All matches': {
    nl: 'Alle wedstrijden'
  },
  'F/A': {
    nl: 'V/T'
  },

  'Expected lineups': {
    nl: 'Vermoedelijke opstellingen'
  },
  'Substitutions can still be applied': {
    nl: 'Er kan nog gewisseld worden'
  },
  'No results yet': {
    nl: 'Nog geen resultaten'
  },
  'This cup will start in round': {
    nl: 'Deze cup begint in speelronde'
  },
  'The match has started': {
    nl: 'De wedstrijd is begonnen'
  },
  'End of match': {
    nl: 'Einde wedstrijd'
  },
  'Not predicted': {
    nl: 'Niet voorspeld'
  },

  'Super11 points': {
    nl: 'Super11 punten'
  },
  Chosen: {
    nl: 'Gekozen'
  },
  'Minutes played': {
    nl: 'Minuten gespeeld'
  },
  'Penalties scored': {
    nl: 'Penalties benut'
  },
  'Penalties missed': {
    nl: 'Penalties gemist'
  },
  'Penalties saved': {
    nl: 'Penalties gestopt'
  },
  Wins: {
    nl: 'Gewonnen'
  },
  Losses: {
    nl: 'Verloren'
  },
  Draws: {
    nl: 'Gelijk'
  },
  Xchsn: {
    nl: 'Xgek'
  },
  Xcapt: {
    nl: 'Xaanv'
  },
  'No subs': {
    nl: 'Geen wissels'
  },
  'x has not substituted a player (yet)': {
    en: x => `${x} has not substituted a player (yet)`,
    nl: x => `${x} heeft (nog) geen wissels gedaan`
  },
  Won: {
    nl: 'Gewonnen'
  },
  'Clean sheet': {
    nl: 'Nul gehouden'
  },
  'Choose this player': {
    nl: 'Kies deze speler'
  },
  Bench: {
    nl: 'Bank'
  },
  'No subs yet': {
    nl: 'Nog geen wissels'
  },
  'Invalid team': {
    nl: 'Ongeldig'
  },
  'Choose player to sub': {
    nl: 'Kies speler om te wisselen'
  },
  'Press a shirt in the field to select a spot': {
    nl: 'Druk op een shirt in het veld om een plek te kiezen'
  },
  'Team saved': {
    nl: 'Team opgeslagen'
  },
  'No favorites yet': {
    nl: 'Nog geen favorieten'
  },
  'Player stats': {
    nl: 'Speler stats'
  },
  CHOSEN: {
    nl: 'GEKOZEN'
  },
  'Nobody in this group chose this player': {
    nl: 'Niemand in deze groep heeft deze speler gekozen'
  },
  'This cup has finished': {
    nl: 'Deze cup is gespeeld'
  },
  'Total score': {
    nl: 'Totaalscore'
  },
  'You already have this player': {
    nl: 'Je hebt deze speler al'
  },
  Deleted: {
    nl: 'Verwijderd'
  },
  Undo: {
    nl: 'Herstel'
  },
  'Saved in favorites': {
    nl: 'Opgeslagen in favorieten'
  },
  'Go to round x to apply substitutions': {
    en: x => `Go to round ${x} to apply substitutions`,
    nl: x => `Ga naar speelronde ${x} om vooruit te wisselen`
  },
  'About text': {
    en: 'With the Super11 App you play your own live football competition against your friends with ral players from the Eredivisie, Premier League, La Liga and more!',
    nl: 'Met de Super11 App speel je met vrienden jouw eigen live voetbal competitie met echte spelers uit de Eredivisie, Premier League, La Liga en meer!'
  },
  'Stay tuned text': {
    en: 'Right now we are running a private beta with around 100 people. The plan is to go public a month or two before the UEFA Euro 2020 (in 2021) starts.',
    nl: 'Op dit moment zijn we met een gesloten beta van 100 mensen bezig. Het plan is om een maand of twee voor het UEFA EK 2020 (in 2021) open te gaan. '
  },
  'Contact us': {
    nl: 'Neem contact op'
  },
  'What do you want to tell us?': {
    nl: 'Wat wil je ons vertellen?'
  },
  'How can we reach you?': {
    nl: 'Hoe kunnen we je bereiken?'
  },
  'We are looking forward to hearing from you!': {
    nl: 'We horen graag van je!'
  },
  'Chosen as captain': {
    nl: 'Gekozen als aanvoerder'
  },
  'Go back': {
    nl: 'Ga terug'
  },
  'No players on bench': {
    nl: 'Geen spelers op de bank'
  },
  'No sidelined players': {
    nl: 'Geen sidelined spelers'
  },
  Sidelined: {
    nl: 'Afwezig'
  },
  'All members': {
    nl: 'Alle leden'
  },
  'Group settings': {
    nl: 'Groeps instellingen'
  },
  'Start a new game': {
    nl: 'Start een nieuw spel'
  },
  'Group admin': {
    nl: 'Groep admin'
  },
  'Prize pool': {
    nl: 'Prijzenpot'
  },
  'Game settings': {
    nl: 'Spel instellingen'
  },
  yes: {
    nl: 'ja'
  },
  no: {
    nl: 'nee'
  },
  Save: {
    nl: 'Opslaan'
  },
  Games: {
    nl: 'Spellen'
  },
  'Game has already started!': {
    nl: 'Spel is al begonnen!'
  },
  participants: {
    nl: 'deelnemers'
  },
  'deposit 1 participant': {
    nl: 'inleg 1 deelnemer'
  },
  deposit: {
    nl: 'inleg'
  },
  'extra deposit': {
    nl: 'extra inleg'
  },
  'other input': {
    nl: 'overige inbreng'
  },
  'prize pool': {
    nl: 'prijzenpot'
  },
  'total in prize pool': {
    nl: 'totaal in prijzenpot'
  },
  'Use a standard prize pool for this game': {
    nl: 'Activeer een standaard prijzenpot in dit spel'
  },
  'Use a custom prize pool for this game': {
    nl: 'Activeer een custom prijzenpot in dit spel'
  },

  'ranking prizes': {
    nl: 'klassementsprijzen'
  },
  'week prizes': {
    nl: 'week prijzen'
  },
  '1VS1 cup winners': {
    nl: '1VS1 cup winnaars'
  },
  predictions: {
    nl: 'voorspellingen'
  },
  Referee: {
    nl: 'scheidsrechter'
  },
  Unknown: {
    nl: 'Onbekend'
  },
  Form: {
    nl: 'Vorm'
  },
  m: {
    nl: 'm'
  },
  h: {
    nl: 'u'
  },
  d: {
    nl: 'd'
  },
  Points: {
    nl: 'Punten'
  },
  Lineup: {
    nl: 'Opstelling'
  },
  resultLetterDraw: {
    en: 'D',
    nl: 'G'
  },
  'Group chat': {
    nl: 'Groepschat'
  },
  'Install the Super11 App now!': {
    nl: 'Installeer de Super11 App nu!'
  },
  Install: {
    nl: 'Installeer'
  },
  'Super11 App installed and added to your home screen!': {
    nl: 'De Super11 App is geïnstalleerd en toegevoegd aan je home screen!'
  },
  'Type a message': {
    nl: 'Type een bericht'
  },
  'Tap the share button': {
    nl: 'Druk op de deel knop'
  },
  'Find and press': {
    nl: 'Vind en druk op'
  },
  'Add to Home Screen': {
    nl: 'Zet op beginscherm'
  },
  'Saved in favorites and scout list in sub popup': {
    nl: 'Opgeslagen in favorieten en scout lijst in de wissel popup'
  },
  'Install the Super11 App': {
    nl: 'Installeer de Super11 App'
  },
  'Tap the share button beneath this blue popup': {
    nl: 'Druk op de deel knop onder deze blauwe popup'
  },
  'Then scroll down and press': {
    nl: 'Scroll dan naar beneden en druk op'
  },
  'Find a': {
    nl: 'Zoek een'
  },
  'Press on a player to see the player profile page': {
    nl: 'Druk op een speler om de speler profiel pagina te bekijken'
  },
  out: {
    nl: 'uit'
  },
  in: {
    nl: 'in'
  },
  Player: {
    nl: 'Speler'
  },

  'This sub will cost you x points': {
    en: x => `This sub will cost you ${x} points`,
    nl: x => `Deze wissel kost je ${x} minpunten`
  },
  'Select a player to the right': {
    nl: 'Selecteer hiernaast een speler'
  },
  'Apply this sub now': {
    nl: 'Pas deze wissel nu toe'
  },
  all: {
    nl: 'alle'
  },
  'Prediction position': {
    nl: 'Voorspelpositie'
  },
  'All time Super11 scores': {
    nl: 'All time Super11 scores'
  },
  'This user does not (yet) play any games in this group': {
    nl: 'Deze gebruiken speelt (nog) geen spellen in deze groep'
  },
  'Team stats': {
    nl: 'Team stats'
  },
  "Didn't play": {
    nl: 'Niet gespeeld'
  },
  Graphs: {
    nl: 'Grafieken'
  },
  'Contact / report': {
    nl: 'Contact / report'
  },
  Report: {
    nl: 'Report'
  },
  Scout: {
    nl: 'Scout'
  },
  Scouted: {
    nl: 'Gescout'
  },
  'I see something incorrect': {
    nl: 'Ik zie iets wat niet klopt'
  },
  'Super11 report': {
    nl: 'Super11 report'
  },
  'Want to report something else?': {
    nl: 'Wil je iets anders aangeven?'
  },
  'No leagues to choose from at the moment, check back later.': {
    nl: 'Er zijn geen leagues om uit te kiezen op dit moment, kijk later nog eens.'
  },
  'Choose a league': {
    nl: 'Kies een league'
  },
  'This user does not play any games (in this group)': {
    nl: 'Deze gebruiker speelt geen spellen (in deze groep). Of er zijn nog geen beschikbare stats'
  },
  Pool: {
    en: 'Group',
    nl: 'Poule'
  },
  PL: {
    nl: 'GS'
  },
  Pools: {
    en: 'Groups',
    nl: 'Poules'
  },
  Predict: {
    nl: 'Voorspel'
  },
  'Predict this match': {
    nl: 'Voorspel deze wedstrijd'
  },
  Prediction: {
    nl: 'Voorspelling'
  },
  'Full-time': {
    nl: 'Afgelopen'
  },
  Suspended: {
    nl: 'Gestaakt'
  },
  'Once this game starts player stats will be visible here': {
    nl: 'Zodra dit spel begonnen is kun je hier speler stats bekijken'
  },
  'Choose this player now': {
    nl: 'Kies deze speler nu'
  },
  'Once you have scouted players they will appear here': {
    nl: 'Zodra je spelers gescout hebt zullen deze hier worden weergegeven'
  },
  'No players found': {
    nl: 'Geen spelers gevonden'
  },
  'Choose a contact email address': {
    nl: 'Kies een contact email adres'
  },

  "With this email address members in your group will be able to contact you. Super11 automatically forwards all messages to your email address. Members can't see your email address. Make this field empty if you don't want to receive messages from users.":
    {
      nl: `Via dit e-mailadres kunnen leden contact met je opnemen. Super11 stuurt alle berichten automatisch door naar jouw e-mail adres. Leden kunnen jouw e-mail adres niet zien. Laat dit veld leeg als je geen berichten wilt ontvangen van leden.`
    },
  "With this email address members in your group will be able to contact you. Make this field empty if you don't want this.":
    {
      nl: 'Via dit e-mailadres kunnen leden contact met je opnemen. Laat dit veld leeg als je dit niet wilt'
    },

  'I have read everything and filled in everything correctly': {
    nl: 'Ik heb alles gelezen en goed ingevuld'
  },
  'After you created this group you will go to the group admin. Hier you can manage everything and create new games.':
    {
      nl: 'Nadat je deze groep hebt aangemaakt kom je direct in de groep admin terecht. Hier kun je alles beheren en nieuwe spellen maken.'
    },

  'Are you sure you read everything?': {
    nl: 'Weet je zeker dat je alles hebt gelezen?'
  },
  "Match didn't start yet": {
    nl: 'Wedstrijd nog niet begonnen'
  },
  'Group name': {
    nl: 'Naam groep'
  },
  'Group logo': {
    nl: 'Groeps logo'
  },
  'Do you want to enable a chat box for this group?': {
    nl: 'Wil je dat er een chatbox is voor deze groep?'
  },
  'Change group logo': {
    nl: 'Pas groeps logo aan'
  },
  'Only unique names are allowed': {
    nl: 'Alleen unieke namen zijn toegestaan'
  },
  'Save logo': {
    nl: 'Logo opslaan'
  },
  'Upload a new logo': {
    nl: 'Upload een nieuw logo'
  },
  'Group link': {
    nl: 'Groep link'
  },
  'This link is already in use': {
    nl: 'Deze link is al in gebruik'
  },
  'This link is available': {
    nl: 'Deze link is beschikbaar'
  },
  'Invalid link': {
    nl: 'Ongeldige link'
  },
  'Checking link': {
    nl: 'Link aan het checken'
  },
  'Link too short': {
    nl: 'Link te kort'
  },
  'Link too long': {
    nl: 'Link te lang'
  },
  'Link contains invalid characters': {
    nl: 'Link bevat ongelidige tekens'
  },
  'Only unique links are allowed': {
    nl: 'Alleen unieke links zijn toegestaan'
  },
  Allowed: {
    nl: 'Toegestaan'
  },
  'Copied to clipboard': {
    nl: 'Gekopieerd naar het klembord'
  },
  'Use round numbers for prizes': {
    nl: 'Gebruik ronde getallen voor prijzen'
  },
  'Should members be automatically accepted for this game?': {
    nl: 'Moeten leden automatisch goedgekeurd worden voor dit spel?'
  },
  'Save message': {
    nl: 'Bericht opslaan'
  },
  'When a member will not be confirmed by an admin, this player will automatically be removed as soon as the game starts.':
    {
      nl: 'Wanneer je een deelnemer niet bevestigd zal deze niet meedoen aan het spel en zal worden verwijderd zodra het spel is begonnen.'
    },
  'Joined members will automatically be able to play this game. A group admin does not have to do anything.':
    {
      nl: 'Ingeschreven deelnemers doen automatisch mee met dit spel. Hiervoor hoeft een admin niets te doen.'
    },
  'Your Super11 Credits': {
    nl: 'Jouw Super11 Credits'
  },
  'Go back to': {
    nl: 'Ga terug naar'
  },
  allMembers: {
    en: 'All members',
    nl: 'Alle leden'
  },
  'Because this game has not yet started you can still delete it.': {
    nl: 'Omdat dit spel nog niet gestart is kun je het nog verwijderen.'
  },
  'All members will get the credits they might have paid back.': {
    nl: 'Alle leden zullen eventuele betaalde credits terug ontvangen.'
  },
  'Delete this game': {
    nl: 'Verwijder dit spel'
  },
  'Write the name of your group to confirm.': {
    nl: 'Schrijf de naam van je groep om te bevestigen'
  },
  'Incorrect group name': {
    nl: 'Groep naam incorrect'
  },
  'Link preview': {
    nl: 'Link voorbeeld'
  },
  'Press to copy': {
    nl: 'Druk om te kopiëren'
  },
  Cancel: {
    nl: 'Annuleren'
  },
  'Are you sure you want to change the admin status?': {
    nl: 'Weet je zeker dat je de admin status wilt aanpassen?'
  },
  // 'Register now': {
  //   nl: 'Nu inschrijven'
  // },
  'We added a new game': {
    nl: 'Wij hebben een nieuw spel toegevoegd'
  },
  'Currency symbol': {
    nl: 'Valuta symbool'
  },
  'Delete this group': {
    nl: 'Verwijder deze groep'
  },
  'Because there are no active games (with members) in this group you can delete it.':
    {
      nl: 'Omdat er op dit moment geen actieve spellen (met leden) worden gespeeld in deze groep kun je de groep verwijderen.'
    },
  'Leave this group': {
    nl: 'Verlaat deze groep'
  },
  'Open group admin': {
    nl: 'Open groep admin'
  },
  Leave: {
    nl: 'Verlaat'
  },
  Chat: {
    nl: 'Chat'
  },
  Rules: {
    nl: 'Regels'
  },
  Admin: {
    nl: 'Admin'
  },
  News: {
    nl: 'Nieuws'
  },
  Account: {
    nl: 'Account'
  },
  Invite: {
    nl: 'Uitnodigen'
  },
  'Group invite link copied automatically': {
    nl: 'Groep uitnodig link automatisch gekopieerd'
  },
  Confirm: {
    nl: 'Bevestigen'
  },
  Decline: {
    nl: 'Afkeuren'
  },
  'Leave game': {
    nl: 'Verlaat spel'
  },
  'Are you sure you want to leave this game?': {
    nl: 'Weet je zeker dat je dit spel wilt verlaten?'
  },
  pnt: {
    nl: 'pnt'
  },
  'Choose option': {
    nl: 'Kies optie'
  },
  'Fill in previous round': {
    nl: 'Vul de vorige ronde in '
  },
  Group: {
    nl: 'Groep'
  },
  'Remove choice': {
    nl: 'Verwijder keuze'
  },
  Questions: {
    nl: 'Vragen'
  },
  'Choose country': {
    nl: 'Kies land'
  },
  'We have updated the app': {
    nl: 'We hebben de app aangepast'
  },
  'Remove member from game': {
    nl: 'Verwijder lid uit spel'
  },
  'Are you sure you want to remove this member from this game?': {
    nl: 'Weet je zeker dat je dit lid wilt verwijderen uit dit spel?'
  },
  Yes: {
    nl: 'Ja'
  },
  No: {
    nl: 'Nee'
  },
  'Game is live, member cannot be removed (anymore)': {
    nl: 'Spel is live, lid kan niet (meer) worden verwijderd'
  },
  'Are you sure you want to remove x from y?': {
    en: (x, y) => `Are you sure you want to remove ${x} from ${y}?`,
    nl: (x, y) => `Weet je zeker dat je ${x} verwijderen uit ${y}?`
  },
  'Member plays a live game. Member cannot be removed.': {
    nl: 'Lid speelt een live spel. Lid kan niet worden verwijderd.'
  },
  'You cannot leave your own group. You can delete this group from the group settings, if there are no live games being played in this group.':
    {
      nl: 'Je kunt je eigen groep niet verlaten. Je kunt deze groep verwijderen in de groeps instellingen, als er geen live spellen in deze groep worden gespeeld'
    },
  'Remove member from group': {
    nl: 'Verwijder lid uit groep'
  },
  total: {
    nl: 'totaal'
  },
  'Add a photo': {
    nl: 'Voeg een foto toe'
  },
  user: {
    nl: 'gebruiker'
  },
  fixture: {
    nl: 'wedstrijd'
  },
  playerFixture: {
    en: 'player fixture stats',
    nl: 'speler wedstrijd stats'
  },
  team: {
    nl: 'team'
  },
  'Did you see something incorrect?': {
    nl: 'Heb je iets gezien wat niet klopt?'
  },
  'missed penalty': {
    nl: 'penalty gemist'
  },
  'own goal': {
    nl: 'eigen doelpunt'
  },
  'My account': {
    nl: 'Mijn account'
  },
  'Super11 credits': {
    nl: 'Super11 credits'
  },
  Preferences: {
    nl: 'Voorkeuren'
  },
  'Groups and games': {
    nl: 'Groepen en spellen'
  },
  Notifications: {
    nl: 'Notificaties'
  },
  Edit: {
    nl: 'Aanpassen'
  },
  History: {
    nl: 'Geschiedenis'
  },
  'Buy now': {
    nl: 'Koop nu'
  },
  For: {
    nl: 'Voor'
  },
  Soon: {
    nl: 'Spoedig'
  },
  Preview: {
    nl: 'Voorbeeld'
  },
  'Account and settings': {
    nl: 'Account en instellingen'
  },
  'Feel free to contact us': {
    nl: 'Aarzel niet om contact met ons op te nemen'
  },
  'A football community': {
    nl: 'Een voetbal community'
  },
  'Super11 community': {
    nl: 'Super11 community'
  },
  'Read more about Super11, chat with the community and give your feedback about the Super11 platform':
    {
      nl: 'Lees meer over Super11, chat met de community en geef je feedback over het Super11 platform'
    },
  'Contact us now': {
    nl: 'Neem nu contact op'
  },
  'Register now': {
    nl: 'Nu registreren'
  },
  'Help center': {
    nl: 'Helpcenter'
  },
  'Chat in real time with the Super11 community': {
    nl: 'Chat in real time met de Super11 community'
  },
  'Follow our IG account just like 76000 others': {
    nl: 'Volg ons IG account net als 76000 anderen'
  },
  'We are also available on facebook': {
    nl: 'We zijn ook beschikbaar op Facebook'
  },
  'Fast updates, features and news messages': {
    nl: 'Snelle updates features en nieuwsberichten'
  },
  'Subscribe for updates and news': {
    nl: 'Meld je aan voor updates en nieuws'
  },
  'your@email.com': {
    nl: 'jouw@email.com'
  },
  'You are subscribed to the newsletter': {
    nl: 'Je bent aangemeld voor de nieuwsbrief'
  },
  Sending: {
    nl: 'Aan het versturen'
  },
  'Error signing up': {
    nl: 'Fout bij aanmelden'
  },
  Helpcenter: {
    nl: 'Helpcenter'
  },
  'What is Super11': {
    nl: 'Wat is Super11'
  },
  FAQ: {
    nl: 'FAQ'
  },
  Affiliate: {
    nl: 'Affiliate'
  },
  'I already have an account': {
    nl: 'Ik heb al een account'
  },
  'Receive verification code': {
    nl: 'Ontvang verificatie code'
  },
  'Create an account faster?': {
    nl: 'Sneller een account aanmaken?'
  },
  'Verification code': {
    nl: 'Verificatie code'
  },
  'No account yet?': {
    nl: 'Nog geen account?'
  },
  Step: {
    nl: 'Stap'
  },
  'Forgot password?': {
    nl: 'Wachtwoord vergeten?'
  },
  'Need help?': {
    nl: 'Hulp nodig?'
  },
  'Search in helpcenter': {
    nl: 'Zoeken in helpcenter'
  },
  Predict_short: {
    en: 'Predict',
    nl: 'Vrspl'
  },
  'left over': {
    nl: 'blijft over'
  },
  'You have been invited for the group': {
    nl: 'Je bent uitgenodigd voor de groep'
  },
  'What our users say': {
    nl: 'Wat onze gebruikers zeggen'
  },
  "Your haven't filled in your predictions": {
    nl: 'Jouw voorspellingen zijn nog niet ingevuld'
  },
  'Your predictions have been filled in': {
    nl: 'Jouw voorspellingen zijn ingevuld'
  },

  'You have been confirmed by a group admin': {
    nl: 'Je bent bevestigd door een groep admin'
  },
  "You haven't been confirmed by a group amin": {
    nl: 'Je bent nog niet bevestigd door een groep admin'
  },

  Turkey: {
    nl: 'Turkije'
  },
  Italy: {
    nl: 'Italië'
  },
  Wales: {
    nl: 'Wales'
  },
  Switzerland: {
    nl: 'Zwitserland'
  },
  Denmark: {
    nl: 'Denemarken'
  },
  Finland: {
    nl: 'Finland'
  },
  Belgium: {
    nl: 'België'
  },
  Russia: {
    nl: 'Rusland'
  },
  England: {
    nl: 'Engeland'
  },
  Croatia: {
    nl: 'Kroatië'
  },
  Austria: {
    nl: 'Oostenrijk'
  },
  'North Macedonia': {
    nl: 'Noord-Macedonië'
  },
  Netherlands: {
    nl: 'Nederland'
  },
  Romania: {
    nl: 'Roemenië'
  },
  Albania: {
    nl: 'Albanië'
  },
  Georgia: {
    nl: 'Georgië'
  },
  Slovenia: {
    nl: 'Slovenië'
  },
  Ukraine: {
    nl: 'Oekraïne'
  },
  Scotland: {
    nl: 'Schotland'
  },
  'Czech Republic': {
    nl: 'Tsjechië'
  },
  Poland: {
    nl: 'Polen'
  },
  Slovakia: {
    nl: 'Slowakije'
  },
  Spain: {
    nl: 'Spanje'
  },
  Sweden: {
    nl: 'Zweden'
  },
  Hungary: {
    nl: 'Hongarije'
  },
  Portugal: {
    nl: 'Portugal'
  },
  France: {
    nl: 'Frankrijk'
  },
  Germany: {
    nl: 'Duitsland'
  },
  Argentina: {
    nl: 'Argentinië'
  },

  Qatar: { nl: 'Qatar' },
  Ecuador: { nl: 'Ecuador' },
  'IR Iran': { en: 'Iran', nl: 'Iran' },
  Senegal: { nl: 'Senegal' },
  'United States': { nl: 'Verenigde Staten' },
  'Saudi Arabia': { nl: 'Saoedi-Arabië' },
  Tunisia: { nl: 'Tunesië' },
  Mexico: { nl: 'Mexico' },
  Australia: { nl: 'Australië' },
  Morocco: { nl: 'Marokko' },
  Japan: { nl: 'Japan' },
  'Costa Rica': { nl: 'Costa Rica' },
  Canada: { nl: 'Canada' },
  Cameroon: { nl: 'Kameroen' },
  Uruguay: { nl: 'Uruguay' },
  'Korea Republic': { nl: 'Koreaanse republiek' },
  Ghana: { nl: 'Ghana' },
  Brazil: { nl: 'Brazilië' },
  Serbia: { nl: 'Servië' },

  '2nd Group A': {
    nl: '2e Groep A'
  },
  '2nd Group B': {
    nl: '2e Groep B'
  },
  '1st Group A': {
    nl: '1e Groep A'
  },
  '2nd Group C': {
    nl: '2e Groep C'
  },
  '1st Group C': {
    nl: '1e Groep C'
  },
  '3rd Group D/E/F': {
    nl: '3e Groep D/E/F'
  },
  '1st Group B': {
    nl: '1e Groep B'
  },
  '3rd Group A/D/E/F': {
    nl: '3e Groep A/D/E/F'
  },
  '2nd Group D': {
    nl: '2e Groep D'
  },
  '2nd Group E': {
    nl: '2e Groep E'
  },
  '1st Group F': {
    nl: '1e Groep F'
  },
  '3rd Group A/B/C': {
    nl: '3e Groep A/B/C'
  },
  '1st Group D': {
    nl: '1e Groep D'
  },
  '2nd Group F': {
    nl: '2e Groep F'
  },
  '1st Group E': {
    nl: '1e Groep E'
  },
  '1st Group G': {
    nl: '1e Groep G'
  },
  '2nd Group H': {
    nl: '2e Groep H'
  },
  '1st Group H': {
    nl: '1e Groep H'
  },
  '2nd Group G': {
    nl: '2e Groep G'
  },
  'Winner Match 53': {
    nl: 'Winnaar 53e Match'
  },
  'Winner Match 54': {
    nl: 'Winnaar 54e Match'
  },
  'Winner Match 49': {
    nl: 'Winnaar 49e Match'
  },
  'Winner Match 50': {
    nl: 'Winnaar 50e Match'
  },
  'Winner Match 55': {
    nl: 'Winnaar 55e Match'
  },
  'Winner Match 38': {
    nl: 'Winnaar 38e Match'
  },
  'Winner Match 43': {
    nl: 'Winnaar 43e Match'
  },
  'Winner Match 44': {
    nl: 'Winnaar 44e Match'
  },
  'Winner Match 39': {
    nl: 'Winnaar 39e Match'
  },
  'Winner Match 37': {
    nl: 'Winnaar 37e Match'
  },
  'Winner Match 40': {
    nl: 'Winnaar 40e Match'
  },
  'Winner Match 41': {
    nl: 'Winnaar 41e Match'
  },
  'Winner Match 42': {
    nl: 'Winnaar 42e Match'
  },

  'Winner Match 56': {
    nl: 'Winnaar 56e Match'
  },
  'Winner Match 51': {
    nl: 'Winnaar 51e Match'
  },
  'Winner Match 52': {
    nl: 'Winnaar 52e Match'
  },
  'Winner Quarter-final 1': {
    nl: 'Winnaar Kwart-finale 1'
  },
  'Winner Quarter-final 2': {
    nl: 'Winnaar Kwart-finale 2'
  },
  'Winner Quarter-final 3': {
    nl: 'Winnaar Kwart-finale 3'
  },
  'Winner Quarter-final 4': {
    nl: 'Winnaar Kwart-finale 4'
  },
  'Loser Semi-final 1': {
    nl: 'Verliezer Halve-finale 1'
  },
  'Loser Semi-final 2': {
    nl: 'Verliezer Halve-finale 2'
  },
  '3rd Group A/B/C/D': {
    nl: '3e Groep A/B/C/D'
  },
  'Winner 41st Match': {
    nl: 'Winnaar 41e Match'
  },
  'Winner 42nd Match': {
    nl: 'Winnaar 42e Match'
  },
  'Winner 39th Match': {
    nl: 'Winnaar 39e Match'
  },
  'Winner 37th Match': {
    nl: 'Winnaar 37e Match'
  },
  'Winner 40th Match': {
    nl: 'Winnaar 40e Match'
  },
  'Winner 38th Match': {
    nl: 'Winnaar 38e Match'
  },
  'Winner 43rd Match': {
    nl: 'Winnaar 43e Match'
  },
  'Winner 44th Match': {
    nl: 'Winnaar 44e Match'
  },
  'Winner 46th Match': {
    nl: 'Winnaar 46e Match'
  },
  'Winner 45th Match': {
    nl: 'Winnaar 45e Match'
  },
  'Winner 48th Match': {
    nl: 'Winnaar 48e Match'
  },
  'Winner 47th Match': {
    nl: 'Winnaar 47e Match'
  },
  'Winner Semi-final 1': {
    nl: 'Winnaar Halve Finale 1'
  },
  'Winner Semi-final 2': {
    nl: 'Winnaar Halve Finale 2'
  },
  'Round of 16': {
    nl: 'Ronde van 16'
  },
  'Prize pool information': {
    nl: 'Prijzenpot informatie'
  },
  'Last edited by group admin': {
    nl: 'Laatste wijziging door groep admin'
  },
  prizePoolInfo: {
    en: 'This is a virtual prize pool. Because football data can change or be corrected, scores and statistics are continuously collected. 48 hours after the game has completely finished, the league data will no longer be updated and every will be official. This is 48 hours after the final round of a game.',
    nl: 'Dit is een virtuele prijzenpot. Omdat voetbaldata kan wijzigen worden scores en statistieken continu opgehaald. 48 uur nadat een spel in zijn geheel is afgelopen wordt een competitie niet meer upgedate en staat alles officieel vast. Dit is dus 48 uur na de laatste speelronde uit een spel.'
  },
  Important: {
    nl: 'Belangrijk'
  },
  prizePoolDisclaimer: {
    nl: groupName =>
      `Deze prijzenpot is opgezet door de groep ${groupName}. De organisatie van Super11 is hiervoor op geen enkele manier verantwoordelijk. Alle gebruikers van Super11 weten middels de algemene voorwaarden dat Super11 op geen enkele manier aansprakelijk kan worden gesteld en/of geschillen binnen een groep kan oplossen. Super11 biedt zelf op geen enkele wijze geldprijzen en/of fysieke prijzen van waarde aan.`,
    en: groupName =>
      `This prize pool has been created by the group ${groupName}. The Super11 organisation is in no way responsible for this. Every Super11 user knows from the terms of service that Super11 can in no way be held accountable for any disputes within a group. Super11 offers no money and or physical prizes of any worth in any form.`
  },
  'You are already a member of this group': {
    nl: 'Je bent al lid van deze groep'
  },
  General: {
    nl: 'Algemeen'
  },
  'This is what you have to do before the game starts': {
    nl: 'Dit moet je doen voordat het spel start'
  },
  'Predict fixtures': {
    nl: 'Voorspel wedstrijden'
  },
  'Choose 11 players': {
    nl: 'Kies 11 spelers'
  },
  'Choose a captain': {
    nl: 'Kies een aanvoerder'
  },
  'Make sure you are accepted by a group admin (read admin message)': {
    nl: 'Zorg ervoor dat je bent geaccepteerd door een groep admin (lees evt. admin bericht)'
  },
  'Now check all the game rules, point systems and other information using the navigation in this popup.':
    {
      nl: 'Bekijk nu eerst alle spelregels, puntentellingen en overige informatie via de navigatie in deze popup.'
    },
  'Super11 scoring': {
    nl: 'Super11 puntentelling'
  },
  'Predictions scoring': {
    nl: 'Puntentelling voorspellingen'
  },
  Fixtures: {
    nl: 'Wedstrijden'
  },
  'Spots (EC/WC)': {
    nl: 'Spots (EK/WK)'
  },
  'Questions (EC/WC)': {
    nl: 'Vragen (EK/WK)'
  },
  points: {
    nl: 'punten'
  },
  point: {
    nl: 'punt'
  },
  'General terms': {
    nl: 'Algemene voorwaarden'
  },
  "I've read the terms": {
    nl: 'Ik heb de voorwaarden gelezen'
  },
  'By creating an account you accept the': {
    nl: 'Door een account aan te maken accepteer je de'
  },
  terms: {
    nl: 'voorwaarden'
  },

  ordinal: {
    nl: x => `${x}e`,
    en: x => {
      const lastDigit = x.toString().slice(-1);

      return `${x}${
        lastDigit === '1'
          ? 'st'
          : lastDigit === '2'
          ? 'nd'
          : lastDigit === '3'
          ? 'rd'
          : 'th'
      }`;
    }
  },
  place: {
    nl: 'plaats'
  },
  'This prize pool was created by': {
    nl: 'Deze prijzenpot is opgezet door'
  },
  'Questions? Contact the group admin': {
    nl: 'Vragen? Neem contact op met de groeps admin'
  },
  'Group admin contact': {
    nl: 'Groeps admin contact'
  },
  'Write a message': {
    nl: 'Schrijf je bericht'
  },
  'Send a message to the admin of this group': {
    nl: 'Stuur een bericht naar de admin van deze groep'
  },

  adminMailInfo: {
    nl: groupName =>
      `Dit bericht wordt verstuurd via e-mail naar de administrator van de groep: ${groupName}. Jouw username + e-mail adres worden automatisch meegestuurd zodat de admin evt. kan antwoorden.`,
    en: groupName =>
      `This message will be sent by email to the administrator of the group: ${groupName}. Your username and email address will be sent as well so that the admin can answer you.`
  },
  Send: {
    nl: 'verstuur'
  },
  'Message sent!': {
    nl: 'Bericht verstuurd!'
  },
  'Install app': {
    nl: 'Installeer app'
  },
  'Group partners': {
    nl: 'Groeps partners'
  },
  'This group has no partners. You can add partners below.': {
    nl: 'Deze groep heeft geen partners. Je kunt hier beneden partners toevoegen.'
  },
  'New users get 3 credits to play a game for FREE till the 11th of June!': {
    nl: 'Nieuwe gebruikers krijgen tot 11 juni 3 credits om een GRATIS spel te spelen!'
  },
  'Group terms': {
    nl: 'Groeps voorwaarden'
  },
  'Play real-time live football games': {
    nl: 'Speel bij ons real-time live voetbalspellen'
  },
  Country: {
    nl: 'Land'
  },
  'Groups lobby': {
    nl: 'Groepen lobby'
  },
  'Do you want to hide the name of the group owner?': {
    nl: 'Wil je de naam van de groepseigenaar verbergen?'
  },
  'Do you want this group to be private or public?': {
    nl: 'Wil je dat deze groep besloten of openbaar is?'
  },
  'Purchase completed': {
    nl: 'Aankoop geslaagd'
  },
  "You can close this page if it doesn't close automatically": {
    nl: 'Je kunt deze pagina sluiten als dit niet automatisch gebeurt'
  },
  'You can go back to the app': {
    nl: 'Je kunt terug gaan naar de app'
  },
  Reloading: {
    nl: 'Herladen'
  },
  'Pay attention': {
    nl: 'Let op'
  },
  'You have not yet registered for the x game of y.': {
    en: (x, y) => `You have not yet registered for the ${x} game of ${y}.`,
    nl: (x, y) => `Je bent nog niet ingeschreven voor het ${x} spel van ${y}.`
  },
  'Go to the registration screen': {
    nl: 'Ga naar het registratie scherm'
  },
  'Go to the login screen': {
    nl: 'Ga naar het login scherm'
  },
  'You can register yourself next to this message.': {
    nl: 'Je kunt jezelf hiernaast inschrijven.'
  },
  KvK: {
    en: 'Chamber of commerce'
  },
  Costs: {
    nl: 'Kosten'
  },
  'Super11 is being actively developed by': {
    nl: 'Super11 wordt actief aan gewerkt door'
  },
  'Left over': {
    nl: 'Blijft over'
  },
  'Left over total': {
    nl: 'Blijf over totaal'
  },
  Distribution: {
    nl: 'Verdeling'
  },
  'Total available': {
    nl: 'Totaal beschikbaar'
  },
  'Prizes added up': {
    nl: 'Prijzen opgeteld'
  },
  'Per spot': {
    nl: 'Per plek'
  },
  StandingsPlural: {
    en: 'Standings',
    nl: 'Standen'
  },
  'Game starts in': {
    nl: 'Spel begint over'
  },
  'Replace this player for free': {
    nl: 'Vervang deze speler gratis'
  },
  'This player has been set to inactive and cannot score points for you.': {
    nl: 'Deze speler is op inactief gezet en kan geen punten voor jou behalen.'
  },
  'Do you want to hide this group?': {
    nl: 'Wil je deze groep verbergen?'
  },
  'The Netherlands': {
    nl: 'Nederland'
  },
  "No mail? Don't forget to check your spam folder.": {
    nl: 'Geen mail ontvangen? Vergeet niet in je spam map te kijken.'
  },

  'Members WILL BE automatically confirmed.': {
    nl: 'Deelnemers zullen AUTOMATISCH worden bevestigd.'
  },
  'Members WILL NOT BE automatically confirmed.': {
    nl: 'Deelnemers zullen NIET AUTOMATISCH worden bevestigd.'
  },
  'You are not playing this game': {
    nl: 'Je speelt dit spel niet'
  },
  'too many substitutions': {
    en: 'Too many substitutions',
    nl: 'Te veel wissels'
  },
  'forgot password intro': {
    en: `Forgot your password? No worries, we're going to fix that. Enter the <b><i>email address</i></b> you used to sign up for Super11 below. When you did this press the green button "Send reset reset code".`,
    nl: 'Wachtwoord vergeten? Geen paniek, dat gaan we oplossen. Vul hieronder jouw <b><i>e-mail adres</i></b> in waarmee je eerder een account heb geregistreerd op Super11. Druk vervolgens op de groene knop "Verstuur herstelcode".'
  },
  "Don't forget to check your spam folder": {
    nl: 'Vergeet niet om in je spam map te kijken'
  },
  'forgot password code sent': {
    en: `Thanks. If your Super11 account was created with the above email address we sent you an email with a reset code. Don't forget to check your spam folder in case you don't see the email. Enter the reset code below and press the green button "create new password".`,
    nl: `Bedankt. Als je een Super11 account hebt gemaakt met het bovenstaande e-mail adres hebben we een e-mail gestuurd met een herstel code. Bekijk ook jouw spam map mocht je de e-mail niet zien. Vul de herstelcode nu hieronder in en druk vervolgens op de groene knop "maak een nieuw wachtwoord".`
  },
  'forgot password complete': {
    en: `We mailed you a new password. Don't forget to check your spam folder in case you don't see the email. You can now log in with your email address and the password we just sent to you. When you logged in you can change your password in the account settings.`,
    nl: `We hebben zojuist een nieuw wachtwoord naar je toe gemaild. Bekijk ook jouw spam map mocht je de e-mail niet zien. Je kunt nu opnieuw inloggen met jouw e-mail adres en het zojuist ontvangen wachtwoord. Wanneer je bent ingelogd kun je het wachtwoord aanpassen via de account instellingen. Succes!`
  },
  'Create new password': {
    nl: 'Maak een nieuw wachtwoord'
  },
  'Reset code': {
    nl: 'Herstelcode'
  },
  'Try again': {
    nl: 'Opnieuw proberen'
  },
  'Password reset': {
    nl: 'Wachtwoord vergeten'
  },
  'It can take up to a few minutes to receive an email': {
    nl: 'Het kan een paar minuten duren om een e-mail te ontvangen'
  },
  'Server offline or you are offline': {
    nl: 'Server offline of jij bent offline'
  },
  'This player is inactive': {
    nl: 'Deze speler is inactief'
  },
  'Free substitutions': {
    nl: 'Gratis wissels'
  },
  'No paid subs': {
    nl: 'Geen betaalde wissels'
  },
  'Penalty miss': {
    nl: 'Penalty mis'
  },
  'Penalty goal': {
    nl: 'Penalty raak'
  },
  'You have the maximum amount of players of this team': {
    nl: 'Je hebt het de maximum hoeveelheid spelers van dit team'
  },
  Live: {
    nl: 'Live'
  },
  Played: {
    nl: 'Gespeeld'
  },
  'The game has finished': {
    nl: 'Het spel is afgelopen'
  },
  'Add predictions': {
    nl: 'Voeg voorspellingen toe'
  },
  unlockPredictionSpotDesc: {
    en: 'If you want to enter new predictions you can buy a new predictions database for this competition. This can be useful if you play multiple games on Super11 with this competition. This way you can increase your odds!',
    nl: 'Wanneer je voor dit spel nieuwe voorspellingen wilt invullen kun je een nieuwe database voor deze competitie kopen. Dit kan handig zijn als je op Super11 meerdere spellen speelt met deze competitie. Zo is het mogelijk jouw win kansen te verspreiden!'
  },
  'Unlocking costs 3 Super11 credits': {
    nl: 'Unlocken kost 3 Super11 credits'
  },
  'You have enough credits': {
    nl: 'Je hebt genoeg credits'
  },
  'You do not have enough credits': {
    nl: 'Je hebt niet genoeg credits'
  },
  'Unlock this feature now': {
    nl: 'Unlock deze feature nu'
  },
  'Not enough credits': {
    nl: 'Niet genoeg credits'
  },
  'Predictions unlocked': {
    nl: 'Voorspellingen unlocked'
  },
  'To be announced': {
    nl: 'Nog niet bekend'
  },
  Rankings: {
    nl: 'Klassement'
  },
  Overview: {
    nl: 'Overzicht'
  },
  Super11: {
    nl: 'Super11'
  },
  'There are no prizes for this category': {
    nl: 'Er zijn geen prijzen voor deze categorie'
  },
  s11_rankings: {
    en: 'Super11 rankings',
    nl: 'Super11 klassement'
  },
  s11_rounds: {
    en: 'Super11 rounds',
    nl: 'Super11 rondes'
  },
  predict_rankings: {
    en: 'Predict rankings',
    nl: 'Voorspel klassement'
  },
  predict_rounds: {
    en: 'Predict rounds',
    nl: 'Voorspel rondes'
  },
  s11_predict_rankings: {
    en: 'Total rankings',
    nl: 'Totaal klassement'
  },
  s11_predict_rounds: {
    en: 'Total rounds',
    nl: 'Totaal rondes'
  },
  '1VS1_cup_winners': {
    en: '1VS1 cup winners',
    nl: '1VS1 cup winnaars'
  },
  '1VS1': {
    en: '1VS1',
    nl: '1VS1'
  },
  'Per cup': {
    nl: 'Per cup'
  },
  "Can't login?": {
    nl: 'Problemen met inloggen?'
  },

  'Forgot password or other problems logging in?': {
    nl: 'Wachtwoord vergeten of andere inlog problemen?'
  },

  'Sort by round': {
    nl: 'Sorteer op speelronde'
  },
  Penalties: {
    nl: 'Pennalties'
  },
  Shots: {
    nl: 'Schoten'
  },
  'Shots on goal': {
    nl: 'Schoten op doel'
  },
  'Passes accuracy': {
    nl: 'Passes nauwkeurigheid'
  },
  'Duels won': {
    nl: 'Duels gewonnen'
  },
  'Aerials won': {
    nl: 'Kopduels gewonnen'
  },
  Interceptions: {
    nl: 'Intercepties'
  },
  Fouls: {
    nl: 'Overtredingen'
  },
  Offside: {
    nl: 'Buitenspel'
  },
  'Match score': {
    nl: 'Wedstrijd score'
  },
  'Player page': {
    nl: 'Speler pagina'
  },
  Close: {
    nl: 'Sluiten'
  },
  List: {
    nl: 'Lijst'
  },
  'Substitution players': {
    en: 'Substition players',
    nl: 'Wissel spelers'
  },
  'Bench players': {
    nl: 'Bank spelers'
  },
  'Lined up': {
    nl: 'Basisplaatsen'
  },
  Minutes: {
    nl: 'Minuten'
  },
  Minute: {
    nl: 'Minuut'
  },
  avg: {
    nl: 'gem'
  },
  Rating: {
    nl: 'Beoordeling'
  },
  'Lineups unknown': {
    nl: 'Opstellingen onbekend'
  },
  'Stats unknown': {
    nl: 'Stats onbekend'
  },
  Career: {
    nl: 'Carrière'
  },
  Coach: {
    nl: 'Trainer'
  },
  Squad: {
    nl: 'Selectie'
  },
  lost: {
    nl: 'verloren'
  },
  'Club stats': {
    nl: 'Club stats'
  },
  'Competition points': {
    nl: 'Competitie punten'
  },
  Lost: {
    nl: 'Verloren'
  },
  'Competition rank': {
    nl: 'Competitie positie'
  },
  'Club stat score': {
    nl: 'Club stat score'
  },
  Duels: {
    nl: 'Duels'
  },
  'Clean sheets': {
    nl: 'Clean sheets'
  },
  'Second yellow cards': {
    nl: '2e gele kaarten'
  },
  'Goals against': {
    nl: 'Doelpunten tegen'
  },
  'Check fixture': {
    nl: 'Bekijk wedstrijd'
  },
  'Super11 competitions': {
    nl: 'Super11 competities'
  },
  'Go to fixture': {
    nl: 'Ga naar wedstrijd'
  },
  Players: {
    nl: 'Spelers'
  },
  Teams: {
    nl: 'Clubs'
  },
  'All teams': {
    nl: 'Alle clubs'
  },
  'All teamsLiteral': {
    en: 'All teams',
    nl: 'Alle teams'
  },
  'Home+away': {
    nl: 'Thuis + uit'
  },
  'HOME FIXTURE': {
    nl: 'Thuis',
    en: 'Home'
  },
  Away: {
    nl: 'Uit'
  },
  Tackles: {
    nl: 'Tackles'
  },
  Assists: {
    nl: 'Assists'
  },
  Passes: {
    nl: 'Passes'
  },
  RED_CARD_SHORT: {
    en: 'RED',
    nl: 'ROOD'
  },
  YELLOW_CARD_SHORT: {
    en: 'YLLW',
    nl: 'GEEL'
  },
  CLEAN_SHEET_SHORT: {
    en: 'CS',
    nl: 'CS'
  },
  SHOTS_ON_GOAL_SHORT: {
    en: 'SOG',
    nl: 'SOD'
  },
  PASSES_SHORT: {
    en: 'PASS',
    nl: 'PASS'
  },
  PASSES_ACCURACY_SHORT: {
    en: 'PA',
    nl: 'PN'
  },
  DUELS_WON_SHORT: {
    en: 'DW',
    nl: 'DG'
  },
  AERIALS_WON_SHORT: {
    en: 'AW',
    nl: 'KG'
  },
  INTERCEPTIONS_SHORT: {
    en: 'INT',
    nl: 'INT'
  },
  TACKLES_SHORT: {
    en: 'TCKLS',
    nl: 'TCKLS'
  },
  FOULS_SHORT: {
    en: 'FOUL',
    nl: 'FOUL'
  },
  OFFSIDES_SHORT: {
    en: 'OFFS',
    nl: 'BS'
  },
  Open: {
    nl: 'Open'
  },

  S11_GROUP_SUBTITLE: {
    en: x => `Welcome to the "${x.toUpperCase()}" section of Super11`,
    nl: x => `Welkom bij het "${x.toUpperCase()}" gedeelte van Super11`
  },
  S11_GROUP_TEXT: {
    en: x =>
      `As a Super11 member you can player the ${x.toUpperCase()} game. However this is not required to see all the stats, check live matches, scout player and much more`,
    nl: x =>
      `Als Super11 lid kun jij hier nu meespelen met het ${x.toUpperCase()} spel. Dat hoeft echter niet. Je kunt ook zonder mee te spelen alle statistieken inlezen, live wedstrijden volgen, spelers bekijken en nog veel meer.`
  },
  S11_GROUP_WARNING: {
    en: 'Pay attention: When you are looking for a group or want to create your own group, close this popup and search or create a group.',
    nl: 'Let op: Wanneer je een groep zoekt of wilt aanmaken, sluit dan deze popup en zoek of maak een groep.'
  },
  'No predictions are made for this fixture': {
    nl: 'Er zijn geen voorspellingen gedaan voor deze wedstrijd'
  },
  'This game had no members': {
    nl: 'Dit spel had geen deelnemers'
  },
  'This game has no members': {
    nl: 'Dit spel heeft geen deelnemers'
  },
  SUPER11_APP_DESC: {
    en: 'The Super11 App is a Progressive Web App (PWA). Do you want to know how to install a PWA without having to do any extra downloads? Search on Google for: "how to install a PWA on my iPhone". The Super11 app also works on computers, both Windows and MacOS, Android smartphones and even your smartfridge. :) In the future we will be posting instructions here.',
    nl: 'De Super11 App is een Progressive Web App (PWA). Wil je weten hoe je een PWA kunt installeren zonder extra downloads te hoeven doen? Zoek dan op Google naar bijvoorbeeld: "hoe installeer ik een PWA op mijn iPhone". De Super11 app werkt ook op Windows en MacOS computers, Android smartphones en zelfs op je smartfridge. :) In de toekomst zullen wij hier instructies plaatsen.'
  },
  'Play along with our community': {
    nl: 'Speel mee met onze community'
  },
  'All Super11 members can play': {
    nl: 'Alle Super11 leden kunnen meespelen'
  },
  LATE_JOIN_WELCOME_TEXT: {
    en: x =>
      `Welcome to the ${x} game of Super11. In this competition all member of Super11 are welcome to play.`,
    nl: x =>
      `Welkom bij het ${x} spel van Super11. In deze competitie zijn alle leden van Super11 welkom om mee te spelen.`
  },
  LATE_JOIN_WARNING_TEXT: {
    en: 'Pay attention: This is not a personal group. You can also create your own group with your friends, colleagues, organization (etc.). Go back to the lobby to create your own group or look for another group.',
    nl: 'Let op: Dit is geen persoonlijk aangemaakte groep. Je kunt ook met jouw vriendengroep, vereniging, bedrijf (etc.) een eigen groep aanmaken en competities spelen. Ga hiervoor terug naar de lobby.'
  },
  'Should members be able to join the game when the competition is already live?':
    {
      nl: 'Moeten leden het spel kunnen joinen terwijl de competitie live is?'
    },

  'Should members be able to purchase a new team?': {
    nl: 'Kunnen leden een nieuw team kopen?'
  },

  'Complete your team first': {
    nl: 'Maak eerst je team compleet'
  },
  'Insufficient Super11 credits': {
    nl: 'Niet genoeg Super11 credits'
  },
  Average: {
    nl: 'Gemiddeld'
  },
  'Total points': {
    nl: 'Punten totaal'
  },
  'Average score': {
    nl: 'Gemiddelde score'
  },
  'Edit avatar': {
    nl: 'Avatar aanpassen'
  },
  Reloaded: {
    nl: 'Opnieuw geladen'
  },
  Setup: {
    nl: 'Systeem'
  },
  Display: {
    nl: 'Weergave'
  },

  Field: { nl: 'Veld' },

  'This shows if the admin(s) of the group has/have verified a member. If a member is not verified by the time this game begins, the member will be automatically removed from this game and be refunded any paid credits.':
    {
      nl: 'Dit laat zien of de admin(s) een deelnemer heeft/hebben goedgekeurd. Als een deelnemer niet is goedgekeurd op het moment dat dit spel begint, dan zal de deelnemer automatisch uit het spel verwijderd worden en eventuele betaalde credits terug krijgen.'
    },

  'This shows if a user has completed their Super11 team.': {
    nl: 'Dit laat zien of een deelnemer zijn Super11 team compleet heeft gemaakt.'
  },

  'This shows if a user has filled in all the required predictions for the start of this game.':
    {
      nl: 'Dit laat zien of een gebruiker alle nodige voorspellingen voor de start van dit spel heeft ingevuld.'
    },

  'You can register yourself for this game in the middle of your screen.': {
    nl: 'Je kunt jezelf inschrijven voor dit spel in het midden van je scherm.'
  },

  'You are playing this game': {
    nl: 'Je speelt dit spel'
  },

  Online: {
    nl: 'Online'
  },

  Game: {
    nl: 'Spel'
  },

  'Active member': {
    nl: 'Actief lid'
  },
  'inactive game': {
    nl: 'inactief spel'
  },
  'inactive games': {
    nl: 'inactieve spellen'
  },
  'You played this game': {
    nl: 'Je speelde dit spel'
  },
  'You did not play this game': {
    nl: 'Je speelde dit spel niet'
  },
  Status: {
    nl: 'Status'
  },

  'Live commentary will appear here when a match is live!': {
    nl: 'Hier komt live commentaar zodra een wedstrijd live is!'
  },

  'This is your groups overview. You can create a group yourself or search for a group. In a group unique football games are organized':
    {
      nl: 'Hier is jouw groepen overzicht. Je kunt zelf een groep aanmaken of een groep zoeken. Binnen een groep worden unieke voetbalspellen georganiseerd.'
    },

  'Make sure that you have chosen 11 players. You can choose your captain by pressing on one of your chosen players.':
    {
      nl: 'Zorg er voor dat je 11 spelers hebt gekozen. Je kunt je aanvoerder kiezen door op een van je gekozen spelers te drukken.'
    },

  'If you are not accepted by an admin of this group at the moment this game starts, you will be automatically removed from this game. You will be refunded any credits that you might have paid. You can see who has been accepted by an admin of this group in the standings list.':
    {
      nl: 'Als jij niet bent goedgekeurd door een admin van deze groep, op het moment dat dit spel begint dan zal je automatisch uit het spel verwijderd worden en eventuele betaalde credits terug krijgen. Je kunt zien wie er zijn goedgekeurd door de admin in de lijst met standen.'
    },

  'Group shop': {
    nl: 'Groep shop'
  },
  'Free spot': {
    nl: 'Vrije plek'
  },
  'Free spots': {
    nl: 'Vrije plekken'
  },

  FREE_SPOTS_GROUP_JOIN_DESC: {
    en: x =>
      `There are free spots available for this game. This means that you do not have to pay any Super11 Credits to play this game because the admin(s) of this ${x} paid for you.`,
    nl: x =>
      `Er zijn gratis plekken beschikbaar voor dit spel. Jij hoeft geen Super11 Credits te betalen om dit spel te spelen, de admin(s) van deze ${x} hebben dat voor je gedaan.`
  },

  'When you buy Free Spots for this game, members of this group will not have to pay Super11 Credits to play this game.':
    {
      nl: 'Waneer je Vrije Plekken koopt voor dit spel zullen leden van deze groep geen Super11 Credits hoeven te betalen om dit spel te spelen.'
    },

  'Free Spot for your Super11 Game': {
    nl: 'Vrije Plek voor jouw Super11 Spel'
  },

  'Free Spots for your Super11 Game': {
    nl: 'Vrije Plekken voor jouw Super11 Spel'
  },

  Store: {
    nl: 'Winkel'
  },

  'Purchase spots': {
    nl: 'Vrije plekken kopen'
  },

  'Are you sure?': {
    nl: 'Weet je het zeker?'
  },

  Settings: {
    nl: 'Instellingen'
  },

  'This link will be shown as a button together with the game message, i.e. for a payment request.':
    {
      nl: 'Deze link zal worden weergegeven als een knop, onder het spel bericht, bijvoorbeeld voor een betaalverzoek.'
    },

  Link: {
    nl: 'Link'
  },

  Text: {
    nl: 'Tekst'
  },
  'Play along': {
    nl: 'Speel mee'
  },
  'All members of this group can play': {
    nl: 'Alle leden van deze groep kunnen meespelen'
  },
  'You have to unlock this game with 3 credits first': {
    nl: 'Dit spel moet je eerst unlocken met 3 credits'
  },
  'There are free spots available': {
    nl: 'Er zijn vrije plekken beschikbaar'
  },
  'Open external link?': {
    nl: 'Externe link openenen?'
  },
  'Are you sure you want to open the following link?': {
    nl: 'Weet je zeker dat je de volgende link wilt openen?'
  },

  'Share with this user': {
    nl: 'Deel met deze gebruiker'
  },

  'Super11 is busy to prepare this competition': {
    nl: 'Super11 is druk bezig om deze competitie gereed te maken'
  },

  'Wait a little longer': {
    nl: 'Nog even geduld'
  },
  'We expect this competition to be playable at': {
    nl: 'Wij verwachten deze competitie speelklaar te hebben op'
  },
  'Get notified when this game is ready': {
    nl: 'Ontvang bericht zodra dit spel gereed is'
  },

  LFA_TITLE: {
    en: x => `Super11 is looking for an admin for the ${x}`,
    nl: x => `Super11 zoekt een admin voor de ${x}`
  },
  LFA_TEXT: {
    en: x =>
      `Super11 needs admins for every competition. Are you a fan of the ${x} and do you think it might be fun to work with Super11? Then please press the button below and fill in the form so that we can get in contact with you.`,
    nl: x =>
      `Super11 heeft voor iedere competitie admins nodig. Ben jij een liefhebber van de ${x} en lijkt het je leuk om samen te werken met Super11? Druk op de knop hier beneden en vul het formulier in zodat we contact met je kunnen opnemen.`
  },
  'Your email': {
    nl: 'Je e-mail'
  },
  'Invite others': {
    nl: 'Nodig anderen uit'
  },
  Manage: {
    nl: 'Beheer'
  },

  'Active game': {
    nl: 'Actief spel'
  },
  'Active games': {
    nl: 'Actieve spellen'
  },
  'Finished game': {
    nl: 'Afgelopen spel'
  },
  'Finished games': {
    nl: 'Afgelopen spellen'
  },

  'Check finished games': {
    nl: 'Bekijk afgelopen spellen'
  },

  'Leave group': {
    nl: 'Verlaat groep'
  },

  'Group page': {
    nl: 'Groepspagina'
  },
  'Or contact the Super11 team instead': {
    nl: 'Of neem contact op met het Super11 team'
  },
  'Because of live games you cannot leave this group now': {
    nl: 'Omdat er live games zijn kun je deze groep nu niet verlaten'
  },
  Info: {
    nl: 'Info'
  },
  Captain: {
    nl: 'Captain'
  },
  Subs: {
    nl: 'Subs'
  },

  Of: {
    nl: 'Van'
  },
  'Help desk': {
    nl: 'Helpdesk'
  },

  'Play the most fun': {
    nl: 'Speel bij ons de leukste'
  },
  'live football games with us': {
    nl: 'live voetbalspellen'
  },
  'Open Super11 App': {
    nl: 'Open Super11 App'
  },
  'You can use the Super11 app without an account. In order to play games you need to create an account easily.':
    {
      nl: 'Je kunt de Super11 app zonder account gebruiken. Om mee te spelen met een spel is wel een account nodig. Maar dit is later eenvoudig aan te maken.'
    },
  'Invite your friends': {
    nl: 'Nodig je vrienden uit'
  },
  'Super11 is The football app': {
    nl: 'Super11 is dé voetbal app'
  },
  'which makes it possible to organize football games based on real-time football data. This way the Eredivisie becomes even more fun to follow. But of course the most fun is to play with your Super11 team during the European Championship or the World Cup!':
    {
      nl: 'die het mogelijk maakt om met elkaar voetbalspellen te organiseren gebasseerd op real-time voetbaldata. Zo wordt de Eredivisie nog leuker om te volgen. Maar het allerleukste is natuurlijk om met je Super11 team te spelen tijdens het EK of WK!'
    },
  'Play Super11 with your favorite competitions': {
    nl: 'Speel Super11 met je favoriete competities'
  },
  'Sign up': {
    nl: 'Meld je aan'
  },
  'The football app for everybody': {
    nl: 'De voetbal app voor iedereen'
  },
  'Real-time Data': {
    nl: 'Real-time Data'
  },
  'What can we help you with?': {
    nl: 'Waarmee kunnen we je helpen?'
  },
  'Create account': {
    nl: 'Account aanmaken'
  },
  'Your own group here': {
    nl: 'Je eigen groep hier'
  },
  'Create your own group on Super11 with your friends, family, company, sport club (etc.) to organize your own games. For this you need an account.':
    {
      nl: 'Maak een eigen groep op Super11 met jouw vrienden, familie, bedrijf, sportvereniging (etc.) om eigen spellen te kunnen organiseren. Hiervoor heb je een account nodig.'
    },
  'Live commentary will be shown here': {
    nl: 'Live commentaar kun je hier zien'
  },
  'Open app': {
    nl: 'Open app'
  },
  'You need to create an account first.': {
    nl: 'Je moet eerst een account maken.'
  },
  'Looking for admins': {
    nl: 'Op zoek naar admins'
  },
  'T.b.d.': {
    nl: 'N.t.b.'
  },
  'Be the first to write a message': {
    nl: 'Wees de eerste die een bericht schrijft'
  },
  'Buy new team': {
    nl: 'Koop nieuw team'
  },
  'Current password': {
    nl: 'Huidige wachtwoord'
  },
  'Choose a unique name for your group': {
    nl: 'Kies een unieke naam voor je groep'
  },
  'Your group is visible for everyone and every Super11 user kan join your group.':
    {
      nl: 'Jouw groep is voor iedereen zichtbaar en elke Super11 gebruiker kan lid worden van jouw groep.'
    },
  'Nice, you are about to create a new Super11 group. This group is your own football community. All members of your group can play the unique football games that you organize within this group.':
    {
      nl: 'Wat leuk dat je een nieuwe Super11 groep gaat aanmaken. Deze groep is jouw eigen voetbal community. Alle leden van jouw groep kunnen meespelen met de unieke voetbalspellen die jij organiseert binnen deze groep.'
    },
  'Partner spots': {
    nl: 'Partner plekken'
  },
  'Purchase partner spots': {
    nl: 'Partner plekken kopen'
  },
  'Partner spot': {
    nl: 'Partner plek'
  },
  'Edit partner': {
    nl: 'Partner aanpassen'
  },
  'New logo': {
    nl: 'Nieuw logo'
  },
  'Group created': {
    nl: 'Groep aangemaakt'
  },

  'Create game': {
    nl: 'Spel aanmaken'
  },
  'Game created': {
    nl: 'Spel aangemaakt'
  },
  'No game': {
    nl: 'Geen spel'
  },
  'Choose a starting round': {
    nl: 'Kies een startronde'
  },
  'Choose which game elements you want to play': {
    nl: 'Kies welke spel elementen je wilt spelen'
  },
  'Add scores in total score': {
    nl: 'Puntentelling samenvoegen in totaalscore'
  },
  'When checking this checkbox the scores of the "Super11 game" and the "predictions game" will also be added together as a total score and shown as an extra(!) "total" tab.':
    {
      nl: 'Bij aanvinken worden behaalde punten van het "Super11 spel" en het "voorspellingen spel" ook samengevoegd als een totaalscore en extra(!) weergegeven in een aparte "totaal" tab.'
    },
  'Coming soon': {
    nl: 'Binnenkort beschikbaar'
  },
  'Game rules in': {
    nl: 'Spelregels in'
  },
  'Members can': {
    nl: 'Leden kunnen'
  },
  'Join later': {
    nl: 'Later inschrijven'
  },
  'New members are allowed to join the game whilst it has already started.': {
    nl: 'Nieuwe leden mogen tijdens het spel inschrijven. Ook al is het spel reeds gestart.'
  },
  'Members have the ability to reset their theam by buying a new team.': {
    nl: 'Leden hebben de mogelijkheid om hun team te vernieuwen door een nieuw team te kopen.'
  },
  'Members have the option to reset their team for the next round by purchasing a new team with Super11 credits.':
    {
      nl: 'Deelnemers hebben de mogelijkheid om voor de volgende speelronde hun team te vernieuwen door een nieuw team te kopen met Super11 credits.'
    },
  'Buy a new team': {
    nl: 'Nieuw team kopen'
  },

  '11 players of 11 different clubs/countries': {
    nl: '11 spelers van 11 verschillende clubs/landen'
  },
  'Captain activated (scores double points)': {
    nl: 'Aanvoerder geactiveerd (pakt dubbele punten)'
  },
  'Standard Super11 scoring': {
    nl: 'Standaard Super11 puntentelling'
  },
  '3 substitutions per round': {
    nl: '3 keer wisselen per speelronde'
  },
  '-6 points for one substitude': {
    nl: '-6 punten voor 1 wissel'
  },
  'Inactive players can be substituted for free': {
    nl: 'Inactieve spelers kunnen gratis worden gewisseld'
  },
  'Predictions including possible extra time': {
    nl: 'Voorspellingen tot en met eventuele verlenging'
  },
  'Standard predictions scoring': {
    nl: 'Standaard voorspellingen puntentelling'
  },
  'Automatically confirm users': {
    nl: 'Leden automatisch goedkeuren'
  },
  'Members which have not been confirmed will be automatically removed when the game starts (and will be refunded any credits paid).':
    {
      nl: 'Deelnemers die niet zijn goedgekeurd worden automatisch verwijderd zodra het spel is gestart (en krijgen eventuele credits terug betaald).'
    },
  'Welcome message (max 300 characters)': {
    nl: 'Welkomsbericht (max 300 karakters)'
  },
  'For example let members know what they should do to be confirmed so that they are sure they will play your game.':
    {
      nl: 'Laat bijvoorbeeld weten wat deelnemers moeten doen om door jou goedgekeurd te worden zodat ze sowieso mee gaan spelen met jouw spel.'
    },
  Button: {
    nl: 'Knop'
  },
  'Button link': {
    nl: 'Knop link'
  },
  'Optionally enter a link here (i.e. https://www.super11.nl)': {
    nl: 'Vul hier eventueel een link in (bv: https://www.super11.nl)'
  },
  'Button text': {
    nl: 'Knop tekst'
  },
  'I.e. Payment request': {
    nl: 'Bv: Betaalverzoek'
  },
  League: {
    nl: 'League'
  },
  'Super11 and Predictions': {
    nl: 'Super11 en Voorspellingen'
  },
  'Starting round': {
    nl: 'Startronde'
  },
  Standard: {
    nl: 'Standaard'
  },
  Options: {
    nl: 'Opties'
  },
  'Late join': {
    nl: 'Later aansluiten '
  },
  'Automatically confirm': {
    nl: 'Automatisch goedkeuren'
  },
  'After you have created this game you will have many more options in the admin panel to make this game even more fun. For example you can create a prize pool or activate free member spots so that members can join for free.':
    {
      nl: 'Nadat je dit spel hebt aangemaakt heb je nog veel meer opties in de admin om dit spel nog leuker te maken. Je kunt bijvoorbeeld een prijzenpot maken of gratis deelname spots activeren zodat deelnemers gratis mee kunnen spelen.'
    },
  'I have read and filled in everything correctly.': {
    nl: 'Ik heb alles gelezen en goed ingevuld'
  },
  'This game starts in round': {
    nl: 'Dit spel begint in speelronde'
  },
  'Contact Super11 to do this': {
    nl: 'Neem contact op met Super11 om dit te doen'
  },
  Custom: {
    nl: 'Custom'
  },
  Unavailable: {
    nl: 'Niet beschikbaar'
  },
  'This game was created by the group owner without a "predictions game"': {
    nl: 'Dit spel is aangemaakt door de groeps eigenaar zonder een "voorspel spel"'
  },
  'This game was created by the group owner without a "total standings"': {
    nl: 'Dit spel is aangemaakt door de groeps eigenaar zonder een "totaal stand"'
  },
  'This game was created by the group owner without a "Super11 (team) game"': {
    nl: 'Dit spel is aangemaakt door de groeps eigenaar zonder een "Super11 (team) spel"'
  },
  Final: {
    en: 'Finals',
    nl: 'Finales'
  },
  '3rd Place Final': {
    nl: '3e Plaats Finale'
  },
  'Semi-finals': {
    nl: 'Halve Finales'
  },
  'Quarter-finals': {
    nl: 'Kwart Finales'
  },
  '8th Finals': {
    nl: '8e Finales'
  },
  Start: {
    nl: 'Start'
  },
  AvailableSinglular: {
    en: 'Available',
    nl: 'Beschikbaar'
  },
  'Game already in this group': {
    nl: 'Spel zit al in deze groep'
  },
  unavailable: {
    nl: 'niet beschikbaar'
  },
  'Go to the home page to create your own group!': {
    nl: 'Ga naar de home pagina om je eigen groep te maken!'
  },

  "Choose which competition you want to use for this new game. Only active competitions are available. Don't see a competition you want to start a game with? Maybe we are not yet hosting this competition on Super11 or it's not available yet. You can always contact us about options for us to make this league available. Go to the contact center to do this.":
    {
      nl: 'Kies hier welke voetbalcompetitie je wilt gebruiken voor dit nieuwe spel. Alleen actieve competities zijn beschikbaar. Staat de competitie er niet tussen waarmee je een spel wilt opstarten? Waarschijnlijk hosten wij deze competitie nog niet op Super11 of is deze nog niet beschikbaar. Je kunt altijd informeren naar de mogelijkheden om deze competitie beschikbaar door ons te laten maken. Ga hiervoor naar ons contactcenter.'
    },

  'Choose which game elements you want to offer with this new game. There are two game elements available for this game: "Super11" and the "Predictions" game. Super11 is the game where members have to create their own team with 11 players of this league. The predict game consists of predicting matches/results/questions. You can also select both game elements so that they can be both played at the same with the same members.':
    {
      nl: `Kies hier welke spelelementen je wilt aanbieden voor dit nieuwe spel. Er zijn voor dit spel 2 spelelementen beschikbaar: "Super11" en het "Voorspellingen spel". Super11 is het spel waarin deelnemers een team moeten samenstellen van 11 spelers uit deze competitie. Het voorspellingen spel bestaat wedstrijden/uitslagen/vragen voorspellen. Je kunt ook beide spelelementen selecteren zodat beide spellen naast elkaar worden gespeeld met dezelfde deelnemers.`
    },

  'This text is what users see before they join your game. For example let members know what they should do to be confirmed so that they are sure they will play your game.':
    {
      nl: 'Deze tekst zullen Super11 gebruikers zien voordat ze zich aansluiten bij jouw groep. Dit kan bijvoorbeeld een leuk welkomsbericht zijn of een beschrijving van jouw groep.'
    },
  'Only fill in a complete link to a website, i.e. https://www.super11.nl. You can leave this field empty if you do not want to create a button.':
    {
      nl: 'Vul hier alleen een volledige website url in zoals https://www.super11.nl. Je kunt dit veld ook leeg laten als je geen button wilt maken.'
    },
  'Create a name for the button. Suggestions: "Click here", "website", "payment request", "information". You can also leave this field empty.':
    {
      nl: 'Creëer hier een naam voor de button. Suggesties: "klik hier", "website", "betaalverzoek", "informatie". Je kunt dit veld ook leeg laten.'
    },
  'Add spot': {
    nl: 'Voeg plek toe'
  },
  'Join now': {
    nl: 'Doe nu mee'
  },
  'You can join for free': {
    nl: 'Je kunt gratis meespelen'
  },
  Free: {
    nl: 'Gratis'
  },
  Spots: {
    nl: 'Plekken'
  },
  'There are x free spots available': {
    en: x => `There are ${x} free spots available`,
    nl: x => `Er zijn ${x} gratis plekken beschikbaar`
  },
  'Custom prize pool': {
    nl: 'Custom prijzenpot'
  },
  'Create your own custom prize pool with custom prizes': {
    nl: 'Maak je eigen custom prijzenpot aan met custom prijzen'
  },
  'Custom Prize Pool': {
    nl: 'Custom prijzenpot'
  },
  'Purchase custom prize pool': {
    nl: 'Custom prijzenpot kopen'
  },
  Purchased: {
    nl: 'Gekocht'
  },
  'This feature has to be purchased first': {
    nl: 'Deze functie moet je eerst kopen'
  },
  'Go to the store': {
    nl: 'Ga naar de winkel'
  },
  'These partners/sponsors will be shown in the standings of your game. We always strive to check/confirm new partners within 24 hours.':
    {
      nl: 'Deze partners/sponsors zullen worden weergegeven in de standen van je game. We proberen een nieuwe partner altijd binnen 24 uur te checken/keuren.'
    },
  'Game rules and info': {
    nl: 'Spelregels en info'
  },
  'Are you sure you want to buy a new team? This will cost you 3 credits': {
    nl: 'Weet je zeker dat je een nieuw team wilt kopen? Dit kost 3 credits'
  },

  'Cannot buy freeSubs for round.i 0': {
    en: 'You cannot buy a new team yet',
    nl: 'Je kunt nog geen nieuw team kopen'
  },
  'Add database': {
    nl: 'Voeg database toe'
  },
  'Write an optional message for new members': {
    nl: 'Schrijf eventueel een bericht voor nieuwe leden'
  },
  Message: {
    nl: 'Bericht'
  },
  'Show completed games': {
    nl: 'Toon afgelopen spellen'
  },
  'Start new game': {
    nl: 'Start nieuw spel'
  },
  'Completed games': {
    nl: 'Afgelopen spellen'
  },

  'With this you create your own web link for your group.': {
    nl: 'Hiermee creëer je een eigen weblink voor jouw groep.'
  },
  Visibility: {
    nl: 'Zichtbaarheid'
  },
  'Contact email address': {
    nl: 'Contact e-mailadres'
  },
  'Edit logo': {
    nl: 'Logo aanpassen'
  },
  'Hide group owner': {
    nl: 'Groepseigenaar verbergen'
  },
  'Hide group in lobby': {
    nl: 'Groep verbergen in lobby'
  },
  'Then your group cannot be seen or found by Super11 users in the Super11 lobby.':
    {
      nl: 'Dan is jouw groep niet zichtbaar of vindbaar in de Super11 lobby voor Super11 gebruikers.'
    },
  Hidden: {
    nl: 'Verborgen'
  },
  'Buy spots': {
    nl: 'Plekken kopen'
  },
  'As the organizer of this game you have the option to offer your members free spots. This way members can play this game for free. Without free spots members have to pay 3 Super11 credits to play.':
    {
      nl: 'Als organisator van dit spel heb jij de mogelijkheid om gratis plekken aan te bieden voor deelnemers. Zo kunnen deelnemers gratis meespelen met dit spel. Zonder gratis spots moeten deelnemers 3 Super11 credits betalen om mee te kunnen spelen.'
    },

  Balance: {
    nl: 'Saldo'
  },
  Currency: {
    nl: 'Valuta'
  },
  'Activate standard prize pool': {
    nl: 'Activeer standaard prijzenpot'
  },
  'Activate custom prize pool': {
    nl: 'Activeer custom prijzenpot'
  },
  'Prizes will be automatically rounded to full numbers in our calculator.': {
    nl: 'Prijzen worden in onze calculator automatisch afgerond op hele bedragen.'
  },
  'Play with a prize pool for this game': {
    nl: 'Speel met een prijzenpot voor dit spel'
  },
  'Rounded numbers': {
    nl: 'Afgeronde bedragen'
  },
  'inlay (1 member)': {
    nl: 'inleg (1 lid)'
  },
  'extra inlay': {
    nl: 'extra inleg'
  },
  Percentage: {
    nl: 'Percentage'
  },

  'Play with a standard prize pool for this game': {
    nl: 'Speel met een standaard prijzenpot voor dit spel'
  },
  'Play with a custom prize pool for this game': {
    nl: 'Speel met een custom prijzenpot voor dit spel'
  },
  CUSTOM_PRIZE_POOL_INTRO: {
    en: x =>
      `Here you can create a custom prize pool for the ${x} game. You can choose prizes per game element and positions.`,
    nl: x =>
      `Maak hier een custom prijzenpot voor this ${x} spel. Je kunt per spelelement zelf prijzen toewijzen aan posities.   `
  },
  'Remove prize?': {
    nl: 'Verwijder prijs?'
  },
  'Same for prizes every round': {
    nl: 'Zelfde prijzen voor elke ronde'
  },
  'Sure? All round prizes for this category will be lost!': {
    nl: 'Weet je het zeker? Alles speelronde prijzen voor deze categorie gaan weg!'
  },
  'Sure? All prizes for this category will be lost!': {
    nl: 'Weet je het zeker? Alles prijzen voor deze categorie gaan weg!'
  },
  'There already is a custom prize pool for this game. By activating the standard prize pool the custom prize pool data will be removed!':
    {
      nl: 'Er is al een custom prijzenpot voor dit spel. Door de standaard prijzenpot te activeren zal de custom prijzenpot data verwijderd worden!'
    },
  'There already is a standard prize pool for this game. By activating the custom prize pool the standard prize pool data will be removed!':
    {
      nl: 'Er is al een standaard prijzenpot voor dit spel. Door de custom prijzenpot te activeren zal de standaard prijzenpot data verwijderd worden!'
    },
  'You will be redirected to your group page.': {
    nl: 'Je zult worden doorverwezen naar je groepspagina.'
  },
  'Create new game': {
    nl: 'Maak nieuw spel'
  },
  'Invite link': {
    nl: 'Uitnodig link'
  },
  'This link you can share with people so that they can join your group easily.':
    {
      nl: 'Deze kun je delen met mensen zodat ze zich kunnen aanmelden voor deze groep.'
    },
  Lobby: {
    nl: 'Lobby'
  },

  'You can send this to people by pasting it for example in WhatsApp or an email.':
    {
      nl: 'Deze kun je nu direct versturen naar mensen door hem te plakken in bijvoorbeeld WhatsApp of e-mail.'
    },

  'Use the tabs above to view which prizes are available.': {
    nl: 'Gebruik de tabs hierboven om te zien welke prijzen er zijn.'
  },
  Page: {
    nl: 'Pagina'
  },
  'The prizes for this category will be removed!': {
    nl: 'De prijzen voor deze categorie zullen worden verwijderd!'
  },
  Participants: {
    nl: 'Deelnemers'
  },
  'Standard game rules': {
    nl: 'Standaard spelregels'
  },
  'This explanation will be here soon.': {
    nl: 'Deze uitleg zal er binnenkort zijn.'
  },
  'Open admin': {
    nl: 'Open admin'
  },
  'Nothing found. Try to use different words.': {
    nl: 'Niks gevonden. Probeer andere woorden.'
  },
  'This info is about the mobile version': {
    nl: 'Deze info gaat over de mobiele versie'
  },
  'This info is about the desktop version': {
    nl: 'Deze info gaat over de desktop versie'
  },

  'You can only change your prediction database for not started games': {
    nl: 'Je kunt alleen je voorspel database aanpassen voor spellen die nog niet zijn gestart'
  },
  'Group message': {
    nl: 'Groepsbericht'
  },
  'Super11 leagues are open to all users. Play along with the Super11 leagues and/or follow the competitions and check (live) matches, rankings and stats.':
    {
      nl: 'Super11 leagues zijn voor alle gebruikers toegankelijk. Speel mee met de Super11 leagues en/of volg de betreffende competities en bekijk (live) wedstrijden, ranglijsten en statistieken.'
    },

  'Maybe you created an account with Facebook/Google? If so you have to log in using the Facebook/Google button.':
    {
      nl: 'Misschien heb je een account met Facebook/Google aangemaakt? Zo ja, dan moet je met de Facebook/Google knoppen inloggen.'
    },

  editShort: {
    nl: 'Bewerk',
    en: 'Edit'
  },
  'Partner Spot': {
    nl: 'Partner plek'
  },
  'Current logo': {
    nl: 'Huidige logo'
  },

  'Are you sure your email is written correctly? Go back to try again.': {
    nl: 'Weet je zeker dat er geen typfouten zitten in jouw e-mailadres? Ga terug om het opnieuw te proberen.'
  },

  'Unfortunately you are not logged in so you cannot inspect Super11 users.': {
    nl: 'Je bent helaas niet ingelogd dus kun je nu geen Super11 gebruikers bekijken.'
  },

  'Press on a group member to be able to make them an admin of this group.': {
    nl: 'Klik op een groepslid om deze admin van deze groep te kunnen maken.'
  },

  'Close app': {
    nl: 'Sluit app'
  },

  'Group Phase': {
    nl: 'Poulefase'
  },

  'The theme will be reflected when you are back in the app.': {
    nl: 'Het thema zul je zien zodra je weer in de app zit.'
  },

  'Are you sure you want to change the confirmation status?': {
    nl: 'Weet je zeker dat je de bevestigings status wilt aanpassen?'
  },

  'No leagues available': {
    nl: 'Geen leagues beschikbaar'
  },

  'This group does not have a chat enabled, an admin/owner of this group can do this.':
    {
      nl: 'Deze groep heeft geen chat ingeschakeld, een admin/eigenaar van deze groep kan dit doen.'
    },
  'No active games': {
    nl: 'Geen actieve spellen'
  },
  'Button example': {
    nl: 'Knop voorbeeld'
  },
  'Save button': {
    nl: 'Knop opslaan'
  }
};

export default translations;
