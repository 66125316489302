import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function TrophyIcon(props) {
  return (
    <SvgIcon style={{ position: 'relative', top: 4 }} {...props}>
      <path d="M20.9879 2.89617C20.8964 2.51684 20.5554 2.25 20.1652 2.25H16.6214C16.63 1.8123 16.6252 1.4168 16.616 1.08914C16.6004 0.47918 16.1046 0 15.4929 0H6.50699C5.89527 0 5.39957 0.47918 5.38199 1.08914C5.34332 1.4168 5.36793 1.8123 5.37848 2.25H1.83332C1.44309 2.25 1.10478 2.51684 1.01249 2.89617C0.983945 3.0157 0.322656 5.87812 2.1782 8.64492C3.49445 10.6073 5.71492 11.9858 8.77 12.7723C9.42742 12.9412 10.1559 13.5538 10.1559 14.2327C10.1559 14.2324 10.1559 14.2329 10.1559 14.2327L10.1562 16.3125H7.34371C6.87789 16.3125 6.5316 16.6904 6.5316 17.1562C6.5316 17.6221 6.87965 18 7.34371 18H14.6246C15.0904 18 15.4367 17.6221 15.4367 17.1562C15.4367 16.6904 15.0587 16.3125 14.6246 16.3125H11.8437V14.2298C11.8448 13.5517 12.5721 12.9403 13.2292 12.7716C16.2864 11.9862 18.5062 10.6077 19.821 8.64422C21.6769 5.87812 21.016 3.0157 20.9879 2.89617ZM3.59641 7.72734C2.61414 6.27891 2.52766 4.7707 2.57582 3.9375H5.40203C5.59043 6.03352 6.11746 8.54648 7.42949 10.5855C5.70543 9.9 4.42223 8.94375 3.59641 7.72734ZM11 12.375C9.04176 10.1848 7.05191 8.11758 7.06246 1.6875H14.9058C14.948 8.11406 12.9582 10.1812 11 12.375ZM18.4039 7.72734C17.5798 8.94375 16.2966 9.89824 14.5718 10.5827C15.8832 8.54648 16.4105 6.03281 16.5968 3.9375H19.423C19.441 4.7707 19.3847 6.28242 18.4039 7.72734Z" />
    </SvgIcon>
  );
}
