import React, { useEffect, Suspense, lazy } from 'react';
import { ThemeProvider, Button } from '@material-ui/core';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

import ErrorBoundary from 'Components/ErrorBoundary.js';
import NotFound from 'Components/Routes/NotFound.js';
import themes from 'themes.js';
import routes from 'routes.js';
import UpdateDialog from './UpdateDialog';

import useUnderConstruction from 'hooks/useUnderConstruction';

import bottomNavExcludes from 'bottomNavExcludes';
import useIsTablet from 'hooks/useIsTablet';
import getScrollbarWidth from 'getScrollBarWidth';
import { useThrottledCallback } from 'use-debounce';

const UnderConstruction = lazy(() => import('./UnderConstruction'));

function makeRouteFromRouteObj({ path, exact, component }) {
  return <Route key={path} exact={exact} path={path} component={component} />;
}

function PureRouter({ parentProps }) {
  const setScrollY = useStoreActions(a => a.setScrollY);
  const scrollHandler = useThrottledCallback(y => setScrollY(y), 100);

  return (
    <BrowserRouter basename='/'>
      <div
        className='App'
        onScroll={evt => {
          scrollHandler(evt.target.scrollTop);
        }}
      >
        {parentProps.before}
        <RouterSwitch>
          {routes.map((route, ri) => makeRouteFromRouteObj(route))}

          {routes.map((route, ri) => {
            const childRoutes = [];
            route.children &&
              route.children.map(child => childRoutes.push(child));
            return childRoutes.map((child, ri) =>
              makeRouteFromRouteObj({
                ...child,
                path: route.path + child.path
              })
            );
          })}

          <Route component={NotFound} />
        </RouterSwitch>
        {parentProps.after}
      </div>
    </BrowserRouter>
  );
}

const snackbarsRootStyle = document.getElementById('snackbarsRootStyle');

/**
 * Used to wrap around the APP CONTENT
 * Note that we're not including the app store, check index.js for this.
 */
export default function AppWrapper(props) {
  const {
    refetch: refetchUnderConstruction,
    underConstruction,
    loading: loadingUnderConstruction,
    error: errorUnderConstruction
  } = useUnderConstruction();

  const route = useStoreState(s => s.route);

  let themeNameToUse = useStoreState(s => s.theme);

  if (route?.isContentPage) themeNameToUse = 'dark';

  const themeToUse = themes[themeNameToUse];
  const isTablet = useIsTablet();

  document.body.style.background = themeToUse.palette.background.body;

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    if (route) {
      if (bottomNavExcludes.includes(route.path)) {
        snackbarsRootStyle.innerHTML =
          ' .snackbarsRoot { bottom: 14px !important; }' +
          '.App { padding-bottom: 0px !important; }';
      } else {
        if (isTablet) {
          snackbarsRootStyle.innerHTML =
            ' .snackbarsRoot { bottom: 14px !important; }' +
            '.App { padding-bottom: 20px !important; }';
        } else {
          snackbarsRootStyle.innerHTML =
            ' .snackbarsRoot { bottom: 64px !important; }' +
            '.App { padding-bottom: 86px !important; }';
        }
      }

      if (
        route.path !== '/' &&
        route.path !== '/register' &&
        route.path !== '/about'
      ) {
        // snackbarsRootStyle.innerHTML += `
        // .react-swipeable-view-container {
        //   transition: none !important;
        // }
        // `;
      } else {
        snackbarsRootStyle.innerHTML += `
        div.react-swipeable-view-container {
          transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) !important;
        }
        `;
      }
    }

    if (isTablet) {
      if (themeToUse.palette.type === 'dark') {
        snackbarsRootStyle.innerHTML += `
        html {
          --scrollbarBG: ${themeToUse.palette.background.paperDark};
          --thumbBG: ${themeToUse.palette.background.paper};
  
          --paperDark: ${themeToUse.palette.background.paperDark};
          --paper: ${themeToUse.palette.background.paper};
        }
        *::-webkit-scrollbar {
          width: 11px;
          height: 11px;
        }
        * {
          scrollbar-width: thin;
          scrollbar-color: var(--thumbBG) var(--scrollbarBG);
        }
        *::-webkit-scrollbar-track {
          background: var(--scrollbarBG);
          border-radius: 3px;
        }
        *::-webkit-scrollbar-thumb {
          background-color: var(--thumbBG);
          border-radius: 3px;
          width: 11px;
          height: 11px;
          border: 3px solid var(--scrollbarBG);
        }
        `;
      } else {
        snackbarsRootStyle.innerHTML += `
        html {
          --scrollbarBG: ${themeToUse.palette.background.paperDark};
          --thumbBG: ${themeToUse.palette.background.paper};
        }
        *::-webkit-scrollbar {
          width: 11px;
          height: 11px;
        }
        * {
          scrollbar-width: thin;
          scrollbar-color: var(--thumbBG) var(--scrollbarBG);
        }
        *::-webkit-scrollbar-track {
          background: var(--scrollbarBG);
          border-radius: 3px;
        }
        *::-webkit-scrollbar-thumb {
          background-color: var(--thumbBG);
          border-radius: 3px;
          width: 11px;
          height: 11px;
          border: 3px solid var(--scrollbarBG);
        }
        `;
      }
    }

    // if (underConstruction) {
    //   snackbarsRootStyle.innerHTML += `
    //   body {

    //   background-image: url(${
    //     ASSET_URL + `/branding/s11-screens_${!isTablet ? 'small' : 'full'}.jpg`
    //   }) !important;
    //   background-position: 50% 50% !important;
    //   background-repeat: no-repeat !important;
    //   background-size: cover !important;
    // }
    //   `;
    // }
  }, [isTablet, underConstruction, themeNameToUse, route]); // eslint-disable-line

  themes[themeNameToUse].scrollbarWidth = getScrollbarWidth();

  return (
    <ThemeProvider theme={themeToUse}>
      <ErrorBoundary>
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          action={key => (
            <Button onClick={onClickDismiss(key)}>
              <CloseIcon style={{ color: 'white' }} />
            </Button>
          )}
          preventDuplicate={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          autoHideDuration={2500}
          classes={{
            containerRoot: 'snackbarsRoot'
          }}
        >
          {(underConstruction && underConstruction.all) ||
          errorUnderConstruction ? (
            <Suspense fallback={null}>
              <UnderConstruction
                refetchUnderConstruction={refetchUnderConstruction}
                underConstruction={underConstruction}
                loadingUnderConstruction={loadingUnderConstruction}
                errorUnderConstruction={errorUnderConstruction}
              />
            </Suspense>
          ) : (
            <PureRouter parentProps={props} />
          )}

          {/* <PureRouter parentProps={props} /> */}

          <UpdateDialog />
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
