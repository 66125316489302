import React from 'react';
import useTranslations from 'hooks/useTranslations';
import { Typography } from '@material-ui/core';
import PrimaryButton from './Elements/PrimaryButton';
import { Link } from 'react-router-dom';
import getRegisterUrl from 'getRegisterUrl';
import useIsTablet from 'hooks/useIsTablet';
import useCurrentUser from 'hooks/useCurrentUser';

const TEXT = {
  text: {
    nl: 'De Super11 app is gemaakt voor iedereen die van voetbal houdt. Naast meedoen aan de bekende Super11 spellen kun je ook jouw favoriete competities, clubs of spelers volgen. De Super11 app draait op verschillende data leveranciers en alles update live en binnen minimale secondes. Bekijk ook de uitgebreide statistieken die bij alle competities worden weergegeven.',
    en: 'The Super11 app was created for everybody who loves football. Besides playing the well received Super11 games you can also follow your favorite competitions, clubs and players. The Super11 app runs on multiple data providers and everything updates live and within seconds. Also check out the detailed statistics which are avaiable for all competitions.'
  }
};

export default function ContentPageBottomCTA() {
  const tr = useTranslations();
  const textTr = useTranslations(TEXT);
  const isTablet = useIsTablet();
  const { currentUser } = useCurrentUser();

  return (
    <>
      <Typography
        style={{
          fontSize: 36,
          lineHeight: '42px',
          lettSpacing: '-1%',
          fontWeight: 'bold',
          marginBottom: isTablet ? 32 : 16
        }}
      >
        {tr('The football app for everybody')}
      </Typography>
      <Typography
        style={{
          fontSize: 18,
          lineHeight: '28px',
          maxWidth: 800,
          margin: '0 auto'
        }}
      >
        {textTr('text')}
      </Typography>
      {currentUser && !currentUser.FAKE_LOGIN ? (
        <PrimaryButton
          component={Link}
          to={'/'}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            fontSize: 21,
            padding: '6px 16px',
            marginTop: 16,
            width: !isTablet ? '100%' : 'auto'
          }}
        >
          {tr('Open app')}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          component={Link}
          to={getRegisterUrl()}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            fontSize: 21,
            padding: '6px 16px',
            marginTop: 16,
            width: !isTablet ? '100%' : 'auto'
          }}
        >
          {tr('Sign up')}
        </PrimaryButton>
      )}
    </>
  );
}
