import { useQuery, useMutation } from '@apollo/client';
import { Query, Mutation } from 'gqls';
import { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

export default function useCurrentUser({
  onCompleted,
  fetchPolicy,
  withCreditPurchases
} = {}) {
  let {
    loading,
    error,
    data: { currentUser } = {},
    refetch,
    networkStatus
  } = useQuery(
    withCreditPurchases
      ? Query.currentUserWithCreditPurchases
      : Query.currentUser,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted,
      fetchPolicy: fetchPolicy
      // pollInterval: 1000
    }
  );

  const [logout] = useMutation(Mutation.logout, {
    onCompleted: data => {
      if (data.logout) {
        setTimeout(() => {
          window.location = '/';
        }, 100);
      }
    }
  });

  useEffect(() => {
    if (currentUser?._id) {
      const cuid = localStorage.getItem('cuid');
      if (
        cuid &&
        typeof cuid === 'string' &&
        cuid.length === 24 &&
        cuid !== currentUser._id
      ) {
        return logout({
          variables: {
            from: 'logout'
          }
        });
      }
      localStorage.setItem('cuid', currentUser._id);
    }
  }, [currentUser, logout]);

  const fakeLogin = useStoreState(s => s.fakeLogin);

  if (fakeLogin) {
    currentUser = { FAKE_LOGIN: true, name: 'new user' };
  }

  return { currentUser, loading, error, refetch, networkStatus };
}
